<template>
  <Drawer
    title="导入"
    ref="drawerIshow"
    :buttonIsShow="true"
    submitFormName="导入"
  >
    <div class="importMember">
      <!-- 上方标题 -->
      <div class="topTitle">
        <i class="el-icon-folder-add"></i>
        <span class="title">批量导入助餐补贴对象</span>
      </div>
      <!-- 导入说明 -->
      <div class="importDesc">
        导入说明：
        1、导入的数据自动审批通过。
        2、请先维护补贴分类，再填写导入数据。 3、模板中标红的数据必须填写
        4、如果设置补贴对象审批区划为"助餐点所在行政区划"，责任助餐点为必填。
      </div>
      <!-- 导入模版 -->
      <div class="downloadTemplate">
        <subTitle information-name="导入模版"></subTitle>
        <el-button type="primary" plain size="mini" icon="el-icon-upload2">
          <a href="/static/zcbthygl.xlsx" download="助餐补贴对象批量导入.xlsx">模板下载</a >
        </el-button>
      </div>
      <!-- 导入文件 -->
      <div class="importTemplate">
        <subTitle information-name="导入文件"></subTitle>
        <el-upload
        :show-file-list="true"
        :headers="token"
        :file-list="xlsAreafileList"
        :action="xlsAreaAction"
        :on-success="onXlsUploadSuccess"
        accept=".xlsx, .xls"
      >
        <el-button type="success" plain size="mini" icon="el-icon-upload"
          >选择文件</el-button
        >
      </el-upload>
      </div>
    </div>
  </Drawer>
</template>

<script>
import { Drawer } from "@/components";
import subTitle from "../common/subTitle.vue";
export default {
  name: "ImportMember",
  components: { Drawer, subTitle },
  data() {
    return {
      token: { token: localStorage.getItem("systoken") },
      xlsAreafileList: [],
      xlsAreaAction: "/api/oldUser/importUser.json",
    };
  },

  mounted() {},

  methods: {
    // 下载模版
    handleDownload() {},
    // 导入
    onXlsUploadSuccess(response) {
      if (response.success) {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.dataRefresh();
      } else {
        this.$message({
          type: "error",
          message: "导入失败",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.importMember {
  .topTitle {
    padding-bottom: 0.3125rem;
    border-bottom: 1px solid #eee;
    margin-bottom: 0.9375rem;
    .el-icon-folder-add {
    font-size: 0.875rem;
    margin-right: 0.1875rem;
  }

    .title {
      font-size: 1rem;
    }
  }
  .importDesc {
    font-size: 1rem;
    color: #d19402;
  }
  .downloadTemplate {
    margin: 0.9375rem 0;
  }
}
</style>
