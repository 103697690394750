<template>
  <Drawer title="订单详情" ref="drawerIshow">
    <el-row :gutter="24"> </el-row>
    <el-form class="form" :model="dataForm" label-width="100px" disabled>
      <el-col :span="24">
        <subTitle information-name="基本信息"></subTitle>
      </el-col>
      <el-col :span="12">
        <el-form-item label="老人姓名">
          <el-input v-model="dataForm.userName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="身份证号">
          <el-input v-model="dataForm.idCard"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="就餐时间">
          <el-date-picker v-model="dataForm.createTime" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="菜品内容">
          <el-input v-model="dataForm.dishContent"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否享受补贴">
          <el-input v-model="dataForm.subsidy"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="市场价(元)">
          <el-input v-model="dataForm.originalPrice"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="补贴(元)">
          <el-input v-model="dataForm.totalSubsidy"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="就餐时段">
          <el-radio-group v-model="dataForm.checkValue">
            <el-radio :label="1">早餐</el-radio>
            <el-radio :label="2">中餐</el-radio>
            <el-radio :label="3">晚餐</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <subTitle information-name="评价信息"></subTitle>
      </el-col>
      <el-col :span="12">
        <el-form-item label="评价时间">
          <el-date-picker v-model="dataForm.evaluateDate" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="星级评定">
          <el-input v-model="dataForm.evaluateStar"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="评价">
          <el-input v-model="dataForm.evaluateDesc" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
        </el-form-item>
      </el-col>
    </el-form>
  </Drawer>
</template>

<script>
import { Drawer } from "@/components";
import subTitle from "../common/subTitle.vue";

export default {
  name: "OrderFormInfo",
  components: { Drawer, subTitle },
  data() {
    return {
      dataForm: {
        userName: '',
        idCard: '',
        createTime: '',
        dishContent: '',
        subsidy: '是',
        originalPrice: '',
        totalSubsidy: '',
        checkValue: 2,
        evaluateDate: '',
        evaluateStar: '',
        evaluateDesc: ''
      },
    };
  },

  mounted() { },

  methods: {},
};
</script>

<style lang="scss" scoped>
.form {
  width: 96%;

  .el-date-editor {
    width: 100%;
  }
}
</style>
