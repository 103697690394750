<template>
    <div class="hl-full-container">

        <div class="img">
            <div>
                <div class="img1" @click="configuration('1')">

                </div>
                <div class="word">
                    服务项配置
                </div>
            </div>

            <div>
                <div class="img2" @click="configuration('2')">

                </div>
                <div class="word">
                    服务规则设置
                </div>
            </div>
            <div>
                <div class="img3" @click="configuration('3')">

                </div>
                <div class="word">
                    模块基础配置
                </div>
            </div>
        </div>
        <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize">
            <configuration1 v-if="configurationIsShow == '1'"></configuration1>
            <configuration2 v-if="configurationIsShow == '2'"></configuration2>
            <configuration3 v-if="configurationIsShow == '3'"></configuration3>
        </Drawer>
    </div>
</template>
  
<script>
import { Drawer } from "@/components";
import configuration1 from './components/configuration1'
import configuration2 from './components/configuration2'
import configuration3 from './components/configuration3'
export default {
    name: 'DPSJ',
    components: { Drawer, configuration1, configuration2, configuration3 },
    data() {
        return {
            title: '',
            drawerSize: '90%',
            configurationIsShow: '',
            NEWdata: ""
        };
    },
    methods: {
        configuration(e) {
            switch (e) {
                case '1':
                    this.title = '服务项配置'
                    this.$refs.drawerIshow.drawer = true
                    this.configurationIsShow = '1'

                    break;
                case '2':
                    this.$store
                        .dispatch("jbsz/getNewData")
                        .then((res) => {
                            this.$store.dispatch("app/toForm", res.result);
                        })
                    this.title = '居家服务项配置'
                    this.$refs.drawerIshow.drawer = true

                    this.configurationIsShow = '2'
                    break;
                case '3':
                    this.title = '模块基础配置'
                    this.$store
                        .dispatch("jbsz/livingModulegetDataList")
                        .then((res) => {
                            this.$store.dispatch("app/toForm", res.result);
                        })
                    this.$refs.drawerIshow.drawer = true
                    this.configurationIsShow = '3'
                    break;
            }
        }
    },
}
</script>
  
<style lang="scss">
.img {
    height: 12rem;
    width: 100%;
    border-bottom: 1px solid;
    display: flex;

    div {
        margin-right: 1rem;
    }

    .img1 {
        width: 15rem;
        height: 10rem;
        background: url('~@/assets/content/sz2.pic.jpg');
        background-size: 100% 100%;
        cursor: pointer;
    }

    .img2 {
        width: 15rem;
        height: 10rem;
        background: url('~@/assets/content/sz1.pic.jpg');
        background-size: 100% 100%;
        cursor: pointer;
    }

    .img3 {
        width: 15rem;
        height: 10rem;
        background: url('~@/assets/content/sz3.pic.jpg');
        background-size: 100% 100%;
        cursor: pointer;
    }

    .word {
        width: 15rem;
        font-size: 1rem;
        text-align: center;
    }
}
</style>
  