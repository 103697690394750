<template>
  <div class="infoTitle">
    <div class="gdWord" :style="{fontSize: fontSize}">{{ informationName }}</div>
    <div class="desc" :style="{fontSize: descFontSize}">{{ informationDesc }}</div>
  </div>
</template>

<script>
export default {
  name: "SubTitle",
  props: {
    informationName: {
      type: String,
      default: "",
    },
    informationDesc: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "1rem", 
    },
    descFontSize: {
      type: String,
      default: "0.875rem", 
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.infoTitle {
  margin-bottom: .9375rem;
  border-bottom: 1px solid #ddddde;
  padding-bottom: .625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .gdWord {
    color: #6179bb;
    font-weight: 350;
  }
  .desc {
    color: goldenrod;
    font-weight: 350;
  }
}
</style>
