<template>
    <div class="hl-full-container flexVertical">
        <!-- 查询 -->
        <div class="search">
            <el-form :model="query" ref="query" label-width="100px" inline>
                <el-form-item label="服务对象名字">
                    <el-input v-model="query.oldName" placeholder="请输入老人姓名"></el-input>
                </el-form-item>
                <el-form-item label="月份区间">
                    <el-date-picker type="month" placeholder="选择月" v-model="query.startFormat">
                    </el-date-picker>-<el-date-picker type="month" placeholder="选择月" v-model="query.endFormat">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="服务批次">
                    <el-select v-model="query.batchId" placeholder="请选择">
                        <el-option v-for="item in  fwpc  " :key="item.id" :label="item.describe" :value="item.id">
                        </el-option>
                    </el-select>

                </el-form-item>
                <template v-if="searchIsShow">
                    <el-form-item label="身份证号">
                        <el-input v-model="query.idCard" placeholder="请输入身份证号"></el-input>
                    </el-form-item>

                    <!-- <el-form-item label="服务对象分类">
                        <el-select v-model="query.level" placeholder="请选择">
                            <el-option key="能力完好" label="分散自理" value="能力完好"> </el-option>
                            <el-option key="轻度失能" label="分散半自理" value="轻度失能"></el-option>
                            <el-option key="中度失能" label="低保农村300" value="中度失能"></el-option>
                            <el-option key="重度失能" label="低保农村450" value="重度失能"></el-option>
                            <el-option key="完全失能" label="低保农村600" value="完全失能"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="合约号">
                        <el-input v-model="query.code" placeholder="请输入电话"></el-input>
                    </el-form-item>
                    <el-form-item label="所属区划:" prop="areaName">
                        <el-cascader v-model="query.areaName" :options="options" @change="handleBaseValue"
                            :props="optionProps"></el-cascader>
                    </el-form-item>
                </template>
                <el-form-item>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
                    <el-button size="small" icon="el-icon-refresh-left" @click="reset('Form')">重置</el-button>
                    <el-button size="small" type="info"
                        :icon='searchIsShow == false ? "el-icon-arrow-down" : "el-icon-arrow-up"'
                        @click="more">更多</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 中间 -->
        <div class="toolbar">
            <el-button type="primary" plain size="mini" icon="el-icon-download" @click="derive">导出</el-button>
        </div>
        <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
            :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
            :isNumber="table.isNumber" :formSearch="query">
            <div slot-scope="selected" slot="EditColumn">
                <el-button icon="el-icon-view" type="text" size="small" @click="search(selected.singlerow)">服务详情</el-button>
            </div>
        </hltableZ>
        <Drawer :title="title" ref="drawerIshow">
            <el-card>
                <h1>{{ xq.oldName }}</h1>
                <h3>
                    <span style="margin-right:4rem">身份证：{{ xq.idCard }}</span>
                    <span>电话：{{ xq.phone }}</span>
                </h3>
            </el-card>
            <el-card>
                <el-steps :active="active">
                    <el-step title="创建订单" :description="$timestampTostr(contractEntity.createTime)"></el-step>
                    <el-step title="服务商接单" :description="organName"></el-step>
                    <el-step title="订单执行"
                        :description="'已完成' + contractEntity.totalCount + '次服务' + ',' + '约' + contractEntity.totalMinute + '小时'"></el-step>
                    <el-step title="订单完成" :description="status"></el-step>
                </el-steps>
            </el-card>
            <el-card>
                <h2>服务记录</h2>
                <div class="content">
                    <div class="contentLeft">
                        <div v-for="(  item, index  ) in  fwList  " :key="index" style="margin: 1rem;"
                            :class="indexa == index ? 'clacks2' : 'clacks'" @click="qh(item, index)">
                            <p>{{ $timestampTostr(item.startTime) }}</p>
                            <p>{{ item.type }}</p>
                        </div>
                    </div>
                    <div class="contentRight">
                        <el-timeline-item type="">
                            <h4>服务过程</h4>
                            <template v-for="( item, index ) in   fwLists  ">
                                <h3>
                                    {{ item.serviceType }}
                                </h3>
                                <div class="block" v-for=" item2  in   item.fileStoreEntity  ">
                                    <p class="demonstration">{{ $timestampTostr(item2.createTime) }}</p>
                                    <el-image style="width: 200px; height: 200px" :src="item2.filePath"></el-image>
                                </div>
                            </template>
                        </el-timeline-item>
                        <el-timeline-item type="primary">
                            <h4>服务确认</h4>
                            <el-card style="display: flex;">
                                <div class="block">
                                    <p class="demonstration">服务对象/委托人签名</p>
                                    <el-image style="width: 400px; height: 200px" v-for=" item  in  consignors "
                                        :src="item.filePath"></el-image>

                                    <p class="demonstration">服务人员签名</p>
                                    <el-image style="width: 400px; height: 200px" v-for=" item  in  hashslingers "
                                        :src="item.filePath"></el-image>
                                </div>
                            </el-card>
                        </el-timeline-item>
                        <el-timeline-item type="primary">
                            <h4>工单签出</h4>
                            <el-card v-if="gdqc.backTime">
                                <p>{{ gdqc.fullName }}于{{ $timestampTostr(gdqc.backTime) }}发起补录申请，待管理人员审批</p>
                                <p>申请补录工单签出时间：{{ $timestampTostr(gdqc.signOffTime) }}</p>
                                <p>工单未现场签出原因：{{ gdqc.cause }}</p>
                            </el-card>
                            <el-card v-else>
                                <p>服务于{{ $timestampTostr(gdqc.endTime) }}完成签出</p>
                                <p>服务人员移动端现场确认</p>
                                <p>总服务时长：{{ gdqc.hour }}</p>
                            </el-card>
                        </el-timeline-item>
                    </div>
                </div>
            </el-card>
        </Drawer>
    </div>
</template>
  
<script>

import { hltableZ, Drawer } from "@/components";
export default {
    name: "lrxx",
    components: { hltableZ, Drawer },
    data() {
        return {
            optionProps: {
                value: 'pcode',
                label: 'name',
                children: 'children'
            },
            fwpc: [],
            query: { oldName: "", startFormat: '', endFormat: '', batchId: '', code: '', status: "", idCard: '', areaName: '' },
            //   表格
            table: {
                columnEdit: true,
                column: [
                    {
                        label: "服务对象",
                        prop: "oldName",
                        checked: true,
                        width: '150'
                    },
                    // {
                    //     label: "对象分类",
                    //     prop: "serveType",
                    //     checked: true,
                    //     width: '150'
                    // },
                    {
                        label: "身份证号",
                        prop: "idCard",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "联系方式",
                        prop: "phone",
                        checked: true,
                        width: '150',
                    },
                    {
                        label: "服务月份",
                        prop: "format",
                        checked: true,
                        width: '150'
                    }, {
                        label: "上门人次",
                        prop: "totalCount",
                        checked: true,
                        width: '150'
                    }, {
                        label: "服务项数",
                        prop: "project",
                        checked: true,
                        width: '150'
                    }, {
                        label: "有效时长(小时)",
                        prop: "totalHour",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "服务价值",
                        prop: "price",
                        checked: true,
                        width: '150'
                    },
                    // {
                    //     label: "申报补贴金额",
                    //     prop: "level",
                    //     checked: true,
                    //     width: '150'
                    // },
                    // {
                    //     label: "补贴标准",
                    //     prop: "",
                    //     checked: true,
                    //     width: '150'
                    // },
                    // {
                    //     label: "附加信息",
                    //     prop: "",
                    //     checked: true,
                    //     width: '150'
                    // },
                    {
                        label: "合约号",
                        prop: "code",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "合约当前状态",
                        prop: "status",
                        checked: true,
                        formatter: this.timeFormatter,
                        width: '150',
                        formatter(value) {
                            const status = value.status;
                            if (status == '1') {
                                return '未开始'
                            } else if (status == '2') {
                                return '合约执行中'
                            } else if (status == '3') {
                                return '合约暂停'
                            } else if (status == '4') {
                                return '合约正常完成'
                            } else if (status == '5') {
                                return '合约终止'
                            }
                        },
                    },
                    {
                        label: "服务商",
                        prop: "organName",
                        checked: true,

                        width: '150'
                    },
                    {
                        label: "行政区划",
                        prop: "areaName",
                        checked: true,

                        width: '150'
                    },
                    {
                        label: "居住地址",
                        prop: "address",
                        checked: true,

                        width: '150'
                    },
                ],
                apiurl: "jbsz/settleAccounts",
                refreshFlag: "",
                selectedRows: [],
                isChecked: false,
                isNumber: true
            },
            options: [],
            rulesAdd: [],
            token: { token: localStorage.getItem("systoken") },
            xlsAreafileList: [],
            xlsAreaAction: "/api/oldUser/importUser.json",
            title: '详情信息',
            activeItem: {
            },
            systemConfig: this.$store.getters.systemSettings.config,
            searchIsShow: false,
            fwLists: [],
            xq: {},
            fwList: [

            ],
            consignors: [],
            hashslingers: [],
            gdqc: {},
            active: 4,
            indexa: null,
            contractEntity: {},
            status: '',
            organName: ''
        };
    },

    mounted() {
        this.fwpcClick()
        this.getDataTree()
        this.setActiveItem(this.systemConfig[0])
    },

    methods: {
        qh(e, a) {
            console.log(e)
            this.fwLists = e.serviceContent
            this.consignors = e.consignors
            this.hashslingers = e.hashslingers
            this.organName = e.organName
            this.gdqc = e
            this.active = 4
            if (e.contractEntity.status == 1) {
                this.status = '未开始'
            } else if (e.contractEntity.status == 2) {
                this.status = '合约执行中'
            } else if (e.contractEntity.status == 3) {
                this.status = '合约暂停'
            } else if (e.contractEntity.status == 4) {
                this.status = '合约正常完成'
            } else if (e.contractEntity.status == 5) {
                this.status = '合约终止'
            }
            this.contractEntity = e.contractEntity
            this.indexa = a
        },
        getDataTree() {
            this.$store.dispatch("area/getDataTree",{
          areaCode: this.$store.getters.user.areaCode,
        }).then((res) => {
                this.options = res.result
            })
        },
        handleBaseValue(val) {
            this.query.areaName = val[val.length - 1];
        },
        fwpcClick() {
            this.$store
                .dispatch("jbsz/getList")
                .then((res) => {
                    this.fwpc = res.result

                })
        },
        timeFormatter(row, column, cellValue) {
            return cellValue ? this.$timestampTostr(cellValue) : "";
        },
        //   查询
        dataRefresh() {
            this.table.refreshFlag = (new Date()).getTime().toString()
        },
        reset() {
            const th = this
            Object.keys(th.query).forEach(function (key) {
                if (key == 'value') {
                    th.query.value = '1'
                } else {
                    th.query[key] = ''
                }

            })
            this.dataRefresh();
        },
        // 导入
        onXlsUploadSuccess(response) {
            if (response.success) {
                this.$message({
                    type: "success",
                    message: "导入成功",
                });
                this.dataRefresh();
            } else {
                this.$message({
                    type: "error",
                    message: "导入失败",
                });
            }
        },
        async derive() {
            const token = await this.$store.dispatch("user/getToken");

            this.$axios({
                method: "post",
                url: "/api/livingContract/accountsDownLoad",
                data: this.query,
                headers: {
                    token: token,
                    platform: "web",
                },
                responseType: "blob",
            })
                .then((response) => {
                    if (response.headers["content-disposition"]) {
                        const content = response.data;
                        const blob = new Blob([content]); //构造一个blob对象来处理数据

                        // fileName必用这种方式进行解析，否则乱码
                        // Server.UrlEncode(filename);

                        let contentDisposition = response.headers["content-disposition"];
                        // fileName必用这种方式进行解析，否则乱码
                        let fileName = window.decodeURI(
                            contentDisposition.substring(contentDisposition.indexOf("=") + 1)
                        );
                        //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
                        if ("download" in document.createElement("a")) {
                            const link = document.createElement("a"); //创建a标签
                            link.download = fileName; //a标签添加属性
                            link.style.display = "none";
                            link.href = URL.createObjectURL(blob);
                            document.body.appendChild(link);
                            link.click(); //执行下载
                            URL.revokeObjectURL(link.href); //释放url
                            document.body.removeChild(link); //释放标签
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        search(e) {
            this.xq = e
            const data = {
                currentPage: '1',
                size: '999999999',
                query: {
                    contractId: e.contractId,
                    month: e.format
                }
            }
            this.fwList = []
            this.fwLists = []
            this.consignors = [],
                this.hashslingers = [],
                this.gdqc = {},
                // this.active = 0,
                this.indexa = null,
                this.contractEntity = {}
            this.organName = ''
            this.status = ''
            this.$store.dispatch("fwjg/orderServiceGetDataList", data)
                .then((res) => {
                    res.result.rows.forEach(res => {
                        this.fwList.push(res)
                    })
                    this.qh(this.fwList[0], 0)
                })
            this.$refs.drawerIshow.drawer = true
        },
        setActiveItem(item) {
            this.activeItem = {
                name: item.name,
                key: item.key,
                component: item.component,
            };
        },
        more() {
            this.searchIsShow = !this.searchIsShow
        }
    },
};
</script>
  
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

::v-deep .el-table th,
::v-deep .el-table td {
    text-align: center !important;
}

.data {
    display: flex;
}

.component {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.content {
    display: flex;
    min-height: 6rem;

    .contentLeft {
        // border-right: 1px solid #000;
        width: 8rem;
        cursor: pointer;

    }


    .clacks {
        cursor: pointer;
    }

    .clacks2 {
        cursor: pointer;
        color: #409EFF;
    }
}
</style>
  