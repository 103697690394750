<template>
  <div>
    <div class="form-unit">修改信息</div>
    <div class="formdiv">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="修改人:">
              <el-input
                v-model="form.name"
                :disabled="true"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="修改时间:">
              <el-input
                v-model="form.time"
                :disabled="true"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
    </div>
    <div class="jtcw1">
      <hltableZ
        :columnEdit="table1.columnEdit"
        :tableColumn="table1.column"
        :apiurl="table1.apiurl"
        :refreshFlag="table1.refreshFlag"
        :selectedRows="table1.selectedRows"
        :isChecked="table1.isChecked"
        :isNumber="table1.isNumber"
        :formSearch="query1"
      >
      </hltableZ>
    </div>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
export default {
  data() {
    return {
      table1: {
        columnEdit: false,
        column: [
          {
            label: "属性",
            prop: "oldName",
            checked: true,
          },
          {
            label: "	修改前数据",
            prop: "organName",
            checked: true,
          },
          {
            label: "	修改后数据",
            prop: "oldName",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      query1: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
      form: {
        name: "淄博运营",
        time: "2022-08-30 14:50:17",
      },
    };
  },
  components: { hltableZ },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.box-header {
  width: 100%;
  height: 47px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 47px;
  .el-button--small {
    height: 30px !important;
  }
}
.jtcw1 {
  width: 100%;
  height: 40rem;
  margin-top: 1rem;
}
.form-unit {
  font-size: 15px;
  color: #6379bb;
  border-bottom: 1px solid #ddd;
  margin: 8px 10px 25px 10px;
  padding-bottom: 5px;
}
</style>
