<template>
    <div class="hl-full-container">
        <div class="img">
            <div class="img1" @click="DP">

            </div>
            <div class="word">
                大屏展示
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'DPSJ',
    methods: {
        DP() {
            const routeData = this.$router.resolve({
                path: '/DP',
                query: {},
            })
            window.open(
                routeData.href,
                "'_blank"
            );
        }
    },
}
</script>
  
<style lang="scss">
.img {
    height: 12rem;
    width: 100%;
    border-bottom: 1px solid;
    display: flex;
    flex-direction: column;

    .img1 {
        width: 15rem;
        height: 10rem;
        background: url('~@/assets/content/box.jpg');
        background-size: 100% 100%;
        cursor: pointer;
    }

    .word {
        width: 15rem;
        font-size: 1rem;
        text-align: center;
    }
}
</style>
  