<template>
  <Drawer title="非会员订单" ref="drawerIshow">
    <div class="onlineSelfService">
      <!-- 搜索 -->
      <div class="search">
        <el-form
          class="form"
          ref="form"
          :model="query"
          label-width="75px"
          inline
        >
          <el-form-item label="" prop="mealLocation">
            <el-select
              v-model="query.mealLocation"
              placeholder="请选择助餐地点"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="item in mealLocationOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="就餐日期" prop="diningDate">
            <el-date-picker
              v-model="query.diningDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="老人姓名" prop="name">
            <el-input
              class="oldManName"
              v-model="query.name"
              placeholder="请输入老人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单状态" prop="ordersStatus">
            <el-select
              v-model="query.ordersStatus"
              placeholder="请选择支付方式"
            >
              <el-option
                v-for="item in ordersStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="dataRefresh"
              >查询</el-button
            >
            <el-button size="small" icon="el-icon-refresh-left" @click="reset"
              >重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <hltableZ
        :columnEdit="table.columnEdit"
        :tableColumn="table.column"
        :apiurl="table.apiurl"
        :refreshFlag="table.refreshFlag"
        :isChecked="table.isChecked"
        :isNumber="table.isNumber"
        :isClickSelect="table.isClickSelect"
        :staticData="tableDataSource"
        :formSearch="query"
      >
        <div slot-scope="selected" slot="EditColumn">
          <el-button
            icon="el-icon-error"
            type="text"
            size="small"
            @click="chargeback(selected.singlerow)"
            >退单</el-button
          >
        </div>
      </hltableZ>
    </div>
  </Drawer>
</template>

<script>
import { Drawer, hltableZ } from "@/components";

export default {
  name: "OnlineSelfService",
  components: { Drawer, hltableZ },
  data() {
    return {
      // 助餐站点options
      mealLocationOptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "辛店街道于家店村长者食堂",
        },
        {
          value: "2",
          label: "齐都镇东古村长者食堂",
        },
      ],
      // 支付方式
      ordersStatusOptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "客户已下单",
        },
        {
          value: "2",
          label: "客户取消",
        },
        {
          value: "3",
          label: "接单成功",
        },
        {
          value: "4",
          label: "拒单",
        },
        {
          value: "5",
          label: "订单已过期",
        },
      ],
      table: {
        columnEdit: true,
        column: [
          {
            label: "老人姓名",
            prop: "1",
            checked: true,
          },
          {
            label: "就餐日期",
            prop: "2",
            checked: true,
          },
          {
            label: "菜品名称",
            prop: "3",
            checked: true,
          },
          {
            label: "订单状态",
            prop: "4",
            checked: true,
          },
          {
            label: "菜品金额(元)",
            prop: "5",
            checked: true,
          },
          {
            label: "实付金额(元)",
            prop: "6",
            checked: true,
          },
          {
            label: "补贴金额(元)",
            prop: "7",
            checked: true,
          },
          {
            label: "取餐方式",
            prop: "8",
            checked: true,
          },
          {
            label: "最晚取餐时间",
            prop: "9",
            checked: true,
          },
          {
            label: "下单时间",
            prop: "10",
            checked: true,
          },
          {
            label: "助餐站点",
            prop: "11",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        isClickSelect: false,
      },
      // 表格静态数据
      tableDataSource: [
        {
          1: 1,
          2: 1,
          3: 1,
          4: 1,
          5: 1,
          6: 1,
          7: 1,
          8: 1,
          9: 1,
          10: 1,
          11: 1,
        },
      ],
      // 搜索条件
      query: {
        mealLocation: "",
        diningDate: "",
        name: "",
        ordersStatus: null,
      },
    };
  },

  mounted() {},

  methods: {
    // 查询
    dataRefresh() {},
    // 重置
    reset() {
      this.$refs.form.resetFields();
    },
    // 退单
    chargeback() {
      this.$confirm("退单完成后，该订单将被删除。", "您确定要退单吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.onlineSelfService {
  height: 100%;
  display: flex;
  flex-direction: column;
  // 搜索
  .search {
    // 老人姓名输入框样式
    .oldManName {
      width: 10rem;
    }
    // 时间选择器样式
    ::v-deep .el-date-editor--daterange {
      width: 14rem;
    }
  }
}
</style>
