<template>
  <div class="hl-full-container flexVertical">
    <!-- 上方标题 -->
    <div class="topTitle">
      <i class="el-icon-film"></i>
      <span class="title">服务组织管理</span>
      <span class="subTitle"
        >(请到“服务组织管理”中维护并开通“家庭床位(HCM)”功能)</span
      >
    </div>
    <!-- 搜索条件 -->
    <div class="serach">
      <el-form :model="query" ref="query" label-width="80px" inline>
        <el-form-item label="名称" prop="institutionName">
          <el-input
            v-model="query.institutionName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="营业状态" prop="businessStatus">
          <el-select
            v-model="query.businessStatus"
            placeholder="请选择营业状态"
          >
            <el-option
              v-for="item in businessStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <!-- <el-button size="small" icon="el-icon-refresh-left" @click="reset"
            >重置</el-button
          > -->
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <hltableZ
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :isPage="false"
      :staticData="table.staticData"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :isNumber="table.isNumber"
      :isClickSelect="table.isClickSelect"
      :formSearch="query"
      :handleCellStyle="cellStyle"
      @cellClick="cellClick"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          icon="el-icon-edit"
          type="text"
          size="small"
          @click="handleEdit(selected.singlerow)"
          >编辑</el-button
        >
        <el-button
          icon="el-icon-setting"
          type="text"
          size="small"
          @click="handleModule(selected.singlerow)"
          >模块适配</el-button
        >
      </div>
    </hltableZ>
    <!-- 编辑弹窗 -->
    <fwzcInfo ref="fwzcInfo"></fwzcInfo>
    <!-- 账号弹窗 -->
    <accountInfo ref="accountInfo"></accountInfo>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
import fwzcInfo from "./components/fwzc/FwzcInfo.vue";
import accountInfo from "./components/fwzc/AccountInfo.vue";
export default {
  name: "Fwzc",
  components: { hltableZ, fwzcInfo, accountInfo },
  data() {
    return {
      // 营业状态
      businessStatusOptions: [
        { label: "全部", value: null },
        { label: "正常运营", value: "1" },
        { label: "注销", value: "2" },
        { label: "取缔", value: "3" },
        { label: "停业整顿", value: "4" },
        { label: "事实处于未开展服务状态", value: "5" },
        { label: "关停", value: "6" },
      ],
      // 表格属性值
      table: {
        staticData: [],
        columnEdit: false,
        column: [
          {
            label: "性质",
            prop: "a",
            checked: true,
          },
          {
            label: "二级分类",
            prop: "b",
            checked: true,
          },
          {
            label: "名称",
            prop: "c",
            checked: true,
          },
          {
            label: "简称",
            prop: "d",
            checked: true,
          },
          {
            label: "编码",
            prop: "e",
            checked: true,
          },
          {
            label: "所属区县",
            prop: "f",
            checked: true,
          },
          {
            label: "所在街道/镇",
            prop: "g",
            checked: true,
          },
          {
            label: "所在社区/村",
            prop: "h",
            checked: true,
          },
          {
            label: "详细地址",
            prop: "i",
            checked: true,
          },
          {
            label: "坐标经度",
            prop: "j",
            checked: true,
          },
          {
            label: "坐标纬度",
            prop: "k",
            checked: true,
          },
          {
            label: "服务星级",
            prop: "l",
            checked: true,
          },
          {
            label: "负责人",
            prop: "m",
            checked: true,
          },
          {
            label: "负责人电话",
            prop: "n",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        isClickSelect: false,
      },
      tableDataSource: [
        {
          1: 1,
          2: 2,
          3: 15655556666,
          4: 4,
          account: 5,
          6: 6,
        },
      ],
      query: {
        institutionName: "",
        businessStatus: "",
      },
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    // 查询
    dataRefresh() {
      this.table.staticData = [
        {
          a: "服务企业",
          b: "服务机构",
          c: "淄博市齐佳社会工作服务中心",
          d: "齐佳社会工作服务中心",
          f: "临淄区",
          g: "闻韶街道",
          h: "西王社区居委会",
          i: "山东理工大学大红炉众创空间三楼81座",
          j: 118.016361550842,
          k: 36.8151294583958,
        },
        {
          a: "服务企业",
          b: "服务机构",
          c: "临淄区义家人社会工作服务中心",
          d: "义家人社会工作服务中心",
          f: "临淄区",
          g: "闻韶街道",
          h: "勇士社区居委会",
          i: "淄博市临淄区闻韶街道勇士生活区36号楼",
          j: 118.332285,
          k: 36.819915,
          m: "陈宝明",
          n: 15762850566,
        },
        {
          a: "服务企业",
          b: "服务机构",
          c: "淄博市临淄区蓝丝带社会服务中心",
          d: "蓝丝带社会服务中心",
          f: "临淄区",
          g: "闻韶街道",
          h: "辛东社区居委会",
          i: "淄博市临淄区闻韶街道辛东社区",
          j: 118.333778,
          k: 36.814108,
          m: "亓艳红",
          n: 13589567301,
        },
        // {
        //   a: "服务企业",
        //   b: "服务机构",
        //   c: "高新区居家养老服务中心",
        //   d: "高新区居家养老服务中心",
        //   f: "桓台县",
        //   g: "少海街道",
        //   h: "东岳国际社区居委会",
        //   i: "",
        //   j: 118.095685700256,
        //   k: 36.9588369460706,
        // },
        // {
        //   a: "服务企业",
        //   b: "服务机构",
        //   c: "善行佳豪养老服务运营中心",
        //   d: "善行佳豪养老服务运营中心",
        //   f: "桓台县",
        //   g: "少海街道",
        //   h: "东岳国际社区居委会",
        //   i: "桓台县东岳路弈景嘉园969号",
        //   j: 118.095685700256,
        //   k: 36.9588369460706,
        // },
        {
          a: "服务企业",
          b: "服务机构",
          c: "郑大姐服务组织",
          d: "郑大姐服务组织",
          f: "临淄区",
          g: "闻韶街道",
          h: "西王社区居委会",
          i: "",
          j: 118.315394609345,
          k: 36.8322936351522,
        },
        {
          a: "服务企业",
          b: "服务机构",
          c: "青岛市12349公共服务中心",
          d: "12349公共服务中心",
          f: "临淄区",
          g: "闻韶街道",
          h: "遄台社区居委会",
          i: "山东省青岛市市南区南京路27号",
          j: 120.396642174209,
          k: 36.0749544880814,
          m: "彭勃",
          n: 15095295829,
        },
      ];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    // 重置
    reset() {
      this.$refs.query.resetFields();
    },
    // 单元格点击
    cellClick(row, column, cell, event) {
      if (column.property === "account") {
        this.handleAccount();
      }
    },
    cellStyle(row, column) {
      if (column.property === "account") {
        return {
          color: "#409EFF",
          cursor: "pointer",
        };
      }
    },
    // 编辑
    handleEdit() {
      this.$refs.fwzcInfo.$refs.drawerIshow.drawer = true;
    },
    // 模块适配
    handleModule() {},
    // 打开账号
    handleAccount() {
      this.$refs.accountInfo.$refs.drawerIshow.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.hl-full-container {
  font-size: 1rem;
  color: #606266;
  // 上方标题
  .topTitle {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 0.9375rem;
    .el-icon-film {
      font-size: 14px;
      margin-right: 3px;
    }

    .title {
      font-size: 16px;
      margin-right: 0.3125rem;
    }

    .subTitle {
      font-size: 12px;
      color: #aaa;
    }
  }
}
</style>
