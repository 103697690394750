<template>
  <div class="infoTitle">
    <div class="gdWord">{{ informationName }}</div>
  </div>
</template>

<script>
export default {
  name: "SubTitle",
  props: {
    informationName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.infoTitle {
  margin-bottom: .9375rem;
  border-bottom: 1px solid #ddddde;
  padding-bottom: .625rem;
  .gdWord {
    color: #6179bb;
    font-size: 1.25rem;
    font-weight: 350;
  }
}
</style>
