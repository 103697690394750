<template>
  <div class="hl-full-container flexVertical">
    <!-- 查询 -->
    <div class="search">
      <el-form
        size="small"
        :model="query"
        ref="query"
        label-width="80px"
        inline
      >
        <el-form-item label="服务批次">
          <el-select v-model="query.serviceBatch">
            <el-option
              v-for="item in serviceBatchList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合约号">
          <el-input
            v-model="query.contractCNo"
            placeholder="请输入合约号"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务对象姓名" label-width="100px">
          <el-input
            v-model="query.serviceName"
            placeholder="请输入服务对象姓名"
          ></el-input>
        </el-form-item>
        <template v-if="conditionFlag">
          <el-form-item label="对象联系方式" label-width="120px">
            <el-input
              v-model="query.servicePhone"
              placeholder="请输入服务对象联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务对象身份证" label-width="120px">
            <el-input
              v-model="query.serviceID"
              placeholder="请输入服务对象身份证"
            ></el-input>
          </el-form-item>
          <el-form-item label="区域">
            <el-select v-model="query.county">
              <el-option
                v-for="item in countyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            -
            <el-select v-model="query.serviceBatch">
              <el-option
                v-for="item in streetList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            -
            <el-select v-model="query.serviceBatch">
              <el-option
                v-for="item in communityList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务节点">
            <el-select v-model="query.serviceNode" placeholder="请选择服务节点">
              <el-option
                v-for="item in serviceNodeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务对象分类" label-width="120">
            <el-select v-model="query.object" placeholder="请选择服务对象分类">
              <el-option
                v-for="item in objectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务包">
            <el-input
              v-model="query.servicePack"
              placeholder="请输入服务包"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务商名称" label-width="120">
            <el-input
              v-model="query.serviceName2"
              placeholder="请输入服务商名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务日期">
            <el-date-picker
              v-model="query.lastServiceTime"
              type="daterange"
              range-separator="至"
              start-placeholder="最后一次服务开始日期"
              end-placeholder="最后一次服务结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="总服务次数" label-width="120">
            <el-input-number
              v-model="query.totalNumber1"
              :min="0"
              label="总服务次数"
            ></el-input-number>
            <= 总服务次数 <=<el-input-number
              v-model="query.totalNumber2"
              :min="0"
              label="总服务次数"
            ></el-input-number>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <el-button
            size="small"
            icon="el-icon-refresh-left"
            @click="reset('Form')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="handleBtn">
      <div>批量操作：</div>
      <el-button
        size="small"
        style="margin-right: 20px"
        type="primary"
        @click="handleService"
        >分配服务商和服务人员</el-button
      >
      <!-- <el-dropdown trigger="click">
        <el-button size="small" type="primary" :icon="searchIsShow == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
          " @click="more">更多</el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><el-button type="text" plain size="small"
              @click="handleConfirm">合约批量确认完成</el-button></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <hltableZ
      :column-edit="table.columnEdit"
      :table-column="table.column"
      :isPage="false"
      :staticData="table.staticData"
      :refresh-flag="table.refreshFlag"
      :selected-rows="table.selectedRows"
      :is-checked="table.isChecked"
      :is-number="table.isNumber"
      :form-search="query"
      @getSelectedRows="getSelectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <!-- <el-button icon="el-icon-view" type="text" size="small" @click="setPackage(selected.singlerow)">设置服务包</el-button> -->

        <el-button
          size="small"
          type="text"
          :icon="
            contractShow == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
          "
          @click="handleContract(selected.singlerow)"
          >合约终止</el-button
        >

        <!-- <el-dropdown trigger="click">
          <el-button size="small" type="text" :icon="searchIsShow == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
            " @click="more">更多</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item><el-button type="text" plain size="small"
                @click="handleSupervis(selected.singlerow)">督办</el-button></el-dropdown-item>
            <el-dropdown-item><el-button type="text" plain size="small"
                @click="handleDelete(selected.singlerow)">合约删除</el-button></el-dropdown-item>
            <el-dropdown-item><el-button type="text" plain size="small"
                @click="handleChange(selected.singlerow)">合约变更记录</el-button></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </hltableZ>
    <setPackageDialog ref="setPackageDialog"></setPackageDialog>
    <serviceDialog ref="serviceDialog" @submitForm="submitForm"></serviceDialog>
    <serviceStaffDialog ref="serviceStaffDialog"></serviceStaffDialog>
    <queryDialog ref="queryDialog" @getQuery="getQuery"></queryDialog>
    <supervisDialog ref="supervisDialog"></supervisDialog>
  </div>
</template>

<script>
import supervisDialog from "./hygl/supervisDialog";
import queryDialog from "./hygl/queryDialog";
import serviceStaffDialog from "./hygl/serviceStaffDialog";
import serviceDialog from "./hygl/serviceDialog.vue";
import setPackageDialog from "./hygl/setPackageDialog.vue";
import { hltableZ } from "@/components";
export default {
  name: "Lrxx",
  components: {
    hltableZ,
    setPackageDialog,
    serviceDialog,
    serviceStaffDialog,
    queryDialog,
    supervisDialog,
  },
  data() {
    return {
      conditionFlag: false,
      contractShow: false,
      // serviceNodeList: [
      //   // 服务节点
      //   {
      //     name: "全部服务节点",
      //     id: 1,
      //   },
      //   {
      //     id: 2,
      //     name: "合约未开始",
      //   },
      //   {
      //     id: 3,
      //     name: "待分配服务商",
      //   },
      //   {
      //     id: 4,
      //     name: "待服务商接单",
      //   },
      //   {
      //     id: 5,
      //     name: "待设置服务包",
      //   },
      //   {
      //     id: 6,
      //     name: "待分配服务人员",
      //   },
      //   {
      //     id: 7,
      //     name: "合约执行中",
      //   },
      //   {
      //     id: 8,
      //     name: "合约暂停",
      //   },
      //   {
      //     id: 9,
      //     name: "合约正常完成",
      //   },
      //   {
      //     id: 10,
      //     name: "合约终止",
      //   },
      // ],
      // objectList: [
      //   // 对象分类
      //   {
      //     name: "分散自理",
      //     id: 1,
      //   },
      //   {
      //     id: 2,
      //     name: "分散半自理",
      //   },
      //   {
      //     id: 3,
      //     name: "低保农村300",
      //   },
      //   {
      //     id: 4,
      //     name: "低保农村450",
      //   },
      //   {
      //     id: 5,
      //     name: "低保农村600",
      //   },
      //   {
      //     id: 6,
      //     name: "待分配服务人员",
      //   },
      //   {
      //     id: 7,
      //     name: "低保城市450",
      //   },
      //   {
      //     id: 8,
      //     name: "低保城市675",
      //   },
      //   {
      //     id: 9,
      //     name: "低保城市900",
      //   },
      // ],
      communityList: [], // 全部社区
      streetList: [], // 全部街道
      countyList: [
        // 全部区县
        {
          id: 1,
          name: "市辖区",
        },
        {
          id: 2,
          name: "淄川区",
        },
        {
          id: 3,
          name: "张店区",
        },
        {
          id: 4,
          name: "博山区",
        },
        {
          id: 5,
          name: "临淄区",
        },
        {
          id: 6,
          name: "周村区",
        },
        {
          id: 7,
          name: "桓台区",
        },
        {
          id: 8,
          name: "高青区",
        },
        {
          id: 9,
          name: "沂源县",
        },
      ],
      serviceBatchList: [
        {
          name: "全部服务批次",
          id: 1,
        },
        {
          name: "已结束（按价值）临淄区特困老人居家服务",
          id: 2,
        },
        {
          name: "（按时长）临淄区特困老人居家服务",
          id: 3,
        },
      ],
      query: {
        status: "",
      },
      //   表格
      table: {
        columnEdit: false,
        column: [
          {
            label: "老人姓名",
            prop: "a",
            checked: true,
            width: "150",
          },
          {
            label: "身份证号",
            prop: "b",
            checked: true,
            width: "150",
          },
          {
            label: "老人分类",
            prop: "c",
            checked: true,
            width: "150",
          },
          {
            label: "能力评估结果",
            prop: "d",
            checked: true,
            width: "150",
          },
          // {
          //   label: "自理能力",
          //   prop: "e",
          //   checked: true,
          //   width: "150",
          // },
          // {
          //   label: "床位号",
          //   prop: "f",
          //   checked: true,
          //   width: "150",
          // },
          {
            label: "补贴方式",
            prop: "g",
            checked: true,
            width: "150",
          },
          {
            label: "服务批次",
            prop: "h",
            checked: true,
            width: "150",
          },
          {
            label: "签约组织",
            prop: "i",
            checked: true,
            width: "150",
          },
          // {
          //   label: "签约时间",
          //   prop: "j",
          //   checked: true,
          //   width: "150",
          // },
          {
            label: "签约服务起始日期",
            prop: "k",
            checked: true,
            width: "150",
          },
          {
            label: "服务终止日期",
            prop: "l",
            checked: true,
            width: "150",
          },
          // {
          //   label: "解约日期",
          //   prop: "m",
          //   checked: true,
          //   width: "150",
          // },
          {
            label: "价格(元/月)",
            prop: "n",
            checked: true,
            width: "150",
          },
          {
            label: "合约号",
            prop: "o",
            checked: true,
            width: "150",
          },
          {
            label: "服务包",
            prop: "p",
            checked: true,
            width: "150",
          },
          {
            label: "服务人员",
            prop: "q",
            checked: true,
            width: "150",
          },
          {
            label: "合约状态",
            prop: "r",
            checked: true,
            width: "150",
          },
        ],
        apiurl: "",
        refreshFlag: "",
        staticData: [],
        selectedRows: [],
        isChecked: true,
        isNumber: true,
      },
      options: [
        {
          value: "zhinan",
          label: "闻韶街道",
          children: [
            {
              value: "yizhi",
              label: "南王镇",
            },
            {
              value: "fankui",
              label: "敬仲镇",
            },
            {
              value: "xiaolv",
              label: "梧台镇",
            },
            {
              value: "kekong",
              label: "朱台镇",
            },
          ],
        },
      ],
      rulesAdd: [],
      token: { token: localStorage.getItem("systoken") },
      xlsAreafileList: [],
      xlsAreaAction: "/api/oldUser/importUser.json",
      title: "老人档案",
      activeItem: {},
      systemConfig: this.$store.getters.systemSettings.config,
      searchIsShow: false,
      ids: [],
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    handleSupervis(row) {
      console.log(row);
      this.$refs.supervisDialog.open();
    },
    getQuery(val) {
      this.query = val;
    },
    handleConfirm() {},
    handleDelete(singlerow) {
      console.log(singlerow);
    },
    handleChange(singlerow) {
      console.log(singlerow);
    },
    getSelectedRows(row) {
      this.table.selectedRows = row;
      this.$refs.serviceDialog.tableList = row;
      this.$refs.serviceDialog.indexc = 0;
      this.$refs.serviceDialog.tablell(0);
      this.ids = [];
      row.forEach((res) => [this.ids.push(res.id)]);
    },
    handleService() {
      // 分配服务商
      if (this.table.selectedRows.length === 0)
        return this.$message.warning("请至少选择一个合约");
      if (this.table.selectedRows.length > 0) {
        this.$refs.serviceDialog.dynamicValidateForm.serviceStaff = "";
        this.$refs.serviceDialog.open();
      }
    },
    handleServiceStaff() {
      // 分配服务人员
      if (this.table.selectedRows.length === 0)
        return this.$message.warning("请至少选择一个合约");
      if (this.table.selectedRows.length > 0) {
        this.$refs.serviceStaffDialog.open();
      }
    },
    handleMore() {
      // this.$refs.queryDialog.open();
      this.conditionFlag = !this.conditionFlag;
    },
    handleContract(e) {
      this.$confirm("确定合约作废？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(e);
          const param = {
            id: e.id,
            status: "5",
          };
          this.$store
            .dispatch("jbsz/livingContractSaveData", param)
            .then((res) => {
              this.dataRefresh();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    more() {
      this.searchIsShow = !this.searchIsShow;
    },
    setPackage(singlerow) {
      console.log(singlerow);
      this.$refs.setPackageDialog.open();
    },
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    //   查询
    dataRefresh() {
      this.table.staticData = [
        {
          a: "边增芹",
          b: "370305195105225025",
          c: "",
          d: "轻度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "杨美荣",
          b: "370305193905192824",
          c: "",
          d: "轻度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "贾广琨",
          b: "370305193704260018",
          c: "",
          d: "轻度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "王新芳[370305197705064041]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "范建国",
          b: "370305195707043114",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "淄博市临淄区蓝丝带社会服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张玉花[370305198004196521]、刘清华[370305198002151848]、周东超[232101198203196422]、孙保国[37030519660210531X]、李忠海[370305198211230032]、亓艳红[370304197909050024]、颜琪琳[370305197012150748]",
          r: "执行中",
        },
        {
          a: "周明芹",
          b: "370305195604041829",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "王能建",
          b: "370305195511062110",
          c: "改造完成去世",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "居家上门服务组织",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "",
          q: "",
          r: "执行中",
        },
        {
          a: "张会华",
          b: "370305195406122425",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张翠芳[370305197503013721]、刘凤真[370725196606190037]、王政涵[370305200603160424]、朱华芹[370303197411121327]、王聪聪[370305198701056225]、于兰田[370305197308092487]、周振兰[430223199911109127]、王俭[370305197009051829]、许燕[370305197207024346]、   王爱玲[370305197108263747]、王丽丽[370305197311022121]、郭泽[370305199512125310]、于守平[370305197108243738]、李艳青[37232819900404182X]、王  秦[370305198810160427]、王爱防[370827198102103224]、路志斌[370305198106114718]、牛盼盼[370827198710163044]、李蓓[370305199010085662]",
          r: "执行中",
        },
        {
          a: "郝慧兰",
          b: "370305194206140026",
          c: "",
          d: "中度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "杜希明",
          b: "370305195512042816",
          c: "",
          d: "轻度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "淄博市临淄区蓝丝带社会服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张玉花[370305198004196521]、刘清华[370305198002151848]、周东超[232101198203196422]、孙保国[37030519660210531X]、李忠海[370305198211230032]、亓艳红[370304197909050024]、颜琪琳[370305197012150748]、康天宇[370305200610010715]",
          r: "执行中",
        },

        {
          a: "安学胜",
          b: "370305194511022819",
          c: "",
          d: "轻度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "淄博市临淄区蓝丝带社会服务中心",
          j: "",
          k: "2022-01-15",
          l: "2023-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张玉花[370305198004196521]、刘清华[370305198002151848]、李永刚[370305197502101850]、周东超[232101198203196422]、孙保国[37030519660210531X]、李忠海[370305198211230032]、亓艳红[370304197909050024]、邵珠花[372827197311232548]、颜琪琳[370305197012150748]、王烟台[370305197205064715]、康天宇[370305200610010715]",
          r: "执行中",
        },
        {
          a: "赵学书",
          b: "370305193811212812",
          c: "",
          d: "轻度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "淄博市临淄区蓝丝带社会服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张玉花[370305198004196521]、刘清华[370305198002151848]、周东超[232101198203196422]、孙保国[37030519660210531X]、李忠海[370305198211230032]、亓艳红[370304197909050024]、颜琪琳[370305197012150748]、康天宇[370305200610010715]",
          r: "执行中",
        },
        {
          a: "赵培章",
          b: "370305196201202813",
          c: "",
          d: "中度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "淄博市临淄区蓝丝带社会服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张玉花[370305198004196521]、刘清华[370305198002151848]、李永刚[370305197502101850]、周东超[232101198203196422]、孙保国[37030519660210531X]、李忠海[370305198211230032]、亓艳红[370304197909050024]、邵珠花[372827197311232548]、颜琪琳[370305197012150748]、康天宇[370305200610010715]",
          r: "执行中",
        },
        {
          a: "胡玉贵",
          b: "370305194301223110",
          c: "",
          d: "轻度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "淄博市临淄区蓝丝带社会服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张玉花[370305198004196521]、刘清华[370305198002151848]、周东超[232101198203196422]、孙保国[37030519660210531X]、李忠海[370305198211230032]、亓艳红[370304197909050024]、许丽珍[370305198107204344]、颜琪琳[370305197012150748]、康天宇[370305200610010715]",
          r: "执行中",
        },
        {
          a: "齐富英",
          b: "370305195401114741",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "宗晓鸿[650104197204011662]、王征刚[370305197202142116]、范庆玲[37030519770713404X]、王雷[370305197904180715]、刘素华[370305196611055967]、杨春艳[370305197105040724]、董振菊[370305196307100727]、王姗姗 [370305198612202841]、边玉琪[37030519950818502X]、王景远[37088319650917531X]、曹娜[370305198701110041]、卢晓庆[230123198505143461]、范文浩[370305199508255331]、王芳[370305196306032849]、王源湖[632822197109190024]、赵素红[370305196910170041]、 王秋梅 [370305197507053720]、邢霞[370305198112182848]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "贾来用",
          b: "370305194702174313",
          c: "",
          d: "中度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "淄博市临淄区蓝丝带社会服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "许爱莲[370305197011074325]、许永梅[370305197809304329]、周东超[232101198203196422]、孙保国[37030519660210531X]、李忠海[370305198211230032]、亓艳红[370304197909050024]、许永凤[370305197605244344]、颜琪琳[370305197012150748]",
          r: "执行中",
        },
        {
          a: "吕桂英",
          b: "370305194811203427",
          c: "",
          d: "中度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "淄博市临淄区蓝丝带社会服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张玉花[370305198004196521]、周东超[232101198203196422]、孙保国[37030519660210531X]、亓艳红[370304197909050024]、李忠海[370305198211230032]、康天宇[370305200610010715]、颜琪琳[370305197012150748]、郭环[37030519730320286X]",
          r: "执行中",
        },
        {
          a: "石敦荣",
          b: "370305195512155917",
          c: "",
          d: "中度失能",
          e: "半失能",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "淄博市临淄区蓝丝带社会服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "刘清华[370305198002151848]、周东超[232101198203196422]、李忠海[370305198211230032]、亓艳红[370304197909050024]、颜琪琳[370305197012150748]",
          r: "执行中",
        },
        {
          a: "蔺效忠",
          b: "370305195301223430",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "徐敬春",
          b: "37030519440919151X",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、朱满霞[370305197205282480]、边玉琪[37030519950818502X]、刘小丽[370725198605184400]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]",
          r: "执行中",
        },
        {
          a: "张金河",
          b: "370305194711080714",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "唐秀华",
          b: "370305195409210746",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "李风岐",
          b: "21072419461206001X",
          c: "",
          d: "中度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "何世英",
          b: "37030519580315288X",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "解希廷",
          b: "370305194502100416",
          c: "",
          d: "中度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "闫洪兰",
          b: "370305195411015632",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "尹承业",
          b: "370922194011281311",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]、于秀杰[370305197211100743]",
          r: "执行中",
        },
        {
          a: "马思福",
          b: "370305194207162817",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]",
          r: "执行中",
        },
        {
          a: "臧桂福",
          b: "370305195002262413",
          c: "",
          d: "重度失能",
          e: "",
          f: "",
          g: "政府托底型",
          h: "临淄区家庭养老床位",
          i: "临淄区义家人社会工作服务中心",
          j: "",
          k: "2022-01-15",
          l: "2024-01-14",
          m: "",
          n: "199.0",
          o: "",
          p: "医疗服务、基本照料(需要医疗服务）",
          q: "张嘉仪[370305200504236526]、焦现梅[37030519680219242X]、焦现云[370305197104042488]、焦艳丽[370305199204022424]、边玉琪[37030519950818502X]、王新芳[370305197705064041]、刘小丽[370725198605184400]、于世恒[370305200402145316]、刘伟娜[370305198206232826]、于秀杰[370305197211100743]、王爱防[370827198102103224]、刘彭广鉴[370305199406176229]、王丽娟[37030519781016532X]、于红娜[370305197708300783]、朱满霞[370305197205282480]",
          r: "执行中",
        },
      ];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    reset() {
      const th = this;
      Object.keys(th.query).forEach(function (key) {
        if (key == "value") {
          th.query.value = "1";
        } else {
          th.query[key] = "";
        }
      });
      this.dataRefresh();
    },
    submitForm(e) {
      const param = {
        ids: this.ids,
        organId: e.organId,
        userId: e.fullId,
      };
      this.$store.dispatch("jbsz/livingContractSaveData", param).then((res) => {
        this.dataRefresh();
      });
    },
    // 导入
    onXlsUploadSuccess(response) {
      if (response.success) {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.dataRefresh();
      } else {
        this.$message({
          type: "error",
          message: "导入失败",
        });
      }
    },
    async derive() {
      const token = await this.$store.dispatch("user/getToken");
      const form = Object.assign({}, this.form);
      if (this.options.shipColumn && this.options.shipColumn === "column2") {
        form.dataMode = 0;
      }
      this.$axios({
        method: "post",
        url: "/api/oldUser/doDownLoad",
        data: this.query,
        headers: {
          token: token,
          platform: "web",
        },
        responseType: "blob",
      })
        .then((response) => {
          if (response.headers["content-disposition"]) {
            const content = response.data;
            const blob = new Blob([content]); //构造一个blob对象来处理数据

            // fileName必用这种方式进行解析，否则乱码
            // Server.UrlEncode(filename);

            let contentDisposition = response.headers["content-disposition"];
            // fileName必用这种方式进行解析，否则乱码
            let fileName = window.decodeURI(
              contentDisposition.substring(contentDisposition.indexOf("=") + 1)
            );
            //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
            if ("download" in document.createElement("a")) {
              const link = document.createElement("a"); //创建a标签
              link.download = fileName; //a标签添加属性
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              document.body.appendChild(link);
              link.click(); //执行下载
              URL.revokeObjectURL(link.href); //释放url
              document.body.removeChild(link); //释放标签
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search() {
      this.$refs.drawerIshow.drawer = true;
    },
    setActiveItem(item) {
      this.activeItem = {
        name: item.name,
        key: item.key,
        component: item.component,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

.handleBtn {
  display: flex;
  align-items: center;
  margin: 1.25rem;
  justify-content: flex-end;
}

.rowStyle {
  margin-bottom: 0.625rem;
}

::v-deep .el-table th,
::v-deep .el-table td {
  text-align: center !important;
}

.data {
  display: flex;
}

.component {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
</style>
