<template>
  <!-- 市场化网站服务项目 -->
  <div class="gov">
    <div class="box-header">
      <div class="box-headertotle">
        政府购买服务专用项目为“政府购买服务”专用服务项目，一般由政策文件定义；市场化服务项目为网站发布、展示的面向公众的服务项目。
      </div>
      <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">新增</el-button>
    </div>
    <div class="box-line"></div>
    <div class="jtcw1">
      <hltableZ :columnEdit="table1.columnEdit" :tableColumn="table1.column" :apiurl="table1.apiurl"
        :refreshFlag="table1.refreshFlag" :selectedRows="table1.selectedRows" :isChecked="table1.isChecked"
        :isNumber="table1.isNumber" :formSearch="query1">
        <div slot-scope="selected" slot="EditColumn">
          <el-button icon="el-icon-delete" type="text" size="small" @click="editing()">编辑</el-button>
          <el-button icon="el-icon-delete" type="text" size="small" @click="delete selected.singlerow">删除</el-button>
        </div>
      </hltableZ>
    </div>

    <!-- 新增弹窗 -->
    <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize">
      <div class="schadd">
        <p class="schaddp">基本信息</p>
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="服务类型名称:">
            <el-input v-model="form.typename"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="8">
              <el-form-item label="父级项目:">
                <el-input type="textarea" v-model="form.typedescription"></el-input> </el-form-item></el-col>
            <el-col :span="3">
              <el-button type="primary" @click="selection()">选择</el-button></el-col>
            <el-col :span="1">
              <el-button type="primary" @click="clear()">清空</el-button></el-col>
          </el-row>
          <el-form-item label="末级节点:">
            <el-select v-model="form.region" placeholder="">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市场化类型:">
            <el-select v-model="form.region" placeholder="请选择">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="官方定义类型:">
            <el-select v-model="form.region" placeholder="请选择">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序码:">
            <el-input type="textarea" v-model="form.sorting"></el-input>
          </el-form-item>
          <el-form-item label="活动形式">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="schbut">
        <el-button type="primary" @abort="save()">保存</el-button>
        <el-button type="info" @click="back()">返回</el-button>
      </div>
    </Drawer>
    <!-- 新增选择 -->
    <Drawer :title="titlesl" ref="drawerIsl" :drawerSize="drawerSize">
      <el-form ref="formsl" :model="formsl" label-width="80px">
        <el-col :span="8">
          <el-form-item label="活动名称">
            <el-input v-model="formsl.name" placeholder="输入名称自动搜索"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <div class="treesl">
        <el-tree :data="data" show-checkbox node-key="id" :props="defaultProps">
        </el-tree>
      </div>
      <div class="schbut">
        <el-button type="primary" @abort="save()">保存</el-button>
        <el-button type="info" @click="back()">返回</el-button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
import { Drawer } from "@/components";
export default {
  components: { hltableZ, Drawer },
  data() {
    return {
      drawerSize: "60%",
      title: "政府购买服务项目新增",
      titlesl: "父级项目服务选择",
      table1: {
        columnEdit: true,
        column: [
          {
            label: "服务项目名称",
            prop: "oldName",
            checked: true,
          },
          {
            label: "官方定义统计大类",
            prop: "organName",
            checked: true,
          },
          {
            label: "启用状态",
            prop: "oldName",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      query1: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
      //新增
      form: {
        typename: "",
        typedescription: "",
        sorting: "",
      },
      //选择
      formsl: {
        name: "",
      },
      data: [{
        id: 1,
        label: '一级 1',
        children: [{
          id: 4,
          label: '二级 1-1',
          children: [{
            id: 9,
            label: '三级 1-1-1'
          }, {
            id: 10,
            label: '三级 1-1-2'
          }]
        }]
      },],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  mounted() { },
  methods: {
    add() {
      this.$refs.drawerIshow.drawer = true;
    },
    editing() {
      this.$refs.drawerIshow.drawer = true;
    },
    selection() {
      this.$refs.drawerIsl.drawer = true;
    },
    clear() { },
    save() { },
    back() { },
  },
};
</script>
<style lang="scss">
.gov {
  width: 100%;
  height: 100%;

  .box-header {
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 47px;

    .el-button--small {
      height: 30px !important;
    }
  }

  .box-line {
    width: 100%;
    height: 1px;
    background: #ccd2d9;
    margin-bottom: 20px;
  }

  .jtcw1 {
    width: 100%;
    height: 40rem;
    margin-top: 1rem;
  }
}

.schbut {
  width: 30%;
  display: flex;
  margin-left: 40%;
  justify-content: center;
}

.el-col-3 {
  width: 8.5%;
}

.el-button--primary {
  height: 36px;
}

.content {
  display: flex;
  flex-direction: column;

  .treesl {
    width: 100%;
    height: 80%;
  }
}
</style>
