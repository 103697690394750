<template>
  <div class="Baseconfiguration">
    <div class="form-unit">配置</div>
    <div class="from">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="16">
            <el-form-item label="流程配置:">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-button type="primary">保存</el-button>
          </el-col>
        </el-row>
        <div class="tishi">
          例：DRAFT-SHEQV-JIEDAO-QVXIAN-SHI-FP
          (说明：草稿-社区审批-街道审批-区县审批-市审批-审批通过);
          流程必须以DRAFT开头，FP结尾；
        </div>
      </el-form>
    </div>
    <div class="form-unit">预览</div>
    <div class="col-xs-12">
      <div class="control-label col-sm-2" title="">流程可视化：</div>
      <div class="col-sm-10">流程未配置</div>
    </div>
    <div class="box-line"></div>
    <div class="sevr">
        <el-button type="primary">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.Baseconfiguration {
  .form-unit {
    font-size: 15px;
    color: #6379bb;
    border-bottom: 1px solid #ddd;
    margin: 8px 10px 25px 10px;
    padding-bottom: 5px;
  }
  .tishi {
    color: #ccc;
    font-size: 16px;
    width: 500px;
    margin-left: 130px;
  }
  .col-xs-12 {
    font-size: 16px;
    width: 100%;
    display: flex;
    .col-sm-2 {
      width: 300px;
      margin-left: 57px;
    }
    .col-sm-10 {
      width: 500px;
      color: #f0ad4e;
      font-weight: bold;
    }
  }
  .box-line {
    width: 100%;
    height: 1px;
    background: #ccd2d9;
    margin-top: 20px;
  }
  .sevr{
    width: 100%;
    position: absolute;
    bottom: 40px;
    text-align: center;
  }
}
</style>
