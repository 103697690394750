<template>
  <div class="hl-full-container flexVertical">
    <div class="search">
      <el-form :model="form" ref="form" label-width="120px" inline>
        <el-form-item label="负责人姓名">
          <el-input
            v-model="form.name"
            placeholder="请输入负责人姓名"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button type="text" plain size="mini" icon="el-icon-upload2">
        模板下载</el-button
      >
      <el-button type="success" plain size="mini" icon="el-icon-upload">
        导入
      </el-button>

      <el-button type="primary" plain size="mini" icon="el-icon-download"
        >导出</el-button
      >

      <el-button type="primary" plain size="mini" icon="el-icon-plus"
        >新增</el-button
      >
      <el-button type="danger" plain size="mini" icon="el-icon-delete"
        >删除</el-button
      >
    </div>
    <!-- 表格 -->
    <hltableZ
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :isPage="false"
      :onlyShowTotal="true"
      :staticData="table.staticData"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :isNumber="table.isNumber"
      :isClickSelect="table.isClickSelect"
      :formSearch="query"
      :handleCellStyle="cellStyle"
      @cellClick="cellClick"
    >
    </hltableZ>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
export default {
  name: "fjzlgl",
  components: { hltableZ },
  data() {
    return {
      // 营业状态
      businessStatusOptions: [
        { label: "全部", value: null },
        { label: "正常运营", value: "1" },
        { label: "注销", value: "2" },
        { label: "取缔", value: "3" },
        { label: "停业整顿", value: "4" },
        { label: "事实处于未开展服务状态", value: "5" },
        { label: "关停", value: "6" },
      ],
      //表单信息-e
      form: {
        name: "",
      },
      // 表格属性值
      table: {
        staticData: [],
        columnEdit: false,
        column: [
          {
            label: "辅具名称",
            prop: "a",
            checked: true,
          },
          {
            label: "使用人",
            prop: "b",
            checked: true,
          },
          {
            label: "联系电话",
            prop: "c",
            checked: true,
          },
          {
            label: "地址",
            prop: "d",
            checked: true,
          },
          {
            label: "租借时间",
            prop: "e",
            checked: true,
          },
          {
            label: "租借金额",
            prop: "f",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        isClickSelect: false,
      },
      tableDataSource: [
        {
          1: 1,
          2: 2,
          3: 15655556666,
          4: 4,
          account: 5,
          6: 6,
        },
      ],
      query: {
        institutionName: "",
        businessStatus: "",
      },
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    // 查询
    dataRefresh() {
      this.table.staticData = [
        {
          xh: "1",
          a: "‌‌轮椅",
          b: "张克真",
          c: "15769876354",
          d: "如东路125号",
          e: "2024-10-25 10:20:25",
          f: "100.00",
        },
        {
          xh: "2",
          a: "‌‌腋拐",
          b: "刘二林",
          c: "16625745268",
          d: "勇士社区",
          e: "2024-10-30 16:00:02",
          f: "30.00",
        },
        {
          xh: "3",
          a: "‌‌盲杖",
          b: "孙成武",
          c: "13123658745",
          d: "雪宫社区",
          e: "2024-11-12 11:52:46",
          f: "15.00",
        },
      ];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    // 重置
    reset() {
      this.$refs.query.resetFields();
    },
    // 单元格点击
    cellClick(row, column, cell, event) {
      if (column.property === "account") {
        this.handleAccount();
      }
    },
    cellStyle(row, column) {
      if (column.property === "account") {
        return {
          color: "#409EFF",
          cursor: "pointer",
        };
      }
    },
    // 编辑
    handleEdit() {
      this.$refs.fwzcInfo.$refs.drawerIshow.drawer = true;
    },
    // 模块适配
    handleModule() {},
    // 打开账号
    handleAccount() {
      this.$refs.accountInfo.$refs.drawerIshow.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
.hl-full-container {
  font-size: 1rem;
  color: #606266;
  // 上方标题
  .topTitle {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 0.9375rem;
    .el-icon-film {
      font-size: 14px;
      margin-right: 3px;
    }

    .title {
      font-size: 16px;
      margin-right: 0.3125rem;
    }

    .subTitle {
      font-size: 12px;
      color: #aaa;
    }
  }
}
</style>
