<template>
    <div class="hl-full-container flexVertical">
        <!-- 查询 -->
        <div class="search">
            <el-form :model="query" ref="query" label-width="80px" inline>
                <el-form-item label="服务名称">
                    <el-input v-model="query.oldName" placeholder="请输入服务名称"></el-input>
                </el-form-item>
                <template v-if="searchIsShow">

                </template>
                <el-form-item>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
                    <el-button size="small" icon="el-icon-refresh-left" @click="reset('Form')">重置</el-button>
                    <el-button size="small" type="info"
                        :icon='searchIsShow == false ? "el-icon-arrow-down" : "el-icon-arrow-up"'
                        @click="more">更多</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 中间 -->
        <div class="toolbar">
            <el-button type="primary" plain size="mini" icon="el-icon-plus" @click="add($event, 1)">新增</el-button>
        </div>
        <hltableZ rowKey="id" :isPage="false" :isNumber="false" :isChecked="false" :columnEdit="TableOptions.columnEdit"
            :formSearch="form" :tableColumn="TableOptions.column" :selectedRows="TableOptions.selectedRows"
            :apiurl="TableOptions.apiurl" :refreshFlag="TableOptions.refreshFlag" :isClickSelect="false">
            <div slot-scope="selected" slot="EditColumn">
                <el-button icon="el-icon-plus" type="text" size="small" @click="add(selected.singlerow, 2)">新增</el-button>
                <el-button icon="el-icon-delete" type="text" size="small">删除</el-button>
                <el-button icon="el-icon-delete" type="text" size="small" @click="add(selected.singlerow, 3)">编辑</el-button>
            </div>
        </hltableZ>
        <!-- 弹窗 -->
        <Drawer :title="title" ref="drawerIshow" :buttonIsShow="true" :submitFormName="submitFormName">
            <el-form :model="ruleFormDrawer" :rules="rulesDrawer" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="服务名称" prop="name">
                    <el-input v-model="ruleFormDrawer.name"></el-input>
                </el-form-item>
                <el-form-item label="服务描述" prop="name">
                    <el-input v-model="ruleFormDrawer.name"></el-input>
                </el-form-item>
                <el-form-item label="服务区域" prop="name">
                    <el-select v-model="ruleFormDrawer.name" placeholder="请选择活动区域">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="name">
                    <el-switch v-model="ruleFormDrawer.name"></el-switch>
                </el-form-item>
                <el-form-item label="类别" prop="name" v-if='elForm'>
                    <el-select v-model="ruleFormDrawer.name" placeholder="请选择活动区域">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="重复下单" prop="name" v-if='elForm'>
                    <el-select v-model="ruleFormDrawer.name" placeholder="请选择活动区域">
                        <el-radio v-model="radio" label="1">备选项</el-radio>
                        <el-radio v-model="radio" label="2">备选项</el-radio>
                    </el-select>
                </el-form-item>
                <el-form-item label="图片" prop="name" v-if=elForm>
                    <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card"
                        :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>
            </el-form>

        </Drawer>
        <!-- 新增子服务 -->
    </div>
</template>
  
<script>

import { hltableZ, Drawer } from "@/components";
export default {
    name: "fwxmgl",
    components: { hltableZ, Drawer },
    data() {
        return {
            query: { oldName: "", organ: '', sex: '', level: '', area: '', status: "", idCard: '', phone: '' },
            //   表格
            form: {},
            TableOptions: {
                selectedRows: [],
                apiurl: "",
                refreshFlag: '',
                columnEdit: true,
                column: [{
                    label: "服务名称",
                    prop: "moduleName",
                    checked: true,
                }, {
                    label: "服务描述",
                    prop: "url",
                    checked: true,
                }, {
                    label: "服务区域",
                    prop: "icon",
                    checked: true,
                }, {
                    label: "状态",
                    prop: "sortNo",
                    checked: true,
                }, {
                    label: "类别",
                    prop: "remark",
                    checked: true,
                }]
            },
            rulesAdd: [],
            token: { token: localStorage.getItem("systoken") },
            xlsAreafileList: [],
            xlsAreaAction: "/api/oldUser/importUser.json",
            title: '新增',
            activeItem: {
            },
            searchIsShow: false,
            submitFormName: '新增',
            ruleFormDrawer: { name: "" },
            rulesDrawer: {},
            elForm: true,
            dialogImageUrl: '',
            dialogVisible: false
        };
    },

    mounted() {

    },

    methods: {
        timeFormatter(row, column, cellValue) {
            return cellValue ? this.$timestampTostr(cellValue) : "";
        },
        //   查询
        dataRefresh() {
            this.table.refreshFlag = (new Date()).getTime().toString()
        },
        reset() {
            const th = this
            Object.keys(th.query).forEach(function (key) {
                if (key == 'value') {
                    th.query.value = '1'
                } else {
                    th.query[key] = ''
                }

            })
            this.dataRefresh();
        },
        // 导入
        onXlsUploadSuccess(response) {
            if (response.success) {
                this.$message({
                    type: "success",
                    message: "导入成功",
                });
                this.dataRefresh();
            } else {
                this.$message({
                    type: "error",
                    message: "导入失败",
                });
            }
        },
        add(e, b) {
            if (b == 1) {
                this.elForm = true
            } else if (b == 2) {
                this.elForm = false
            }
            this.$refs.drawerIshow.drawer = true
        },
        setActiveItem(item) {
            this.activeItem = {
                name: item.name,
                key: item.key,
                component: item.component,
            };
        },
        more() {
            this.searchIsShow = !this.searchIsShow
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        }
    },
};
</script>
  
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

::v-deep .el-table th,
::v-deep .el-table td {
    text-align: center !important;
}

.data {
    display: flex;
}

.component {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}
</style>
  