<template>
    <div class="hl-full-container flexVertical">

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="170px" class="demo-ruleForm">
            <div class="title">
                补贴规则
            </div>
            <el-row :gutter="17">
                <el-col :span="17">
                    <el-form-item label="去世当月是否享受补贴:" prop="name">
                        <el-select placeholder="请选择">
                            <el-option key="是" label="是" value="是"> </el-option>
                            <el-option key="否" label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row :gutter="17">
                <el-col :span="17">
                    <el-form-item label="补贴分类制定者:" prop="name">
                        <el-select placeholder="请选择">
                            <el-option key="市级统一定制" label="市级统一定制" value="市级统一定制"> </el-option>
                            <el-option key="区（县）级定制" label="区（县）级定制" value="区（县）级定制"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="title">
                审批流程
            </div>
            <el-row :gutter="17">
                <el-col :span="17">
                    <el-form-item label="发起者:" prop="name">
                        <el-select placeholder="请选择">
                            <el-option key="街道" label="街道" value="街道"> </el-option>
                            <el-option key="社区" label="社区" value="社区"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="17">
                <el-col :span="17">
                    <el-form-item label="发起者:" prop="name">
                        <el-button type="success" plain icon="el-icon-s-grid" size="mini" @click="grid">审批流程</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="title">
                其他
            </div>
            <el-row :gutter="17">
                <el-col :span="17">
                    <el-form-item label="申请附件配置:" prop="name">
                        <el-input type="textarea" :rows="5" placeholder="请输入内容">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="17">
                <el-col :span="17">
                    <el-form-item label="每月最晚申请日期（日）:" prop="name">
                        <el-input-number controls-position="right" @change="handleChange" :min="0"
                            :max="31"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="17">
                <el-col :span="17">
                    <el-form-item label="备注 :" prop="name">
                        <el-input type="textarea" :rows="5" placeholder="请输入内容">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item class="belForm">
                <el-button type="primary" @click="submitForm('ruleForm')" icon="el-icon-folder">保存</el-button>
            </el-form-item>
        </el-form>
        <Drawer :title="title" ref="drawerIshow">
            <div v-if="inforIsShow">请先配置发起者</div>
            <div v-else>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="170px">
                    <div class="title">
                        配置
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="16">
                            <el-form-item label="流程配置 :" prop="name">
                                <el-input placeholder="请输入内容">
                                </el-input>
                            </el-form-item>

                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" icon="el-icon-folder" size="mini">保存</el-button>
                        </el-col>

                    </el-row>
                    <div style=" color: #ccc;display: flex;justify-content: center;">
                        例：DRAFT-SHEQV-JIEDAO-QVXIAN-SHI-FP (说明：草稿-社区审批-街道审批-区县审批-市审批-审批通过);
                        流程必须以DRAFT开头，FP结尾；
                    </div>

                </el-form>
                <div class="title">
                    配置
                </div>
                <div v-if='inforIsShow2'>
                    请先填写流程配置
                </div>
                <div v-else>
                    流程可视化：<span style="color:#009688;font-size: 24px;font-weight: bold">草稿 >社区(村)审批 >街镇审批 >县区审批 >市审批
                        >审批结束</span>
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script>
import { Drawer } from "@/components";
export default {
    name: 'YanglaoRulesOnSubsidy',
    components: { Drawer },
    data() {
        return {
            ruleForm: {

            },
            rules: {},
            title: '信息',
            inforIsShow: true,
            inforIsShow2: true
        };
    },

    mounted() {

    },

    methods: {
        grid() {
            this.$refs.drawerIshow.drawer = true
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

.hl-full-container {
    height: 60rem
}

.title {
    font-size: 15px;
    color: #409EFF;
    border-bottom: 1px solid #ddd;
    margin: 1rem 0;
}

.belForm {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>