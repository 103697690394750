<template>
  <Drawer title="员工基础档案" ref="drawerIshow" :buttonIsShow="true" submitFormName="保存" @resetForm="resetForm">
    <!-- 员工基础档案选项卡 -->
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in tabsList"
        :key="item.label"
        :label="item.label"
        :name="item.name"
      >
        <!-- 员工基础档案 -->
        <div class="employeeFile">
          <!-- 表单 -->
          <el-row v-if="item.name === 'first'" :gutter="24">
            <el-form
              class="form"
              ref="form"
              :model="dataForm"
              label-width="140px"
            >
              <el-col :span="24">
                <!-- 标题 -->
                <subTitle
                  informationName="基本信息"
                  informationDesc="标记为●的为金民工程要求"
                ></subTitle>
              </el-col>
              <el-col :span="12">
                <el-form-item label="头像">
                  <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :on-success="handlerImgUpload"
                  >
                    <img
                      v-if="dataForm.imgSrc"
                      :src="dataForm.imgSrc"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="服务组织" prop="serviceOrganization">
                  <el-select
                    v-model="dataForm.serviceOrganization"
                    placeholder="请选择服务组织"
                  >
                    <el-option
                      v-for="item in serviceOrganizationOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="姓名" prop="name">
                  <el-input v-model="dataForm.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证号" prop="IDCard">
                  <el-input v-model="dataForm.IDCard"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="身份证照片(国徽面)">
                  <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :on-success="handlerImgUpload"
                  >
                    <img
                      v-if="dataForm.imgSrc"
                      :src="dataForm.imgSrc"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证照片(头像面)">
                  <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :on-success="handlerImgUpload"
                  >
                    <img
                      v-if="dataForm.imgSrc"
                      :src="dataForm.imgSrc"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="出生日期" prop="birthdayDate">
                  <el-date-picker
                    v-model="dataForm.birthdayDate"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="性别" prop="gender">
                  <el-select
                    v-model="dataForm.gender"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option label="女" value="1"></el-option>
                    <el-option label="男" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="民族" prop="nation">
                  <el-select
                    v-model="dataForm.nation"
                    placeholder="请选择服务组织"
                  >
                    <el-option
                      v-for="item in nationOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="政治面貌" prop="politicalOutlook">
                  <el-select
                    v-model="dataForm.politicalOutlook"
                    placeholder="请选择服务组织"
                  >
                    <el-option
                      v-for="item in politicalOutlookOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系方式" prop="contactWay">
                  <el-input v-model="dataForm.contactWay"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="地址" prop="address">
                  <el-input v-model="dataForm.address"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="文化程度" prop="educationLevel">
                  <el-select
                    v-model="dataForm.educationLevel"
                    placeholder="请选择文化程度"
                  >
                    <el-option
                      v-for="item in educationLevelOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="毕业学校" prop="graduationSchool">
                  <el-input v-model="dataForm.graduationSchool"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="专业" prop="speciality">
                  <el-input v-model="dataForm.speciality"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="简介" prop="briefIntroduction">
                  <el-input v-model="dataForm.briefIntroduction"></el-input>
                </el-form-item>
              </el-col>
              <!-- 人脸识别 -->
              <el-col :span="24">
                <subTitle information-name="人脸识别"></subTitle>
              </el-col>
              <el-col :span="12">
                <el-form-item label="人脸识别原始照片">
                  <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :on-success="handlerImgUpload"
                  >
                    <img
                      v-if="dataForm.imgSrc"
                      :src="dataForm.imgSrc"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>

              <!-- 在职及参保情况 -->
              <el-col :span="24">
                <subTitle information-name="在职及参保情况"></subTitle>
              </el-col>
              <el-col :span="12">
                <el-form-item label="在职状态" prop="obStatus">
                  <el-select
                    v-model="dataForm.obStatus"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option label="在职" value="1"></el-option>
                    <el-option label="离职" value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="入职日期" prop="joinedDate">
                  <el-date-picker
                    v-model="dataForm.joinedDate"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="劳动合同签订" prop="laborContract">
                  <el-select
                    v-model="dataForm.laborContract"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option label="已签订" value="1"></el-option>
                    <el-option label="未签订" value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="合同结束日期" prop="contractEndDate">
                  <el-date-picker
                    v-model="dataForm.contractEndDate"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="合同照片">
                  <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :on-success="handlerImgUpload"
                  >
                    <img
                      v-if="dataForm.imgSrc"
                      :src="dataForm.imgSrc"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否缴纳保险" prop="payInsurance">
                  <el-select
                    v-model="dataForm.payInsurance"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="参保证明照片">
                  <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :on-success="handlerImgUpload"
                  >
                    <img
                      v-if="dataForm.imgSrc"
                      :src="dataForm.imgSrc"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="参保备注" prop="payInsuranceRemark">
                  <el-input v-model="dataForm.payInsuranceRemark"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="安全责任承诺书" prop="secureCommitment">
                  <el-input v-model="dataForm.secureCommitment"></el-input>
                </el-form-item>
              </el-col>
              <!-- 工作信息 -->
              <el-col :span="24">
                <subTitle information-name="工作信息"></subTitle>
              </el-col>
              <el-col :span="12">
                <el-form-item label="部门" prop="department">
                  <el-input v-model="dataForm.department"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="职业类型" prop="occupationType">
                  <el-select
                    v-model="dataForm.occupationType"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in occupationTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="岗位类型" prop="positionType">
                  <el-select
                    v-model="dataForm.positionType"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option label="工人" value="1"></el-option>
                    <el-option label="干部" value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="护理员等级证书" prop="nurseCertificate">
                  <el-select
                    v-model="dataForm.nurseCertificate"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option label="有" value="1"></el-option>
                    <el-option label="无" value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="护理员资格证等级" prop="nurseLevel">
                  <el-select
                    v-model="dataForm.nurseLevel"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                  <el-option
                      v-for="item in nurseLevelTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="从业起始月份" prop="startMounth">
                  <el-date-picker
                    v-model="dataForm.startMounth"
                    type="mounth"
                    placeholder="选择月份"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="健康证" prop="healthCertificate">
                  <el-select
                    v-model="dataForm.healthCertificate"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option label="有" value="1"></el-option>
                    <el-option label="无" value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="健康证有效日期" prop="effectiveDate">
                  <el-date-picker
                    v-model="dataForm.effectiveDate"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="健康证照片">
                  <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :on-success="handlerImgUpload"
                  >
                    <img
                      v-if="dataForm.imgSrc"
                      :src="dataForm.imgSrc"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <!-- 开户行信息 -->
              <el-col :span="24">
                <subTitle information-name="开户行信息"></subTitle>
              </el-col>
              <el-col :span="12">
                <el-form-item label="银行名称" prop="bankName">
                  <el-input v-model="dataForm.bankName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="开户行" prop="bankInfo">
                  <el-input v-model="dataForm.bankInfo"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="银行账号" prop="bankAccount">
                  <el-input v-model="dataForm.bankAccount"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="持卡人" prop="bankPeople">
                  <el-input v-model="dataForm.bankPeople"></el-input>
                </el-form-item>
              </el-col>
               <!-- 紧急联系人信息 -->
               <el-col :span="24">
                <subTitle information-name="紧急联系人信息"></subTitle>
              </el-col>
              <el-col :span="8">
                <el-form-item label="紧急联系人1" prop="emergencyContactOne">
                  <el-input v-model="dataForm.emergencyContactOne"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="与本人的关系" prop="relationshipOne">
                  <el-input v-model="dataForm.relationshipOne"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系电话" prop="contactPhoneOne">
                  <el-input v-model="dataForm.contactPhoneOne"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="紧急联系人2" prop="emergencyContactTwo">
                  <el-input v-model="dataForm.emergencyContactTwo"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="与本人的关系" prop="relationshipTwo">
                  <el-input v-model="dataForm.relationshipTwo"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系电话" prop="contactPhoneTwo">
                  <el-input v-model="dataForm.contactPhoneTwo"></el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
          <!-- 标题 -->
          <subTitle :informationName="informationName" informationDesc="标记为●的为金民工程要求"></subTitle>
          <!-- 表格 -->
          <hltableZ
            v-if="!['first', 'eighth', 'ninth'].includes(item.name)"
            style="height: 52rem"
            :columnEdit="table.columnEdit"
            :tableColumn="table.column"
            :apiurl="table.apiurl"
            :refreshFlag="table.refreshFlag"
            :isChecked="table.isChecked"
            :isNumber="table.isNumber"
            :isClickSelect="table.isClickSelect"
            :staticData="tableDataSource"
          >
            <div slot-scope="selected" slot="EditColumn">
              <el-button
                icon="el-icon-error"
                type="text"
                size="small"
                @click="unqualified(selected.singlerow)"
                >不合格</el-button
              >
            </div>
          </hltableZ>
        </div>
      </el-tab-pane>
    </el-tabs>
  </Drawer>
</template>

<script>
import { Drawer, hltableZ } from "@/components";
import subTitle from "../common/SubTitle";
import { employeeFileTablesData } from "./tabTablesData";
const employeeFileTabs = [
  { label: "基础信息", name: "first" },
  { label: "资格证", name: "second" },
  { label: "从业经历", name: "third" },
  { label: "培训经历", name: "fourth" },
  { label: "处罚记录", name: "fifth" },
  { label: "奖励记录", name: "sixth" },
  { label: "补贴记录", name: "seventh" },
  { label: "疫情防控信息", name: "eighth" },
  { label: "设备绑定", name: "ninth" },
];

export default {
  name: "EmployeeFile",
  components: { Drawer, hltableZ, subTitle },
  data() {
    return {
      tabsList: [...employeeFileTabs],
      activeName: "first",
      // 标题
      informationName: '基本信息',
      // 服务组织
      serviceOrganizationOptions: [
        { label: "全部", value: null },
        { label: "郑大姐服务组织", value: 1 },
        { label: "高新区居家养老服务组织", value: 2 },
      ],
      // 民族
      nationOptions: [
        { label: "汉族", value: 1 },
        { label: "蒙古族", value: 2 },
        { label: "回族", value: 3 },
        { label: "藏族", value: 4 },
      ],
      // 政治面貌
      politicalOutlookOptions: [
        { label: "中国共产党党员", value: 1 },
        { label: "中国共产党预备党员", value: 2 },
        { label: "中国国民党革命委员会会员", value: 3 },
        { label: "中国民主同盟盟员", value: 4 },
      ],
      // 文化程度
      educationLevelOptions: [
        { label: "初中", value: 1 },
        { label: "高中/中专", value: 2 },
        { label: "大学专科", value: 3 },
        { label: "大学本科", value: 4 },
      ],
      // 职业类型
      occupationTypeOptions: [
        { label: "管理人员", value: 1 },
        { label: "医师", value: 2 },
        { label: "养老护理员", value: 3 },
        { label: "社区助老员", value: 4 },
      ],
       // 护理员资格证等级
       nurseLevelTypeOptions: [
        { label: "未评级", value: 1 },
        { label: "一级", value: 2 },
        { label: "二级", value: 3 },
        { label: "三级", value: 4 },
        { label: "四级", value: 5 },
        { label: "五级", value: 6 },
       ],
      table: { ...employeeFileTablesData.first },
      // 表格静态数据
      tableDataSource: [
        {
          1: 1,
          2: 1,
          3: 1,
          4: 1,
          5: 1,
          6: 1,
          7: 1,
          8: 1,
          9: 1,
          10: 1,
          12: 1,
          13: 1,
          14: 1,
          15: 1,
          16: 1,
          17: 1,
          18: 1,
        },
      ],
      dataForm: {
        serviceOrganization: "",
        name: "",
        IDCard: "",
        birthdayDate: "",
        gender: "",
        nation: "",
        contactWay: "",
        address: "",
        educationLevel: "",
        graduationSchool: "",
        speciality: "",
        briefIntroduction: "",
        obStatus: "",
        joinedDate: "",
        laborContract: "",
        contractEndDate: "",
        payInsurance: "",
        payInsuranceRemark: "",
        secureCommitment: "",
        department: "",
        occupationType: "",
        positionType: "",
        startMounth: "",
        healthCertificate: "",
        bankName: "",
        bankInfo: "",
        bankAccount: "",
        bankPeople: '',
        emergencyContactOne: '',
        relationshipOne: '',
        contactPhoneOne: '',
        emergencyContactTwo: '',
        relationshipTwo: '',
        contactPhoneTwo: '',
      },
    };
  },

  mounted() {},

  methods: {
    // tab切换
    handleClick(e) {
      this.informationName = e.label
    },
    // 上传
    handlerImgUpload(res) {
      if (res.code === 200) {
        this.dataForm.imgSrc = res.url;
      } else {
        this.$message.warning(res.msg);
      }
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
// 图片缩略图样式
.avatar-uploader {
  display: inline-block;
  text-align: center;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
  .avatar {
    width: 148px;
    height: 148px;
  }
  i {
    font-size: 28px;
    color: #8c939d;
  }
}
</style>
