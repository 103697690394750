// 员工档案的表格数据
export const employeeFileTablesData = {
  first: {
    columnEdit: true,
    column: [
      {
        label: "老人姓名",
        prop: "1",
        checked: true,
        width: "150",
      },
      {
        label: "身份证号",
        prop: "2",
        checked: true,
        width: "150",
      },
      {
        label: "补贴类型",
        prop: "3",
        checked: true,
        width: "150",
      },
      {
        label: "就餐日期",
        prop: "4",
        checked: true,
        width: "150",
      },
      {
        label: "监管审查",
        prop: "5",
        checked: true,
        width: "150",
      },
      {
        label: "身份认证方式",
        prop: "6",
        checked: true,
        width: "150",
      },
      {
        label: "菜品内容",
        prop: "7",
        checked: true,
        width: "150",
      },
      {
        label: "原价(元)",
        prop: "8",
        checked: true,
        width: "150",
      },
      {
        label: "补贴(元)",
        prop: "9",
        checked: true,
        width: "150",
      },
      {
        label: "市补贴(元)",
        prop: "10",
        checked: true,
        width: "150",
      },
      {
        label: "区补贴(元)",
        prop: "11",
        checked: true,
        width: "150",
      },
      {
        label: "非政府补贴(元)",
        prop: "12",
        checked: true,
        width: "150",
      },
      {
        label: "实付(元)",
        prop: "13",
        checked: true,
        width: "150",
      },
      {
        label: "支付方式/状态",
        prop: "14",
        checked: true,
        width: "150",
      },
      {
        label: "菜品类型",
        prop: "15",
        checked: true,
        width: "150",
      },
      {
        label: "下单时间",
        prop: "16",
        checked: true,
        width: "150",
      },
      {
        label: "助餐站点",
        prop: "17",
        checked: true,
        width: "150",
      },
      {
        label: "订单来源",
        prop: "18",
        checked: true,
        width: "150",
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
    isClickSelect: false,
  },
  second: {
    columnEdit: true,
    column: [
      {
        label: "老人姓名",
        prop: "1",
        checked: true,
        width: "150",
      },
      {
        label: "身份证号",
        prop: "2",
        checked: true,
        width: "150",
      },
      {
        label: "就餐日期",
        prop: "3",
        checked: true,
        width: "150",
      },
      {
        label: "入住养老机构",
        prop: "4",
        checked: true,
        width: "150",
      },
      {
        label: "入住日期",
        prop: "5",
        checked: true,
        width: "150",
      },
      {
        label: "监管审查",
        prop: "6",
        checked: true,
        width: "150",
      },
      {
        label: "身份认证方式",
        prop: "7",
        checked: true,
        width: "150",
      },
      {
        label: "菜品内容",
        prop: "8",
        checked: true,
        width: "150",
      },
      {
        label: "原价(元)",
        prop: "9",
        checked: true,
        width: "150",
      },
      {
        label: "补贴(元)",
        prop: "10",
        checked: true,
        width: "150",
      },
      {
        label: "市补贴(元)",
        prop: "11",
        checked: true,
        width: "150",
      },
      {
        label: "区补贴(元)",
        prop: "12",
        checked: true,
        width: "150",
      },
      {
        label: "非政府补贴(元)",
        prop: "13",
        checked: true,
        width: "150",
      },
      {
        label: "实付(元)",
        prop: "14",
        checked: true,
        width: "150",
      },
      {
        label: "支付方式/状态",
        prop: "15",
        checked: true,
        width: "150",
      },
      {
        label: "下单时间",
        prop: "16",
        checked: true,
        width: "150",
      },
      {
        label: "助餐站点",
        prop: "17",
        checked: true,
        width: "150",
      },
      {
        label: "订单来源",
        prop: "18",
        checked: true,
        width: "150",
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
    isClickSelect: false,
  },
  third: {
    columnEdit: true,
    column: [
      {
        label: "老人姓名",
        prop: "1",
        checked: true,
        width: "150",
      },
      {
        label: "身份证号",
        prop: "2",
        checked: true,
        width: "150",
      },
      {
        label: "就餐日期",
        prop: "3",
        checked: true,
        width: "150",
      },
      {
        label: "去世日期",
        prop: "4",
        checked: true,
        width: "150",
      },
      {
        label: "监管审查",
        prop: "5",
        checked: true,
        width: "150",
      },
      {
        label: "身份认证方式",
        prop: "6",
        checked: true,
        width: "150",
      },
      {
        label: "菜品内容",
        prop: "7",
        checked: true,
        width: "150",
      },
      {
        label: "原价(元)",
        prop: "8",
        checked: true,
        width: "150",
      },
      {
        label: "补贴(元)",
        prop: "9",
        checked: true,
        width: "150",
      },
      {
        label: "市补贴(元)",
        prop: "10",
        checked: true,
        width: "150",
      },
      {
        label: "区补贴(元)",
        prop: "11",
        checked: true,
        width: "150",
      },
      {
        label: "非政府补贴(元)",
        prop: "12",
        checked: true,
        width: "150",
      },
      {
        label: "实付(元)",
        prop: "13",
        checked: true,
        width: "150",
      },
      {
        label: "支付方式/状态",
        prop: "14",
        checked: true,
        width: "150",
      },
      {
        label: "下单时间",
        prop: "15",
        checked: true,
        width: "150",
      },
      {
        label: "助餐站点",
        prop: "16",
        checked: true,
        width: "150",
      },
      {
        label: "订单来源",
        prop: "17",
        checked: true,
        width: "150",
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
    isClickSelect: false,
  }
}