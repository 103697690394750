// 综合统计选项卡的表格数据
export const statisticsTablesData = {
  first: {
    columnEdit: true,
    column: [
      {
        label: "助餐站点",
        prop: "oldName",
        checked: true,
        width: "150",
      },
      {
        label: "老人姓名",
        prop: "2",
        checked: true,
        width: "150",
      },
      {
        label: "老人身份证",
        prop: "organName",
        checked: true,
        width: "150",
      },
      {
        label: "户口所在地",
        prop: "sex",
        checked: true,
        width: "150",
      },
      {
        label: "补贴类型",
        prop: "age",
        checked: true,
        width: "150",
      },
      {
        label: "监管审查",
        prop: "idCard",
        checked: true,
        width: "150",
      },
      {
        label: "套餐金额(元)",
        prop: "phone",
        checked: true,
        width: "150",
      },
      {
        label: "实付金额(元)",
        prop: "oldType",
        checked: true,
        width: "150",
      },
      {
        label: "补贴总金额(元)",
        prop: "serveType",
        checked: true,
        width: "150",
      },
      {
        label: "政府补贴金额(元)",
        prop: "level",
        checked: true,
        width: "150",
      },
      {
        label: "其他补贴金额(元)",
        prop: "areaName",
        checked: true,
        width: "150",
      },
      {
        label: "配送状态",
        prop: "status",
        checked: true,
        width: "150",
      },
      {
        label: "取餐状态",
        prop: "address",
        checked: true,
        width: "150",
      },
      {
        label: "配餐厨房",
        prop: "createTime",
        checked: true,
        width: "150",
      },
      {
        label: "订单来源",
        prop: "createTime",
        checked: true,
        width: "150",
      },
      {
        label: "订单类型",
        prop: "createTime",
        checked: true,
        width: "150",
      },
      {
        label: "餐品类型",
        prop: "createTime",
        checked: true,
        width: "150",
      },
      {
        label: "菜品名称",
        prop: "createTime",
        checked: true,
        width: "150",
      },
      {
        label: "就餐日期",
        prop: "createTime",
        checked: true,
        width: "150",
      },
      {
        label: "就餐时段",
        prop: "createTime",
        checked: true,
        width: "150",
      },
    ],
    apiurl: "lrxx/getDataList",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: false,
  },
  second: {
    columnEdit: false,
    column: [
      {
        label: "服务对象",
        prop: "oldName",
        checked: true,
      },
      {
        label: "身份证号",
        prop: "2",
        checked: true,
      },
      {
        label: "补贴助餐人次",
        prop: "organName",
        checked: true,
      },
      {
        label: "补贴总金额(元)",
        prop: "sex",
        checked: true,
      },
      {
        label: "补贴分类",
        prop: "age",
        checked: true,
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
  },
  third: {
    columnEdit: false,
    column: [
      {
        label: "助餐站点",
        prop: "oldName",
        checked: true,
      },
      {
        label: "补贴助餐人次",
        prop: "2",
        checked: true,
      },
      {
        label: "日均补贴人次",
        prop: "organName",
        checked: true,
      },
      {
        label: "实付金额(元)",
        prop: "sex",
        checked: true,
      },
      {
        label: "补贴总金额(元)",
        prop: "age",
        checked: true,
      },
      {
        label: "政府补贴金额(元)",
        prop: "age",
        checked: true,
      },
      {
        label: "其他补贴金额(元)",
        prop: "age",
        checked: true,
      },
      {
        label: "营运天数",
        prop: "age",
        checked: true,
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
  },
  fourth: {
    columnEdit: false,
    column: [
      {
        label: "就餐日期",
        prop: "oldName",
        checked: true,
      },
      {
        label: "助餐总人次",
        prop: "2",
        checked: true,
      },
      {
        label: "补贴助餐人次",
        prop: "organName",
        checked: true,
      },
      {
        label: "实付金额(元)",
        prop: "sex",
        checked: true,
      },
      {
        label: "补贴总金额(元)",
        prop: "age",
        checked: true,
      },
      {
        label: "政府补贴金额(元)",
        prop: "age",
        checked: true,
      },
      {
        label: "其他补贴金额(元)",
        prop: "age",
        checked: true,
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
  },
  fifth: {
    columnEdit: false,
    column: [
      {
        label: "区域",
        prop: "oldName",
        checked: true,
      },
      {
        label: "长者饭堂数",
        prop: "2",
        checked: true,
      },
      {
        label: "登记补贴老人数(按户口)",
        prop: "organName",
        checked: true,
        width: 200,
      },
      {
        label: "补贴就餐人次",
        prop: "sex",
        checked: true,
      },
      {
        label: "总补贴金额",
        prop: "age",
        checked: true,
      },
      {
        label: "政府补贴金额",
        prop: "age",
        checked: true,
      },
      {
        label: "其他补贴金额",
        prop: "age",
        checked: true,
      },
      {
        label: "实付金额",
        prop: "age",
        checked: true,
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
  },
};
// 会员管理的表格数据
export const memberTablesData = {
  first: {
    staticData: [],
    columnEdit: false,
    column: [
      {
        label: "姓名",
        prop: "a",
        checked: true,
        width: "150",
      },
      {
        label: "身份证",
        prop: "b",
        checked: true,
        width: "150",
      },
      {
        label: "手机号",
        prop: "c",
        checked: true,
        width: "150",
      },
      {
        label: "年龄",
        prop: "d",
        checked: true,
        width: "150",
      },
      {
        label: "地址",
        prop: "e",
        checked: true,
        width: "150",
      },
      {
        label: "区县",
        prop: "f",
        checked: true,
        width: "150",
      },
      {
        label: "街道",
        prop: "g",
        checked: true,
        width: "150",
      },
      {
        label: "社区",
        prop: "h",
        checked: true,
        width: "150",
      },
      {
        label: "责任助餐点",
        prop: "i",
        checked: true,
        width: "150",
      },
      {
        label: "对象性质",
        prop: "j",
        checked: true,
        width: "150",
      },
      {
        label: "补贴分类",
        prop: "k",
        checked: true,
        width: "150",
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: true,
    isNumber: false,
    isClickSelect: false,
    widthClick: "300",
  },
  // second: {
  //   columnEdit: true,
  //   column: [
  //     {
  //       label: "会员姓名",
  //       prop: "1",
  //       checked: true,
  //       width: "150",
  //     },
  //     {
  //       label: "身份证号",
  //       prop: "2",
  //       checked: true,
  //       width: "150",
  //     },
  //     {
  //       label: "助餐站点",
  //       prop: "3",
  //       checked: true,
  //       width: "150",
  //     },

  //     {
  //       label: "手机号",
  //       prop: "phone",
  //       checked: true,
  //       width: "150",
  //     },

  //     {
  //       label: "账号余额(元)",
  //       prop: "5",
  //       checked: true,
  //       width: "150",
  //     },
  //     {
  //       label: "人脸照片",
  //       prop: "img",
  //       checked: true,
  //       width: "200",
  //       columnType: 'img'
  //     },
  //     {
  //       label: "IC卡支付",
  //       prop: "IC",
  //       checked: true,
  //       width: "150",
  //       formatter(value) {
  //         const val = value.IC;
  //         return val? "支持" : "不支持";
  //       },
  //     },
  //     {
  //       label: "创建时间",
  //       prop: "8",
  //       checked: true,
  //       width: "150",
  //     },
  //   ],
  //   apiurl: "",
  //   refreshFlag: "",
  //   selectedRows: [],
  //   isChecked: true,
  //   isNumber: false,
  //   isClickSelect: false,
  //   widthClick: '300'
  // },
};
// 资金流水的表格数据
export const moneyFlowTablesData = {
  first: {
    columnEdit: false,
    column: [
      {
        label: "站点名称",
        prop: "buffetId",
        checked: true,
        width: "150",
      },
      {
        label: "会员姓名",
        prop: "userName",
        checked: true,
        width: "150",
      },
      {
        label: "会员类型",
        prop: "userType",
        checked: true,
        width: "150",
        formatter(value) {
          var a = "";
          if (value.userType == "1") {
            a = "补贴会员";
          } else {
            a = "普通会员";
          }
          return a;
        },
      },
      {
        label: "变动日期",
        prop: "createTime",
        checked: true,
        width: "190",
        formatter(value) {
          let time = new Date(value.createTime);
          let year = time.getFullYear();
          let month = time.getMonth() + 1;
          let date = time.getDate();
          let hours = time.getHours();
          let minute = time.getMinutes();
          let second = time.getSeconds();

          if (month < 10) {
            month = "0" + month;
          }
          if (date < 10) {
            date = "0" + date;
          }
          if (hours < 10) {
            hours = "0" + hours;
          }
          if (minute < 10) {
            minute = "0" + minute;
          }
          if (second < 10) {
            second = "0" + second;
          }
          return (
            year +
            "-" +
            month +
            "-" +
            date +
            " " +
            hours +
            ":" +
            minute +
            ":" +
            second
          );
        },
      },
      {
        label: "变动类型",
        prop: "variationType",
        checked: true,
        width: "150",
        formatter(value) {
          var a = "";
          if (value.variationType == "1") {
            a = "消费";
          } else if (value.variationType == "2") {
            a = "充值";
          } else if (value.variationType == "3") {
            a = "退单";
          } else if (value.variationType == "4") {
            a = "余额转移";
          } else if (value.variationType == "5") {
            a = "退款";
          }
          return a;
        },
      },
      // {
      //   label: "充值方式",
      //   prop: "rechargeMethod",
      //   checked: true,
      //   width: "150",
      // },
      {
        label: "变动金额(元)",
        prop: "variableMoney",
        checked: true,
        width: "150",
      },
      {
        label: "变动后金额(元)",
        prop: "totalMoney",
        checked: true,
        width: "150",
      },
      // {
      //   label: "变动时间",
      //   prop: "createTime",
      //   checked: true,
      //   width: "150",
      //   formatter(value) {
      //     let time = new Date(value.createTime)
      //     let year = time.getFullYear()
      //     let month = time.getMonth() + 1
      //     let date = time.getDate()
      //     let hours = time.getHours()
      //     let minute = time.getMinutes()
      //     let second = time.getSeconds()

      //     if (month < 10) { month = '0' + month }
      //     if (date < 10) { date = '0' + date }
      //     if (hours < 10) { hours = '0' + hours }
      //     if (minute < 10) { minute = '0' + minute }
      //     if (second < 10) { second = '0' + second }
      //     return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
      //   },
      // },
      {
        label: "备注",
        prop: "10",
        checked: true,
      },
    ],
    apiurl: "homePage/buffetCapitalGetDataList",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
    isClickSelect: false,
  },
  second: {
    columnEdit: false,
    column: [
      {
        label: "站点名称",
        prop: "1",
        checked: true,
      },
      {
        label: "菜品内容",
        prop: "2",
        checked: true,
      },
      {
        label: "金额(元)",
        prop: "phone",
        checked: true,
      },
      {
        label: "支付方式",
        prop: "4",
        checked: true,
      },
      {
        label: "创建人",
        prop: "5",
        checked: true,
      },
      {
        label: "下单时间",
        prop: "6",
        checked: true,
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
    isClickSelect: false,
  },
};
// 订单管理的表格数据
export const orderFormTablesData = {
  first: {
    staticData: [],
    columnEdit: false,
    column: [
      {
        label: "订单编号",
        prop: "id",
        checked: true,
        width: "150",
      },
      {
        label: "站点名称",
        prop: "siteName",
        checked: true,
        width: "150",
      },
      {
        label: "老人姓名",
        prop: "name",
        checked: true,
        width: "150",
      },
      {
        label: "老人身份证号",
        prop: "idCard",
        checked: true,
        width: "150",
      },
      {
        label: "老人电话",
        prop: "phone",
        checked: true,
        width: "150",
      },
      {
        label: "年龄",
        prop: "age",
        checked: true,
        width: "150",
      },
      {
        label: "会员类型",
        prop: "g",
        checked: true,
        width: "150",
      },
      {
        label: "补贴类型",
        prop: "h",
        checked: true,
        width: "150",
      },
      {
        label: "套餐名称",
        prop: "i",
        checked: true,
        width: "150",
      },
      {
        label: "套餐金额（元）",
        prop: "j",
        checked: true,
        width: "150",
      },
      {
        label: "政府补贴金额（元）",
        prop: "k",
        checked: true,
        width: "150",
      },
      {
        label: "市补贴金额（元）",
        prop: "l",
        checked: true,
        width: "150",
      },
      {
        label: "区补贴金额（元）",
        prop: "m",
        checked: true,
        width: "150",
      },
      {
        label: "非政府补贴金额（元）",
        prop: "n",
        checked: true,
        width: "150",
      },
      {
        label: "配送补贴（元）",
        prop: "o",
        checked: true,
        width: "150",
      },
      {
        label: "实际支付金额（元）",
        prop: "p",
        checked: true,
        width: "150",
      },
      {
        label: "就餐日期",
        prop: "pickupTime",
        checked: true,
        width: "150",
      },
      {
        label: "取餐日期",
        prop: "createTime",
        checked: true,
        width: "150",
        formatter: (row, column, cellValue) => timeFormatter(cellValue)
      },
      {
        label: "下单时间",
        prop: "s",
        checked: true,
        width: "150",
      },
    ],
    apiurl: "api/getMealRecordList",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
    isClickSelect: false,
  },
  second: {
    columnEdit: true,
    column: [
      {
        label: "站点名称",
        prop: "1",
        checked: true,
      },
      {
        label: "菜品内容",
        prop: "2",
        checked: true,
      },
      {
        label: "金额(元)",
        prop: "phone",
        checked: true,
      },
      {
        label: "支付方式",
        prop: "4",
        checked: true,
      },
      {
        label: "创建人",
        prop: "5",
        checked: true,
      },
      {
        label: "下单时间",
        prop: "6",
        checked: true,
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
    isClickSelect: false,
  },
};
// 异常订单的表格数据
export const abnormalOrdersTablesData = {
  first: {
    columnEdit: true,
    column: [
      {
        label: "老人姓名",
        prop: "1",
        checked: true,
        width: "150",
      },
      {
        label: "身份证号",
        prop: "2",
        checked: true,
        width: "150",
      },
      {
        label: "补贴类型",
        prop: "3",
        checked: true,
        width: "150",
      },
      {
        label: "就餐日期",
        prop: "4",
        checked: true,
        width: "150",
      },
      {
        label: "监管审查",
        prop: "5",
        checked: true,
        width: "150",
      },
      {
        label: "身份认证方式",
        prop: "6",
        checked: true,
        width: "150",
      },
      {
        label: "菜品内容",
        prop: "7",
        checked: true,
        width: "150",
      },
      {
        label: "原价(元)",
        prop: "8",
        checked: true,
        width: "150",
      },
      {
        label: "补贴(元)",
        prop: "9",
        checked: true,
        width: "150",
      },
      {
        label: "市补贴(元)",
        prop: "10",
        checked: true,
        width: "150",
      },
      {
        label: "区补贴(元)",
        prop: "11",
        checked: true,
        width: "150",
      },
      {
        label: "非政府补贴(元)",
        prop: "12",
        checked: true,
        width: "150",
      },
      {
        label: "实付(元)",
        prop: "13",
        checked: true,
        width: "150",
      },
      {
        label: "支付方式/状态",
        prop: "14",
        checked: true,
        width: "150",
      },
      {
        label: "菜品类型",
        prop: "15",
        checked: true,
        width: "150",
      },
      {
        label: "下单时间",
        prop: "16",
        checked: true,
        width: "150",
      },
      {
        label: "助餐站点",
        prop: "17",
        checked: true,
        width: "150",
      },
      {
        label: "订单来源",
        prop: "18",
        checked: true,
        width: "150",
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
    isClickSelect: false,
  },
  second: {
    columnEdit: true,
    column: [
      {
        label: "老人姓名",
        prop: "1",
        checked: true,
        width: "150",
      },
      {
        label: "身份证号",
        prop: "2",
        checked: true,
        width: "150",
      },
      {
        label: "就餐日期",
        prop: "3",
        checked: true,
        width: "150",
      },
      {
        label: "入住养老机构",
        prop: "4",
        checked: true,
        width: "150",
      },
      {
        label: "入住日期",
        prop: "5",
        checked: true,
        width: "150",
      },
      {
        label: "监管审查",
        prop: "6",
        checked: true,
        width: "150",
      },
      {
        label: "身份认证方式",
        prop: "7",
        checked: true,
        width: "150",
      },
      {
        label: "菜品内容",
        prop: "8",
        checked: true,
        width: "150",
      },
      {
        label: "原价(元)",
        prop: "9",
        checked: true,
        width: "150",
      },
      {
        label: "补贴(元)",
        prop: "10",
        checked: true,
        width: "150",
      },
      {
        label: "市补贴(元)",
        prop: "11",
        checked: true,
        width: "150",
      },
      {
        label: "区补贴(元)",
        prop: "12",
        checked: true,
        width: "150",
      },
      {
        label: "非政府补贴(元)",
        prop: "13",
        checked: true,
        width: "150",
      },
      {
        label: "实付(元)",
        prop: "14",
        checked: true,
        width: "150",
      },
      {
        label: "支付方式/状态",
        prop: "15",
        checked: true,
        width: "150",
      },
      {
        label: "下单时间",
        prop: "16",
        checked: true,
        width: "150",
      },
      {
        label: "助餐站点",
        prop: "17",
        checked: true,
        width: "150",
      },
      {
        label: "订单来源",
        prop: "18",
        checked: true,
        width: "150",
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
    isClickSelect: false,
  },
  third: {
    columnEdit: true,
    column: [
      {
        label: "老人姓名",
        prop: "1",
        checked: true,
        width: "150",
      },
      {
        label: "身份证号",
        prop: "2",
        checked: true,
        width: "150",
      },
      {
        label: "就餐日期",
        prop: "3",
        checked: true,
        width: "150",
      },
      {
        label: "去世日期",
        prop: "4",
        checked: true,
        width: "150",
      },
      {
        label: "监管审查",
        prop: "5",
        checked: true,
        width: "150",
      },
      {
        label: "身份认证方式",
        prop: "6",
        checked: true,
        width: "150",
      },
      {
        label: "菜品内容",
        prop: "7",
        checked: true,
        width: "150",
      },
      {
        label: "原价(元)",
        prop: "8",
        checked: true,
        width: "150",
      },
      {
        label: "补贴(元)",
        prop: "9",
        checked: true,
        width: "150",
      },
      {
        label: "市补贴(元)",
        prop: "10",
        checked: true,
        width: "150",
      },
      {
        label: "区补贴(元)",
        prop: "11",
        checked: true,
        width: "150",
      },
      {
        label: "非政府补贴(元)",
        prop: "12",
        checked: true,
        width: "150",
      },
      {
        label: "实付(元)",
        prop: "13",
        checked: true,
        width: "150",
      },
      {
        label: "支付方式/状态",
        prop: "14",
        checked: true,
        width: "150",
      },
      {
        label: "下单时间",
        prop: "15",
        checked: true,
        width: "150",
      },
      {
        label: "助餐站点",
        prop: "16",
        checked: true,
        width: "150",
      },
      {
        label: "订单来源",
        prop: "17",
        checked: true,
        width: "150",
      },
    ],
    apiurl: "",
    refreshFlag: "",
    selectedRows: [],
    isChecked: false,
    isNumber: true,
    isClickSelect: false,
  },
};
const timeFormatter = (cellValue) => {
  return cellValue ? timestampTostr(cellValue) : "";
};
const timestampTostr = function (timestamp, format = "yyyy-mm-dd hh:mm:ss") {
  var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear()
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
  var D = date.getDate() < 10 ? "0" + date.getDate() + ' ' : date.getDate() + ' ';
  var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  const dateJson = {
    "yyyy-mm-dd hh:mm:ss": Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s,
    "yyyy-mm-dd": Y + '-' + M + '-' + D,
    "yyyy/mm/dd": Y + '/' + M + '/' + D,
    "yyyymmdd": Y + M + D,
    "yyyy年mm月dd日": Y + '年' + M + '月' + D + '日',
    "mm月dd日": M + '月' + D + '日',
    "hh时": h + "时",
    "yyyy-mm-dd hh": Y + '-' + M + '-' + D + ' ' + h,
  }
  return dateJson[format]
}
