<template>
    <div class="hl-full-container flexVertical">
        <!-- 查询 -->
        <div class="search">
            <el-form :model="query" ref="query" label-width="120px" inline>
                <el-form-item label="服务批次">
                    <el-select v-model="query.batchId" placeholder="请选择">
                        <el-option key="1" label="按价值" value="1"> </el-option>
                        <el-option key="2" label="按时长" value="2"></el-option>
                    </el-select>

                </el-form-item>
                <el-form-item label="服务对象姓名">
                    <el-input v-model="query.oldName" placeholder="请输入老人姓名"></el-input>
                </el-form-item>
                <el-form-item label="服务人员姓名">
                    <el-input v-model="query.fullName" placeholder="请输入所属机构"></el-input>
                </el-form-item>

                <template v-if="searchIsShow">
                    <el-form-item label="身份证号">
                        <el-input v-model="query.idCard" placeholder="请输入身份证号"></el-input>
                    </el-form-item>
                    <el-form-item label=" 位置监测状态">
                        <el-select v-model="query.sex" placeholder="请选择">
                            <el-option key="1" label="手机定位失败" value="1"> </el-option>
                            <el-option key="2" label="距离超出范围" value="2"></el-option>
                        </el-select>

                    </el-form-item>
                    <el-form-item label=" 核查状态">
                        <el-select v-model="query.status" placeholder="请选择">
                            <el-option key="2" label="待审核" value="2"> </el-option>
                            <el-option key="1" label="确认为有效服务" value="1"></el-option>
                            <el-option key="3" label="确认为无效服务" value="3"></el-option>
                        </el-select>

                    </el-form-item>
                </template>
                <el-form-item>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
                    <el-button size="small" icon="el-icon-refresh-left" @click="reset('Form')">重置</el-button>
                    <el-button size="small" type="info"
                        :icon='searchIsShow == false ? "el-icon-arrow-down" : "el-icon-arrow-up"'
                        @click="more">更多</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 中间 -->
        <div class="toolbar">
            <el-button type="primary" plain size="mini" icon="el-icon-download" @click="derive">导出</el-button>
        </div>
        <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
            :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
            :isNumber="table.isNumber" :formSearch="query">
            <div slot-scope="selected" slot="EditColumn">
                <el-button icon="el-icon-view" type="text" size="small" @click="search(selected.singlerow)">待审核</el-button>
            </div>
        </hltableZ>
        <!-- 审批 -->
        <Drawer :title="title" ref="drawerIshow" :submitFormName="submitFormName" :buttonIsShow="true"
            @submitForm="submitForm" @resetForm="resetForm">
            <el-form :model="ruleForm" ref="ruleForm" label-width="130px" class="demo-ruleForm">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="服务对象参考头像" prop="name">
                            <el-image style="width: 100px; height: 100px"></el-image>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="服务对象参考头像" prop="name">
                            <el-image style="width: 100px; height: 100px"></el-image>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="审计说明" prop="name">
                            <el-input placeholder="请输入审计说明" v-model="query.cause"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="签入现场证明照片" prop="name">
                            <el-image style="width: 100px; height: 100px;margin-left:0.5rem;"
                                v-for="(item, index) in imageList" :key="index" :src="item.filePath"></el-image>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label=" 核查状态">
                            <el-select v-model="query.status" placeholder="请选择">
                                <el-option key="2" label="待审核" value="2"> </el-option>
                                <el-option key="1" label="确认为有效服务" value="1"></el-option>
                                <el-option key="3" label="确认为无效服务" value="3"></el-option>
                            </el-select>

                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="意见或说明" prop="name">
                            <el-input v-model="query.audit" placeholder="" type="textarea" :rows="10"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </Drawer>
    </div>
</template>
  
<script>

import { hltableZ, Drawer } from "@/components";
export default {
    name: "lrxx",
    components: { hltableZ, Drawer },
    data() {
        return {
            imageList: [],
            query: { oldName: "", status: "2", batchId: '', id: '' },
            //   表格
            table: {
                columnEdit: true,
                column: [
                    {
                        label: "服务对象",
                        prop: "oldName",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "工单号",
                        prop: "code",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "服务日期",
                        prop: "organName",
                        checked: true,
                        width: '150'
                    },
                    {
                        label: "服务项",
                        prop: "serviceContent",
                        checked: true,
                        width: '150',
                        formatter(value) {
                            let a = []
                            value.serviceContent.forEach(e => {
                                a.push(e.serviceType)
                            })
                            return a.join(',')
                        }
                    },
                    {
                        label: "服务人员",
                        prop: "fullName",
                        checked: true,
                        width: '150'
                    }, {
                        label: "服务组织",
                        prop: "organName",
                        checked: true,
                        width: '150'
                    }, {
                        label: "审计说明",
                        prop: "cause",
                        checked: true,
                        width: '150'
                    }
                ],
                apiurl: "fwjg/orderServiceGetDataList",
                refreshFlag: "",
                selectedRows: [],
                isChecked: false,
                isNumber: true
            },
            options: [{
                value: 'zhinan',
                label: '闻韶街道',
                children: [{
                    value: 'yizhi',
                    label: '南王镇'
                }, {
                    value: 'fankui',
                    label: '敬仲镇'
                }, {
                    value: 'xiaolv',
                    label: '梧台镇'
                }, {
                    value: 'kekong',
                    label: '朱台镇'
                }]
            },],
            rulesAdd: [],
            token: { token: localStorage.getItem("systoken") },
            title: '数据处理',
            activeItem: {
            },
            searchIsShow: false,
            ruleForm: {

            },
            submitFormName: "确定"
        };
    },

    mounted() {
        this.dataRefresh()
    },

    methods: {
        timeFormatter(row, column, cellValue) {
            return cellValue ? this.$timestampTostr(cellValue) : "";
        },
        //   查询
        dataRefresh() {
            this.table.refreshFlag = (new Date()).getTime().toString()
        },
        reset() {
            const th = this
            Object.keys(th.query).forEach(function (key) {
                if (key == 'status') {
                    th.query.status = '2'
                } else {
                    th.query[key] = ''
                }

            })
            this.dataRefresh();
        },
        async derive() {
            const token = await this.$store.dispatch("user/getToken");
            this.$axios({
                method: "post",
                url: "/api/orderService/doDownLoad",
                data: this.query,
                headers: {
                    token: token,
                    platform: "web",
                },
                responseType: "blob",
            })
                .then((response) => {
                    if (response.headers["content-disposition"]) {
                        const content = response.data;
                        const blob = new Blob([content]); //构造一个blob对象来处理数据

                        // fileName必用这种方式进行解析，否则乱码
                        // Server.UrlEncode(filename);

                        let contentDisposition = response.headers["content-disposition"];
                        // fileName必用这种方式进行解析，否则乱码
                        let fileName = window.decodeURI(
                            contentDisposition.substring(contentDisposition.indexOf("=") + 1)
                        );
                        //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
                        if ("download" in document.createElement("a")) {
                            const link = document.createElement("a"); //创建a标签
                            link.download = fileName; //a标签添加属性
                            link.style.display = "none";
                            link.href = URL.createObjectURL(blob);
                            document.body.appendChild(link);
                            link.click(); //执行下载
                            URL.revokeObjectURL(link.href); //释放url
                            document.body.removeChild(link); //释放标签
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        search(e) {
            this.imageList = e.serviceContent[0].fileStoreEntity
            console.log(88888, e.serviceContent[0])
            this.query.id = e.id
            this.$refs.drawerIshow.drawer = true
        },
        setActiveItem(item) {
            this.activeItem = {
                name: item.name,
                key: item.key,
                component: item.component,
            };
        },
        more() {
            this.searchIsShow = !this.searchIsShow
        },
        submitForm() {
            this.$store.dispatch("fwjg/orderServicesaveData", this.query).then((res) => {
                this.$refs.drawerIshow.drawer = false
                const th = this
                this.dataRefresh()
                Object.keys(th.query).forEach(function (key) {
                    if (key == 'status') {
                        th.query.status = '2'
                    } else {
                        th.query[key] = ''
                    }

                })
                this.$emit('submitFormreach')
            })
        },
        resetForm() {
            const th = this
            this.dataRefresh()
            Object.keys(th.query).forEach(function (key) {
                if (key == 'status') {
                    th.query.status = '2'
                } else {
                    th.query[key] = ''
                }

            })
        }
    },
};
</script>
  
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

::v-deep .el-table th,
::v-deep .el-table td {
    text-align: center !important;
}

.data {
    display: flex;
}

.component {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}
</style>
  