<template>
  <div class="hl-full-container flexVertical" style="width: calc(100% - 2rem)">
    <div class="search">
      <el-form :model="query" ref="query" label-width="80px" inline>
        <el-form-item label="老人姓名">
          <el-input
            v-model="query.oldName"
            placeholder="请输入老人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属机构">
          <el-input
            v-model="query.organ"
            placeholder="请输入所属机构"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="query.sex" placeholder="请选择">
            <el-option key="1" label="男" value="1"> </el-option>
            <el-option key="2" label="女" value="2"></el-option>
          </el-select>
        </el-form-item>
        <template v-if="searchIsShow">
          <el-form-item label="身份证号">
            <el-input
              v-model="query.idCard"
              placeholder="请输入身份证号"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="query.phone" placeholder="请输入电话"></el-input>
          </el-form-item>
          <el-form-item label="能力等级">
            <el-select v-model="query.level" placeholder="请选择">
              <el-option key="能力完好" label="能力完好" value="能力完好">
              </el-option>
              <el-option
                key="轻度失能"
                label="轻度失能"
                value="轻度失能"
              ></el-option>
              <el-option
                key="中度失能"
                label="中度失能"
                value="中度失能"
              ></el-option>
              <el-option
                key="重度失能"
                label="重度失能"
                value="重度失能"
              ></el-option>
              <el-option
                key="完全失能"
                label="完全失能"
                value="完全失能"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区域">
            <el-cascader
              v-model="query.areaName"
              :options="options"
              @change="handleBaseValue"
              :props="optionProps"
            ></el-cascader>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <el-button
            size="small"
            icon="el-icon-refresh-left"
            @click="reset('Form')"
            >重置</el-button
          >
          <el-button
            size="small"
            type="info"
            :icon="
              searchIsShow == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
            "
            @click="more"
            >更多</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 中间 -->
    <div class="toolbar">
      <el-button type="text" plain size="mini" icon="el-icon-upload2">
        <a href="/static/jjlr.xlsx" download="老人信息管理模板.xlsx"
          >模板下载</a
        ></el-button
      >
      <el-upload
        :show-file-list="false"
        :headers="token"
        :file-list="xlsAreafileList"
        :action="xlsAreaAction"
        :on-success="onXlsUploadSuccess"
        accept=".xlsx, .xls"
        style="margin: 0 1rem"
      >
        <el-button type="success" plain size="mini" icon="el-icon-upload"
          >导入</el-button
        >
      </el-upload>

      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-download"
        @click="derive"
        >导出</el-button
      >

      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="add"
        >新增</el-button
      >
    </div>
    <hltableZ
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :isNumber="table.isNumber"
      :formSearch="query"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          icon="el-icon-view"
          type="text"
          size="small"
          @click="search(selected.singlerow)"
          >查看</el-button
        >
        <el-dropdown>
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>生存异常</el-dropdown-item>
            <el-dropdown-item>迁出</el-dropdown-item>
            <el-dropdown-item>删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </hltableZ>
    <!-- 弹窗 -->
    <Drawer
      :title="title"
      ref="drawerIshow"
      :drawerSize="drawerSize"
      :buttonIsShow="true"
      :submitFormName="submitFormName"
      @submitForm="submitForm"
    >
      <el-tabs
        type="border-card"
        v-model="activeItem.name"
        @tab-click="tabClick"
      >
        <el-tab-pane
          v-for="item in systemConfig"
          :key="item.key"
          :label="item.name"
          :name="item.name"
        >
          <div class="component">
            <component
              :is="activeItem.component"
              :dataProps="selectData"
              ref="componentRef"
            ></component>
          </div>
        </el-tab-pane>
      </el-tabs>
    </Drawer>
    <!-- 新增 -->
    <Drawer :title="title2" ref="drawerIshow2" :drawerSize="drawerSize">
      <div class="information">
        <div class="jbxx">
          <div class="title">
            <span style="">基本信息</span>
            <div>
              <i class="el-icon-printer" style="font-size.5rem;color"></i>
              打印
            </div>
          </div>
          <div class="content">
            <el-form
              :label-position="labelPosition"
              label-width="170px"
              :model="formLabelAlign"
              :inline="true"
              :rules="rules"
              ref="formLabelAlign"
              :hide-required-asterisk="true"
              style="display: flex; flex-wrap: wrap"
            >
              <el-form-item label="老人头像:" style="width: 49%">
                <el-upload
                  class="avatar-uploader"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item style="width: 49%">
                <el-form-item
                  label="老人姓名:"
                  prop="name"
                  style="width: 100%; margin-top: 1rem"
                >
                  <el-input v-model="formLabelAlign.name"></el-input>
                </el-form-item>
                <el-form-item
                  label="证件类型:"
                  prop="idLei"
                  style="width: 100%; margin-top: 1rem"
                >
                  <el-select
                    v-model="formLabelAlign.idLei"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="身份证号:"
                  prop="idCard"
                  style="width: 100%; margin-top: 1rem"
                >
                  <el-input v-model="formLabelAlign.type"></el-input>
                </el-form-item>
              </el-form-item>
              <el-form-item label="老人分类:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="出生日期:" style="width: 49%">
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="formLabelAlign.date1"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="性别:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="年龄:" prop="name" style="width: 49%">
                <el-input
                  v-model="formLabelAlign.name"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="民族:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option key="汉" label="汉" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="联系电话:" prop="name" style="width: 49%">
                <el-input v-model="formLabelAlign.name"></el-input>
              </el-form-item>
              <el-form-item label="行政区划:" prop="name" style="width: 49%">
                <el-input
                  v-model="formLabelAlign.name"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="通过社区（村）快速选择:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="居住地址:" prop="name" style="width: 49%">
                <el-input v-model="formLabelAlign.name"></el-input>
              </el-form-item>
              <el-form-item label="生存状态:" prop="name" style="width: 49%">
                <el-input
                  v-model="formLabelAlign.name"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item style="width: 49%">
                <el-form-item label="是否常住人口:">
                  <el-select
                    v-model="formLabelAlign.idLei"
                    placeholder="请选择"
                    style="width: 9.7rem"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="本市户籍:">
                  <el-select
                    v-model="formLabelAlign.idLei"
                    placeholder="请选择"
                    style="width: 9rem"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item label="户籍地址:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="户口性质:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="文化程度:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="兴趣爱好:" prop="name" style="width: 100%">
                <div class="hobby">
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="复选框 A"></el-checkbox>
                    <el-checkbox label="复选框 B"></el-checkbox>
                    <el-checkbox label="复选框 C"></el-checkbox>
                    <el-checkbox label="复选框 A"></el-checkbox>
                    <el-checkbox label="复选框 B"></el-checkbox>
                    <el-checkbox label="复选框 C"></el-checkbox>
                    <el-checkbox label="复选框 A"></el-checkbox>
                    <el-checkbox label="复选框 B"></el-checkbox>
                    <el-checkbox label="复选框 C"></el-checkbox>
                    <el-checkbox label="复选框 A"></el-checkbox>
                    <el-checkbox label="复选框 B"></el-checkbox>
                    <el-checkbox label="复选框 C"></el-checkbox>
                    <el-checkbox label="复选框 A"></el-checkbox>
                    <el-checkbox label="复选框 B"></el-checkbox>
                    <el-checkbox label="复选框 C"></el-checkbox>
                    <el-checkbox label="复选框 A"></el-checkbox>
                    <el-checkbox label="复选框 B"></el-checkbox>
                    <el-checkbox label="复选框 C"></el-checkbox>
                  </el-checkbox-group>
                </div>
                <div>其他：</div>
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="formLabelAlign.textarea"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                label="助老服务需求:"
                prop="name"
                style="width: 100%"
              >
                <div class="hobby">
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox label="复选框 A"></el-checkbox>
                    <el-checkbox label="复选框 B"></el-checkbox>
                    <el-checkbox label="复选框 C"></el-checkbox>
                    <el-checkbox label="复选框 A"></el-checkbox>
                    <el-checkbox label="复选框 B"></el-checkbox>
                    <el-checkbox label="复选框 C"></el-checkbox>
                    <el-checkbox label="复选框 A"></el-checkbox>
                    <el-checkbox label="复选框 B"></el-checkbox>
                    <el-checkbox label="复选框 C"></el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
              <el-form-item label="备注:" prop="name" style="width: 100%">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="formLabelAlign.textarea"
                  style="width: 80rem"
                >
                </el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="jjlxfs">
          <div class="title">
            <span style="">紧急联系方式</span>
          </div>
          <div class="content">
            <el-form
              :label-position="labelPosition"
              label-width="170px"
              :model="formLabelAlign"
              :inline="true"
              :rules="rules"
              ref="formLabelAlign"
              :hide-required-asterisk="true"
              style="display: flex; flex-wrap: wrap"
            >
              <el-form-item label="紧急联系人1:" prop="name" style="width: 30%">
                <el-input
                  v-model="formLabelAlign.name"
                  style="width: 15rem"
                ></el-input>
              </el-form-item>
              <el-form-item label="电话号码:" prop="name" style="width: 30%">
                <el-input
                  v-model="formLabelAlign.name"
                  style="width: 15rem"
                ></el-input>
              </el-form-item>
              <el-form-item label="与老人关系:" prop="name" style="width: 30%">
                <el-input
                  v-model="formLabelAlign.name"
                  style="width: 15rem"
                ></el-input>
              </el-form-item>
              <el-form-item label="紧急联系人2:" prop="name" style="width: 30%">
                <el-input
                  v-model="formLabelAlign.name"
                  style="width: 15rem"
                ></el-input>
              </el-form-item>
              <el-form-item label="电话号码:" prop="name" style="width: 30%">
                <el-input
                  v-model="formLabelAlign.name"
                  style="width: 15rem"
                ></el-input>
              </el-form-item>
              <el-form-item label="与老人关系:" prop="name" style="width: 30%">
                <el-input
                  v-model="formLabelAlign.name"
                  style="width: 15rem"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="qtxx">
          <div class="title">
            <span style="">其他信息</span>
          </div>
          <div class="content">
            <el-form
              :label-position="labelPosition"
              label-width="170px"
              :model="formLabelAlign"
              :inline="true"
              :rules="rules"
              ref="formLabelAlign"
              :hide-required-asterisk="true"
              style="display: flex; flex-wrap: wrap"
            >
              <el-form-item label="经济来源:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="月度可支配收入(元):"
                prop="name"
                style="width: 49%"
              >
                <el-input v-model="formLabelAlign.name"></el-input>
              </el-form-item>
              <el-form-item label="婚姻状态:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="宗教信仰:" prop="name" style="width: 49%">
                <el-input v-model="formLabelAlign.name"></el-input>
              </el-form-item>
              <el-form-item label="居住情况:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="空巢老人:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="留守老人:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="供养形式:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="救助类型:" prop="name" style="width: 49%">
                <el-input v-model="formLabelAlign.name"></el-input>
              </el-form-item>
              <el-form-item label="" prop="name" style="width: 49%">
              </el-form-item>
              <el-form-item label="自理情况:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="失智情况:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="精神疾病:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="精神病描述:" prop="name" style="width: 49%">
                <el-input v-model="formLabelAlign.name"></el-input>
              </el-form-item>
              <el-form-item label="残疾类型:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="残疾证:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="残疾证号:" prop="name" style="width: 49%">
                <el-input v-model="formLabelAlign.name"></el-input>
              </el-form-item>
              <el-form-item label="社保概况:" prop="name" style="width: 49%">
                <el-input v-model="formLabelAlign.name"></el-input>
              </el-form-item>
              <el-form-item label="养老保险:" prop="name" style="width: 49%">
                <el-input v-model="formLabelAlign.name"></el-input>
              </el-form-item>
              <el-form-item label="医疗费用支付方式:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否退休:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="退休前工种:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="劳模等级:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="享受长护险:" style="width: 49%">
                <el-select v-model="formLabelAlign.idLei" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="采集方式:" prop="name" style="width: 30%">
                <el-input
                  v-model="formLabelAlign.name"
                  style="width: 15rem"
                ></el-input>
              </el-form-item>
              <el-form-item label="建档日期:" prop="name" style="width: 30%">
                <el-date-picker
                  v-model="formLabelAlign.name"
                  type="date"
                  placeholder="选择日期"
                  style="width: 15rem"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="更新日期:" prop="name" style="width: 30%">
                <el-input
                  v-model="formLabelAlign.name"
                  style="width: 15rem"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { hltableZ, Drawer } from "@/components";
export default {
  name: "lrxx",
  components: { hltableZ, Drawer },
  data() {
    return {
      drawerSize: "90%",
      query: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
        type: "",
      },
      submitFormName: "保存",
      //   表格
      table: {
        columnEdit: true,
        column: [
          {
            label: "老人姓名",
            prop: "oldName",
            checked: true,
            width: "150",
          },
          {
            label: "照片",
            prop: "2",
            checked: true,
            width: "150",
          },
          {
            label: "所属机构",
            prop: "organName",
            checked: true,
            width: "150",
          },
          {
            label: "性别",
            prop: "sex",
            checked: true,
            width: "150",
            formatter(value) {
              const sex = value.sex;
              return sex == "1" ? "男" : "女";
            },
          },
          {
            label: "年龄",
            prop: "age",
            checked: true,
            width: "150",
          },
          {
            label: "身份证号",
            prop: "idCard",
            checked: true,
            width: "150",
          },
          {
            label: "联系电话",
            prop: "phone",
            checked: true,
            width: "150",
          },
          {
            label: "老人类别",
            prop: "oldType",
            checked: true,
            width: "150",
          },
          {
            label: "服务类型",
            prop: "serveType",
            checked: true,
            width: "150",
          },
          {
            label: "能力等级",
            prop: "level",
            checked: true,
            width: "150",
          },
          {
            label: "所属区别",
            prop: "areaName",
            checked: true,
            width: "150",
          },
          {
            label: "生存状态",
            prop: "status",
            checked: true,
            width: "150",
          },
          {
            label: "家庭地址",
            prop: "address",
            checked: true,
            width: "150",
          },
          {
            label: "登记时间",
            prop: "createTime",
            checked: true,
            formatter: this.timeFormatter,
            width: "150",
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      options: [],
      optionProps: {
        value: "pcode",
        label: "name",
        children: "children",
      },
      // 新增
      drawerIshow2: false, // 弹框的出现与否
      buttonIshow2: true,
      title2: "新增",
      rulesAdd: [],
      token: { token: localStorage.getItem("systoken") },
      xlsAreafileList: [],
      xlsAreaAction: "/api/oldUser/importUser.json",
      title: "老人档案",
      activeItem: {},
      systemConfig: this.$store.getters.systemSettings.config,
      searchIsShow: false,
      //   新增数据
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        idLei: "",
        idCard: "",
        date1: "",
        textarea: "",
      },
      imageUrl: "",
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        idCard: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
        ],
      },
      checkList: [],
      selectData: {},
      tablNum: "",
    };
  },

  mounted() {
    this.setActiveItem(this.systemConfig[0]);
    this.getDataTree();
  },

  methods: {
    close() {
      this.$refs.drawerIshow2.drawer = false;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    //   查询
    dataRefresh() {
      this.table.refreshFlag = new Date().getTime().toString();
    },
    reset() {
      const th = this;
      Object.keys(th.query).forEach(function (key) {
        if (key == "value") {
          th.query.value = "1";
        } else {
          th.query[key] = "";
        }
      });
      this.dataRefresh();
    },

    // 修改是否让页面显示与隐藏的事件
    updateVisible5(val) {
      this.dialogVisible5 = val;
    },
    // 点击取消的事件
    resetPopupData5() {
      //  这里可重置数据
      this.dialogVisible5 = false;
    },
    // 点击确定的按钮
    async submitPopupData5() {
      this.dialogVisible5 = false;
    },
    // 关闭弹框（头部的X）
    handleClose5() {
      this.dialogVisible5 = false;
    },
    // 导入
    onXlsUploadSuccess(response) {
      if (response.success) {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.dataRefresh();
      } else {
        this.$message({
          type: "error",
          message: "导入失败",
        });
      }
    },
    async derive() {
      const token = await this.$store.dispatch("user/getToken");
      const form = Object.assign({}, this.form);
      if (this.options.shipColumn && this.options.shipColumn === "column2") {
        form.dataMode = 0;
      }
      this.$axios({
        method: "post",
        url: "/api/oldUser/doDownLoad",
        data: this.query,
        headers: {
          token: token,
          platform: "web",
        },
        responseType: "blob",
      })
        .then((response) => {
          if (response.headers["content-disposition"]) {
            const content = response.data;
            const blob = new Blob([content]); //构造一个blob对象来处理数据

            // fileName必用这种方式进行解析，否则乱码
            // Server.UrlEncode(filename);

            let contentDisposition = response.headers["content-disposition"];
            // fileName必用这种方式进行解析，否则乱码
            let fileName = window.decodeURI(
              contentDisposition.substring(contentDisposition.indexOf("=") + 1)
            );
            //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
            if ("download" in document.createElement("a")) {
              const link = document.createElement("a"); //创建a标签
              link.download = fileName; //a标签添加属性
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              document.body.appendChild(link);
              link.click(); //执行下载
              URL.revokeObjectURL(link.href); //释放url
              document.body.removeChild(link); //释放标签
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search(e) {
      this.$store.dispatch("homePage/getDataInfo", { id: e.id }).then((res) => {
        this.$refs.drawerIshow.drawer = true;
        this.selectData = res;
      });
    },
    setActiveItem(item) {
      this.activeItem = {
        name: item.name,
        key: item.key,
        component: item.component,
      };
    },
    tabClick(item) {
      this.tablNum = item.index;
      this.setActiveItem(
        this.systemConfig.filter((v) => v.name === item.name)[0]
      );
    },
    more() {
      this.searchIsShow = !this.searchIsShow;
    },
    // 新增
    add() {
      this.$refs.drawerIshow2.drawer = true;
    },
    submitForm() {
      if (this.tablNum == 1) {
        this.$store
          .dispatch(
            "homePage/saveData",
            this.$refs.componentRef[1].formLabelAlign
          )
          .then((res) => {
            this.$refs.drawerIshow.drawer = false;
            this.setActiveItem(this.systemConfig[0]);
          });
      }
    },
    getDataTree() {
      this.$store
        .dispatch("area/getDataTree", {
          areaCode: this.$store.getters.user.areaCode,
        })
        .then((res) => {
          this.options = res.result;
        });
    },
    handleBaseValue(val) {
      this.query.areaName = val[val.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

::v-deep .el-table th,
::v-deep .el-table td {
  text-align: center !important;
}

.data {
  display: flex;
}

.component {
  height: 100%;
  width: 100%;
  // overflow-y: scroll;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  margin-left: 9px;
  font-size: 12px;
  font-weight: 500;
}

.el-icon-arrow-down {
  font-size: 12px;
  // transform: scale(2);
}

// 新增样式
.avatar-uploader {
  border: 1px solid #ccced1;
}

.avatar-uploader .el-upload {
  border: 1px dashed white;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 1rem;
  color: #8c939d;
  width: 10rem;
  height: 10rem;
  line-height: 10rem;
  text-align: center;
}

.avatar {
  width: 10rem;
  height: 10rem;
  display: block;
}

.el-input {
  width: 30rem;
}

.el-select {
  width: 30rem;
}

.el-checkbox {
  margin-right: 2px;
}

.hobby {
  width: 100%;
  border: 1px solid #d5d5d5;
  padding: 0 5px;
}

.title {
  width: 98%;
  border-bottom: 1px solid #999;
  padding-bottom: 0.5rem;
  margin: 1rem auto;
  font-size: 1rem;
  color: rgb(50, 50, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
