<template>
  <div class="box box-main">
    <div class="box-header">
      <div class="box-headertotle">
        <i class="el-icon-s-grid"></i>服务项目配置
      </div>
      <el-button type="info" size="small" icon="el-icon-setting" @click="fwdl()">服务大类设置</el-button>
    </div>
    <!-- //横线div  -->
    <div class="box-line"></div>
    <!-- 切换页 -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="政府购买服务专用项目" name="first">
        <Governmentpurchase></Governmentpurchase>
      </el-tab-pane>
      <!-- <el-tab-pane label="市场化网站服务项目" name="second">
        <Gotomarket></Gotomarket>
      </el-tab-pane> -->
    </el-tabs>
    <!-- //服务大类设置 -->
    <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize">
      <el-tabs v-model="activeType">
        <!-- <el-tab-pane label="网站服务检索一级大类" name="one">
          <Firstclass></Firstclass>
        </el-tab-pane> -->
        <el-tab-pane label="官方定义统计大类" name="one">
          <Statistica></Statistica>
        </el-tab-pane>
      </el-tabs>
    </Drawer>
  </div>
</template>

<script>
import Governmentpurchase from "./com/Governmentpurchase.vue";
import Gotomarket from "./com/Gotomarket.vue";
//  服务大类设置
import Firstclass from "./com/Firstclass.vue";
import Statistica from "./com/Statistica.vue";
import { Drawer } from "@/components";
export default {
  components: {
    Governmentpurchase,
    Gotomarket,
    Drawer,
    Firstclass,
    Statistica
  },
  name: "YanglaoConfiguration1",

  data() {
    return {

      activeName: "first",
      //  服务大类设置
      activeType: "one",
      title: "服务类型设置",
      drawerSize: "60%",
    };
  },

  mounted() {
  },

  methods: {
    // 服务类型设置按钮方法开关
    fwdl() {
      this.$refs.drawerIshow.drawer = true
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;

  .box-header {
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 47px;

    .el-button--small {
      height: 30px !important;
    }
  }

  .box-line {
    width: 100%;
    height: 1px;
    background: #ccd2d9;
    margin-bottom: 20px;
  }
}
</style>
