<template>
  <Drawer
    title="账号管理"
    ref="drawerIshow"
    :buttonIsShow="true"
    @resetForm="resetForm"
    submitFormName="保存"
  >
    <subTitle information-name="员工账号"></subTitle>
    <!-- 搜索条件 -->
    <el-form class="form" ref="form" :model="query" label-width="40px" inline>
      <el-form-item label="账号" prop="account">
        <el-input v-model="query.account" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="query.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="query.status" placeholder="请选择营业状态">
          <el-option label="正常" value="1"></el-option>
          <el-option label="注销" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="dataRefresh"
          >查询</el-button
        >
        <el-button size="small" icon="el-icon-refresh-left" @click="reset"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <div class="toolbar">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="handleAdd"
        >新增</el-button
      >
    </div>
    <!-- 表格 -->
    <hltableZ
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :isNumber="table.isNumber"
      :widthClick="table.widthClick"
      :isClickSelect="table.isClickSelect"
      :staticData="tableDataSource"
      :formSearch="query"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          icon="el-icon-edit"
          type="text"
          size="small"
          @click="handleEdit(selected.singlerow)"
          >编辑</el-button
        >
        <el-button
          icon="el-icon-setting"
          type="text"
          size="small"
          @click="handleEmpower(selected.singlerow)"
          >授权</el-button
        >
        <el-button
          icon="el-icon-refresh"
          type="text"
          size="small"
          @click="handleResetPassword(selected.singlerow)"
          >重置密码</el-button
        >
        <el-button
          icon="el-icon-delete"
          type="text"
          size="small"
          @click="handleDelete(selected.singlerow)"
          >删除</el-button
        >
      </div>
    </hltableZ>
    <!-- 新增、修改员工信息弹窗 -->
    <accountInfoDrawer ref="accountInfoDrawer" :title="accountInfoDrawerTitle"></accountInfoDrawer>
    <!-- 重置密码 -->
    <accountInfoResetDrawer ref="accountInfoResetDrawer"></accountInfoResetDrawer>
  </Drawer>
</template>

<script>
import { Drawer, hltableZ } from "@/components";
import subTitle from "@/views/ZC/components/common/subTitle.vue";
import accountInfoDrawer from "./AccountInfoDrawer"
import accountInfoResetDrawer from "./AccountInfoResetDrawer"
export default {
  name: "AccountInfo",
  components: { Drawer, hltableZ, subTitle, accountInfoDrawer, accountInfoResetDrawer},
  data() {
    return {
      // 表格属性值
      table: {
        columnEdit: true,
        column: [
          {
            label: "账号",
            prop: "1",
            checked: true,
          },
          {
            label: "用户姓名",
            prop: "2",
            checked: true,
          },
          {
            label: "员工",
            prop: "3",
            checked: true,
          },
          {
            label: "动态口令",
            prop: "4",
            checked: true,
          },
          {
            label: "账号类型",
            prop: "account",
            checked: true,
          },
          {
            label: "创建人",
            prop: "6",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        isClickSelect: false,
        widthClick: '260',
      },
      tableDataSource: [
        {
          1: "l1978",
          2: "李惠芝",
          3: "李惠芝",
          4: "未开启",
          5: "普通账号",
          6: "管理员",
        },
      ],
      query: {
        account: "",
        name: "",
        status: "",
      },
      accountInfoDrawerTitle: ''
    };
  },

  mounted() {},

  methods: {
    // 查询
    dataRefresh() {},
    // 重置
    reset() {
      this.$refs.query.resetFields();
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    // 新增
    handleAdd() {
      this.$refs.accountInfoDrawer.$refs.drawerIshow.drawer = true
      this.accountInfoDrawerTitle = '新增员工'
    },
    // 修改
    handleEdit() {
      this.$refs.accountInfoDrawer.$refs.drawerIshow.drawer = true
      this.accountInfoDrawerTitle = '修改员工'
    },
    // 授权
    handleEmpower() {},
    // 重置密码
    handleResetPassword() {
      this.$refs.accountInfoResetDrawer.$refs.drawerIshow.drawer = true
    },
    // 删除
    handleDelete() {
      this.$confirm('确认要注销吗?此操作不删除员工档案信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {})
    }
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
 margin-bottom: .625rem;
}
</style>
