<template>
  <!-- 渔船网格绑定 -->
  <div class="hl-container">
    <div class="hl-left-container flexVertical">
      <div class="tree-container">
        <div class="tree-container-title">
          <div class="tree-container-left-title" style="font-weight: bold">
            网格管理
          </div>
          <div class="tree-container-right-title">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="dialogVisible"
              >新增
            </el-button>
          </div>
        </div>

        <div class="tree-container-tree">
          <el-tree
            :data="areaTreeData"
            default-expand-all
            node-key="id"
            ref="tree"
            highlight-current
            :props="defaultProps"
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span class="btns">
                <el-button
                  size="mini"
                  type="text"
                  @click.stop="() => listLook(data)"
                >
                  <i class="el-icon-search"></i>
                </el-button>
                <el-button
                  size="mini"
                  type="text"
                  @click.stop="() => listEdit(data)"
                >
                  <i class="el-icon-edit"></i>
                </el-button>
                <el-button
                  size="mini"
                  type="text"
                  @click.stop="() => listDel(node, data)"
                >
                  <i class="el-icon-delete"></i>
                </el-button>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
    </div>

    <!-- 新增、编辑、查看 -->
    <el-dialog
      :title="title"
      :visible.sync="isShow"
      width="30%"
      :showClose="false"
    >
      <el-form
        size="mini"
        ref="add"
        :model="add"
        label-width="80px"
        :rules="rules"
        :disabled="isLook"
      >
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="add.name"
            placeholder="请输入名称"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form
        size="mini"
        ref="add"
        :model="add"
        label-width="80px"
        :rules="rules"
        :disabled="!isAdd || isLook"
      >
        <el-form-item label="code码" prop="code">
          <el-input
            v-model="add.code"
            placeholder="请输入code码"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form
        size="mini"
        ref="add"
        :model="add"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item>
          <div style="margin-left: 50%">
            <el-button size="mini" @click="cancel('add')">取 消</el-button>
            <el-button size="mini" type="primary" @click="confirm('add')"
              >确 定</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "areaManage",
  components: { hltable },
  data() {
    return {
      isShow: false,
      isAdd: true,
      isLook: false,
      title: "",
      form: {
        code: "",
        pcode: "",
      },
      add: {
        name: "",
        code: "",
        pcode: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入code码", trigger: "blur" }],
      },
      // 地区树-s
      areaTreeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      // 地区树-e
      FullLoading: null,
      token: { token: localStorage.getItem("systoken") },
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.getAreaTree();
  },
  destroyed() {},
  methods: {
    handleClose() {},
    getAreaTree() {
      this.$store
        .dispatch("api/getTree", {areaCode: "370300000000"})
        .then((response) => {
          if (response.success) {
            console.log(response);
            this.areaTreeData = response.result;
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.areaTreeData[0]?.id || 1);
              this.handleNodeClick(this.areaTreeData[0]);
            });
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleNodeClick(data) {
      console.log(345, data);
      this.form.code = data.code;
      this.form.pcode = data.pcode;
    },

    // 列表删除
    listDel(e, data) {
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/delTree", { ids: [data.code] })
          .then((response) => {
            if (response.success) {
              this.$message.success("删除成功");
              this.getAreaTree();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 新增
    dialogVisible() {
      this.isShow = true;
      this.isAdd = true;
      this.isLook = false;
      this.title = "新增网格";
      this.label = "新增名称";
      this.add = {
        id: "",
        name: "",
        code: "",
      };
    },
    // 查看
    listLook(data) {
      this.isShow = true;
      this.isLook = true;
      this.add.name = data.name;
      this.add.code = data.code;
      this.title = "查看网格节点";
    },
    // 编辑
    listEdit(data) {
      console.log(data);
      this.add.id = data.id;
      this.add.name = data.name;
      this.add.code = data.code;
      this.add.pcode = data.pcode;
      this.isShow = true;
      this.isAdd = false;
      this.isLook = false;
      this.title = "修改网格节点";
      this.label = "修改名称";
    },
    cancel() {
      this.isShow = false;
      this.add.name = "";
      this.add.code = "";
    },
    confirm(add) {
      if (this.isLook) {
        this.isShow = false;
        return;
      }
      this.$refs[add].validate((valid) => {
        if (valid) {
          let param = {};
          // 新增
          if (this.isAdd) {
            console.log(123, this.add);
            console.log(234, this.form);
            param = {
              name: this.add.name,
              pcode: this.form.code,
              code: this.add.code,
              id: "",
            };
          } else {
            //编辑
            param = {
              name: this.add.name,
              pcode: this.add.pcode,
              code: this.add.code,
              id: this.add.code,
            };
          }
          console.log(param);
          this.$store.dispatch("api/saveTree", param).then((response) => {
            this.isShow = false;
            this.add.name = "";
            this.add.code = "";
            this.getAreaTree();
            if (response.success) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

.hl-left-container {
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  background: #fff;
}

.tree-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .tree-container-title {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tree-container-tree {
    flex: 1 1 auto;
    height: 0;
    overflow-y: auto;
  }

  /deep/.custom-tree-node {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btns {
      display: none;
      margin-right: 20px;

      .el-button {
        margin: 0 10px;
      }
    }
  }

  /deep/.el-tree-node__content:hover .btns {
    display: inline-block;
  }
}

.toolbar {
  .crud {
    display: flex;
  }
}

/deep/
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
</style>
