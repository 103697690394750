<template>
  <Drawer :title="title" ref="drawerIshow" :buttonIsShow="buttonIsShow" :submitFormName="submitFormName"
    @submitForm="submitForm">
    <el-row :gutter="24">
      <el-form class="form" :model="dataForm" :rules="rules" label-width="130px">
        <el-col :span="24">
          <subTitle informationName="基本信息"></subTitle>
        </el-col>
        <el-col :span="12">
          <el-form-item label="对象性质" prop="quality">
            <el-select v-model="dataForm.quality" disabled>
              <el-option label="正式补贴对象" value="1"></el-option>
              <el-option label="准补贴对象" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="户籍所在社区(村)" prop="areaName">
            <el-input v-model="dataForm.areaName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="助餐站点" prop="buffetId">
            <el-select v-model="dataForm.buffetId" placeholder="请选择助餐站点">
              <el-option v-for="item in buffetList" :key="item.id" :label="item.buffetName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="社会保障类型" prop="safeguardType">
            <el-select v-model="dataForm.safeguardType">
              <el-option label="低保" value="1"></el-option>
              <el-option label="特困" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="能力评估等级" prop="level">
            <el-select v-model="dataForm.level">
              <el-option label="低保" value="1"></el-option>
              <el-option label="特困" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="评估日期" prop="createTime">
            <el-date-picker v-model="dataForm.createTime" type="date" placeholder="选择日期" value-format="timestamp">
            </el-date-picker>
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="姓名" prop="oldName">
            <el-input v-model="dataForm.oldName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话号码" prop="phone">
            <el-input v-model="dataForm.phone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="证件类型" prop="idType">
            <el-select v-model="dataForm.idType">
              <el-option label="内地居民身份证" value="1"></el-option>
              <el-option label="香港居民身份证" value="2"></el-option>
              <el-option label="澳门居民身份证" value="3"></el-option>
              <el-option label="台湾居民身份证" value="4"></el-option>
              <el-option label="护照" value="5"></el-option>
              <el-option label="军官证" value="6"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="证件号码" prop="idCard">
            <el-input v-model="dataForm.idCard"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="年龄" prop="age">
            <el-input v-model="dataForm.age"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="人脸照片">
            <img v-show="fileStoreEntity2.length !== 0" v-for="(item, index) in fileStoreEntity2" :key="index" :src="item"
              class="avatar" style="width:9rem;height:9rem" />
            <el-upload class="avatar-uploader" :headers="token" :action="xlsAreaAction" :show-file-list="false"
              :on-success="handlerImgUpload">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="身份证照片">
            <img v-show="idPhotoFile2.length !== 0" v-for="(item, index) in idPhotoFile2" :key="index" :src="item"
              class="avatar" style="width:9rem;height:9rem" />
            <el-upload class="avatar-uploader" :headers="token" :action="xlsAreaAction" :show-file-list="false"
              :on-success="handlerImgUpload2">

              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="户口照片">
            <img v-show="householdFile2.length !== 0" v-for="(item, index) in householdFile2" :key="index" :src="item"
              class="avatar" style="width:9rem;height:9rem" />
            <el-upload class="avatar-uploader" :headers="token" :action="xlsAreaAction" :show-file-list="false"
              :on-success="handlerImgUpload3">

              <i v-show="dataForm.household.length == 0" class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="现住址" prop="address">
            <el-input v-model="dataForm.address"></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="允许助餐点代点餐" prop="replace">
            <el-select v-model="dataForm.replace">
              <el-option label="允许" value="1"></el-option>
              <el-option label="不允许" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="补贴分类" prop="subsidyId">
            <el-select v-model="dataForm.subsidyId">
              <el-option label="1" value="1"></el-option>
              <el-option label="2" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24">
          <el-form-item label="补贴详情" prop="details">
            <el-input v-model="dataForm.details" type="textarea" :autoSize="{ minRows: 2, maxRows: 4 }"></el-input>
          </el-form-item>
        </el-col> -->
      </el-form>
    </el-row>
  </Drawer>
</template>

<script>
import { Drawer } from "@/components";
import subTitle from "../common/subTitle.vue";

export default {
  name: "memberInfo",
  components: { Drawer, subTitle },
  props: {
    title: {
      type: String,
      default: "",
    },
    buttonIsShow: {
      type: Boolean,
      default: false
    },
    submitFormName: {
      type: String,
    },
  },
  data() {
    return {
      dataForm: {
        quality: "",
        buffetId: "",
        areaName: "",
        safeguardType: "",
        level: "",
        createTime: "",
        oldName: "",
        phone: "",
        idType: "",
        idCard: "",
        age: '',
        fileStoreEntity: '',
        idPhoto: '',
        household: '',
        subsidyId: "",
        address: '',
        id: ''
      },
      rules: {},
      xlsAreaAction: "/api/fileStore/upload.json",
      token: { token: localStorage.getItem("systoken") },
      fileStoreEntity2: [],
      idPhotoFile2: [],
      householdFile2: [],
      buffetList: []
    };
  },

  mounted() {
  },

  methods: {
    // 上传
    handlerImgUpload(res, file) {
      this.dataForm.fileStoreEntity += res
      this.fileStoreEntity2.push(file.name)
    },
    handlerImgUpload2(res, file) {
      this.dataForm.idPhoto += res
      this.idPhotoFile2.push(file.name)
    },
    handlerImgUpload3(res, file) {
      this.dataForm.household += res
      this.householdFile2.push(file.name)
    },
    submitForm() {
      let formDatas = new FormData();
      formDatas.append('quality', this.dataForm.quality)
      formDatas.append('buffetId', this.dataForm.buffetId)
      formDatas.append('areaName', this.dataForm.areaName)
      formDatas.append('safeguardType', this.dataForm.safeguardType)
      formDatas.append('level', this.dataForm.level)
      // formDatas.append('createTime', this.dataForm.createTime)
      formDatas.append('oldName', this.dataForm.oldName)
      formDatas.append('phone', this.dataForm.phone)
      formDatas.append('idType', this.dataForm.idType)
      formDatas.append('idCard', this.dataForm.idCard)
      formDatas.append('age', this.dataForm.age)
      formDatas.append('photo', this.dataForm.fileStoreEntity)
      formDatas.append('idPhoto', this.dataForm.idPhoto)
      formDatas.append('household', this.dataForm.household)
      formDatas.append('subsidyId', this.dataForm.subsidyId)
      if (this.dataForm.quality == '1') {
        formDatas.append('type', '5');
      } else if (this.dataForm.quality == '2') {
        formDatas.append('type', '6');
      }

      formDatas.append('id', this.dataForm.id)
      this.$store.dispatch("homePage/oldUser", formDatas).then((res) => {
        if (res.success == true) {
          this.$refs.drawerIshow.drawer = false;
          this.$emit('event-name');
          this.$emit('event-nameZ');

        } else {
          this.$message.error(res.message)
        }

      })
    }
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 98%;

  .el-select {
    width: 100%;
  }

  .domicile ::v-deep .el-form-item__content {
    display: flex;

    .el-select {
      flex: 1;
    }
  }

  // 图片缩略图样式
  .avatar-uploader {
    display: inline-block;
    text-align: center;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    cursor: pointer;
    line-height: 146px;
    vertical-align: top;

    .avatar {
      width: 148px;
      height: 148px;
    }

    i {
      font-size: 28px;
      color: #8c939d;
    }
  }
}
</style>
