<template>
  <div class="Baseconfiguration">
    <div class="box-header">
      <div class="box-headertotle">
        <i class="el-icon-s-grid"></i>服务对象申请规则配置
      </div>
    </div>
    <div class="box-line"></div>
    <div class="box-body">
      <div class="form-unit">服务对象</div>
      <el-form ref="form" :model="form" label-width="200px">
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="服务对象最小年龄:">
              <el-input v-model="form.minAge"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>当新增服务对象时，会对其年龄进行校验。</div>
          </el-col>
        </el-row>
        <div class="form-unit">申请(审批)规则</div>
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="监管部门审批流程:">
              <el-button type="success" @click="splc()"><i class="el-icon-s-tools"></i>审批流程</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div></div>
          </el-col>
        </el-row>
        <div class="form-unit">需要上传的附件及要求</div>
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label=" 附件:">
              <el-input v-model="form.attachment" type="textarea" style="height: 200px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div></div>
          </el-col>
        </el-row>
        <div class="tb">{{ flex }}</div>
        <div class="form-unit">处理者</div>
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="*反常服务数据处理者:">
              <el-select v-model="form.role" placeholder="请选择处理者" multiple>
                <el-option v-for='(item, index) in option' :label="item.roleName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>配置哪些人员有权对反常的服务数据（定位异常）进行处理。</div>
          </el-col>
        </el-row>
      </el-form>
      <div class="sere"><el-button type="success" @click="bc">保存</el-button></div>
    </div>
    <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize">
      <Baseconfiguration></Baseconfiguration>
    </Drawer>
  </div>
</template>

<script>
import Baseconfiguration from "./comthree/Baseconfiguration.vue"
import { Drawer } from "@/components";
export default {
  name: "YanglaoConfiguration3",
  components: {
    Drawer,
    Baseconfiguration
  },
  data() {
    return {
      form: {


      },
      flex: '[{"sort":1,"code":"hkb","name":"户口本","type":"IMG","required":true,"multiple":1}]',
      title: "补贴对象申请流程",
      drawerSize: "60%",
      option: []
    };
  },

  mounted() {
    this.getAllList()
  },
  computed: {
    NEWdata() {
      return this.$store.getters.sidebar.form;

    },
  },
  watch: {
    NEWdata: {
      handler: function (v) {
        this.form = this.NEWdata
        v.role = v.role.split(',')
      },
      immediate: true,
    },
  },
  methods: {
    splc() {
      this.$refs.drawerIshow.drawer = true
    },
    getAllList() {
      this.$store
        .dispatch("jbsz/getAllList")
        .then((res) => {
          this.option = res.result
        })
    },
    bc() {
      this.form.role = this.form.role + ''
      this.$store
        .dispatch("jbsz/livingModulesaveData", this.form)
        .then((res) => {
          this.form.role = this.form.role.split(',')
          this.$message({
            message: '保存成功',
            type: 'success'
          });
        })
    }
  },
};
</script>

<style lang="scss">
.Baseconfiguration {
  height: 100%;

  .box-header {
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 47px;

    .el-button--small {
      height: 30px !important;
    }
  }

  .box-line {
    width: 100%;
    height: 1px;
    background: #ccd2d9;
    margin-bottom: 20px;
  }

  .box-body {
    width: 100%;
    height: 100%;

    .form-unit {
      font-size: 15px;
      color: #6379bb;
      border-bottom: 1px solid #ddd;
      margin: 8px 10px 25px 10px;
      padding-bottom: 5px;
    }

    div {
      color: #aaa;
      font-size: 13px;
      line-height: 30px;
    }

    .el-textarea__inner {
      width: 500px;
      height: 200px;
    }

    .tb {
      width: 600px;
      height: 100px;
      margin-left: 320px;
    }

    .el-select {
      width: 260px;
    }
  }

  .sere {
    display: flex;
    justify-content: center;
  }

  .el-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
</style>
