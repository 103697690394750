<template>
  <div class="box">
    <div class="nav">
      <span v-for="(item, index) in navList" :key="item.name" :class="item.active ? 'active' : ''"
        @click="navClick(index)">
        {{ item.name }}
      </span>
    </div>
    <!-- 巡访 -->
    <div class="xf" v-show="active">
      <div class="search">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="巡访方式">
            <el-select v-model="searchData.xffs" filterable placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="巡访日期">
            <el-date-picker v-model="searchData.startDate" type="date" placeholder="选择日期">
            </el-date-picker>
            -
            <el-date-picker v-model="searchData.endDate" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableBox">
        <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
          :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
          :isNumber="table.isNumber" :formSearch="query">
          <!-- <div slot-scope="selected" slot="EditColumn">
            <el-button
              icon="el-icon-delete"
              type="text"
              size="small"
              @click="delete selected.singlerow"
              >删除</el-button
            >
          </div> -->
        </hltableZ>
      </div>
    </div>
    <!-- 活动 -->
    <div class="hd" v-show="active1">
      <div class="search">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="活动性质">
            <el-select v-model="searchData.hdxz" filterable placeholder="请选择">
              <el-option v-for="item in optionsHD" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动日期">
            <el-date-picker v-model="searchData.startDateHD" type="date" placeholder="选择日期">
            </el-date-picker>
            -
            <el-date-picker v-model="searchData.endDateHD" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableBox">
        <hltableZ :columnEdit="table1.columnEdit" :tableColumn="table1.column" :apiurl="table1.apiurl"
          :refreshFlag="table1.refreshFlag" :selectedRows="table1.selectedRows" :isChecked="table1.isChecked"
          :isNumber="table1.isNumber" :formSearch="query1">
          <!-- <div slot-scope="selected" slot="EditColumn">
            <el-button
              icon="el-icon-delete"
              type="text"
              size="small"
              @click="delete selected.singlerow"
              >删除</el-button
            >
          </div> -->
        </hltableZ>
      </div>
    </div>
    <!-- 助老服务 -->
    <div class="zl" v-show="active2">
      <div class="search">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="服务日期">
            <el-date-picker v-model="searchData.startDateZL" type="date" placeholder="选择日期">
            </el-date-picker>
            -
            <el-date-picker v-model="searchData.endDateZL" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableBox">
        <hltableZ :columnEdit="table2.columnEdit" :tableColumn="table2.column" :apiurl="table2.apiurl"
          :refreshFlag="table2.refreshFlag" :selectedRows="table2.selectedRows" :isChecked="table2.isChecked"
          :isNumber="table2.isNumber" :formSearch="query2">
          <!-- <div slot-scope="selected" slot="EditColumn">
            <el-button
              icon="el-icon-delete"
              type="text"
              size="small"
              @click="delete selected.singlerow"
              >删除</el-button
            >
          </div> -->
        </hltableZ>
      </div>
    </div>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
export default {
  name: "YanglaoSqfw",
  components: { hltableZ },
  data() {
    return {
      navList: [
        { name: "巡访", active: true },
        { name: "活动", active: false },
        { name: "助老服务", active: false },
      ],
      query: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
      table: {
        columnEdit: true,
        column: [
          {
            label: "老人姓名",
            prop: "oldName",
            checked: true,
          },
          {
            label: "老人身份证号",
            prop: "2",
            checked: true,
          },
          {
            label: "巡访日期",
            prop: "organName",
            checked: true,
          },
          {
            label: "巡访员",
            prop: "oldName",
            checked: true,
          },
          {
            label: "巡访方式",
            prop: "2",
            checked: true,
          },
          {
            label: "创建时间",
            prop: "organName",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      query1: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
      table1: {
        columnEdit: true,
        column: [
          {
            label: "活动时间",
            prop: "oldName",
            checked: true,
          },
          {
            label: "县区",
            prop: "2",
            checked: true,
          },
          {
            label: "社区地点",
            prop: "organName",
            checked: true,
          },
          {
            label: "活动主题",
            prop: "oldName",
            checked: true,
          },
          {
            label: "审阅状态",
            prop: "2",
            checked: true,
          },
          {
            label: "活动描述",
            prop: "organName",
            checked: true,
          },
          {
            label: "创建日期",
            prop: "organName",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      query2: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
      table2: {
        columnEdit: true,
        column: [
          {
            label: "服务时间",
            prop: "oldName",
            checked: true,
          },
          {
            label: "助老服务类型",
            prop: "2",
            checked: true,
          },
          {
            label: "服务项目",
            prop: "organName",
            checked: true,
          },
          {
            label: "服务地点",
            prop: "oldName",
            checked: true,
          },
          {
            label: "服务人员",
            prop: "2",
            checked: true,
          },
          {
            label: "收费金额",
            prop: "organName",
            checked: true,
          },
          {
            label: "服务内容",
            prop: "2",
            checked: true,
          },
          {
            label: "服务描述",
            prop: "organName",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      options: [
        {
          value: "选项1",
          label: "电话",
        },
        {
          value: "选项2",
          label: "上门巡访",
        },
      ],
      optionsHD: [
        {
          value: "选项1",
          label: "全部",
        },
        {
          value: "选项2",
          label: "普通活动",
        },
        {
          value: "选项3",
          label: "运营集体策划类活动",
        },
        {
          value: "选项4",
          label: "未组织活动",
        },
      ],
      searchData: {
        xffs: "",
        startDate: "",
        endDate: "",
        hdxz: "",
        startDateHD: "",
        endDateHD: "",
        startDateZL: "",
        endDateZL: "",
      },

      active: true,
      active1: false,
      active2: false,
    };
  },

  mounted() { },

  methods: {
    resetForm() {
      Object.keys(this.searchData).forEach(k => this.searchData[k] = "")
    },
    navClick(e) {
      console.log(e);
      this.active = e == 0 ? true : false;
      this.active1 = e == 1 ? true : false;
      this.active2 = e == 2 ? true : false;
      var array = this.navList;
      for (let i = 0; i < array.length; i++) {
        array[i].active = false;
        if (!array[e].active) {
          array[e].active = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 2rem;
  border-bottom: 1px solid #eee;
  font-size: 1rem;

  span {
    height: 1rem;
    display: inline-block;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(0, 150, 136, 0);
    margin-right: 1rem;
  }

  .active {
    color: #009688;
    border-bottom: 1px solid #009688;
  }
}

.xf,
.hd,
.zl {
  width: 100%;
  height: 100%;

  .search {
    width: 100%;
    margin: 2rem 0;
  }

  .tableBox {
    width: 100%;
    height: 30rem;
  }
}
</style>