<template>
  <div class="">
    Home
  </div>
</template>

<script> 
export default {
  name: 'home',
  components: {},
  data () {
    return {

    }
  },
  watch: {
  },
  created () {

  },
  mounted () {
  },
  destroyed () {
  },
  methods: {

  }
}
</script>

<style lang="scss">
</style>
