<template>
  <!-- 政府购买服务规则设置/ -->
  <div class="HomeGovernment">
    <div class="Governmentgm">
      <p class="fwcl">服务策略</p>
      <span @click="modification()">修改记录</span>
    </div>
    <div class="col-xs-12">
      <div style="padding: 5px; margin-left: 5px; color: red">
        未配置服务策略，请联系管理员！
      </div>
    </div>
    <div class="form-unit">市场化服务规则</div>
    <div class="form-serve">
      <el-form ref="form" :model="form" label-width="200px">
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="签字校验:">
              <el-select v-model="form.region" placeholder="请选择签字校验:">
                <el-option label="开启" value="shanghai"></el-option>
                <el-option label="不开启" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>当服务人员签出时，服务人员和老人的签字均不能为空。</div>
          </el-col>
        </el-row>
        <!-- //用户现场评价校验: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="用户现场评价校验:">
              <el-select v-model="form.region" placeholder="请选择用户现场评价校验:">
                <el-option label="开启" value="shanghai"></el-option>
                <el-option label="不开启" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div></div>
          </el-col>
        </el-row>
        <!-- 从相册选择服务图片: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="从相册选择服务图片:">
              <el-select v-model="form.region" placeholder="请选择服务图片:">
                <el-option label="允许" value="shanghai"></el-option>
                <el-option label="不允许" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>若不允许，则只能实时拍照上传</div>
          </el-col>
        </el-row>
        <!-- 允许单日重复服务: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="允许单日重复服务:">
              <el-select v-model="form.region" placeholder="请选择允许单日重复服务:">
                <el-option label="允许" value="shanghai"></el-option>
                <el-option label="不允许" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>某服务对象的同一服务项在一天内是否允许被服务多次。</div>
          </el-col>
        </el-row>
        <!-- 签入签出传照片: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="签入签出传照片:">
              <el-select v-model="form.region" placeholder="请选择签入签出传照片:">
                <el-option label="允许" value="shanghai"></el-option>
                <el-option label="不允许" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>签入签出时需要上传现场照片。</div>
          </el-col>
        </el-row>
        <!-- 服务定位距离（米）: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="服务定位距离（米）:">
              <div class="julimi">
                <el-input-number v-model="form.region" controls-position="right" :min="1" :max="10"></el-input-number>
                <el-select v-model="form.region" placeholder="请选择允许单日重复服务:">
                  <el-option label="允许" value="shanghai"></el-option>
                  <el-option label="不允许" value="beijing"></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>
              1、非强制：签入签出时进行距离检测，若超出设定的值，或手机定位失败，依然允许签入签出，并判定该工单为“反常数据”，需在“服务位置反常数据检测”中进行人工审查和处理。
            </div>
            <div>2、强制：签入签出时距离校验超出设定值，不允许签入。</div>
            <div>3、若有效签到范围设置为0，则不启用距离检测。</div>
          </el-col>
        </el-row>
        <!-- 服务间隔（分钟）: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="服务间隔（分钟）:">
              <el-input-number v-model="form.num" controls-position="right" :min="1" :max="10"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>0代表不校验</div>
          </el-col>
        </el-row>
        <!-- 允许签入时间范围: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="允许签入时间范围:">
              <el-input v-model="form.input" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>
              服务每天允许签入的时间范围，多个范围逗号分隔，如06:30-12:00,13:00-21:00
            </div>
          </el-col>
        </el-row>
        <div class="form-unit">服务对象要求</div>
        <!--服务对象最小年龄:-->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="服务对象最小年龄:">
              <el-input-number v-model="form.num" controls-position="right" :min="1" :max="10"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>0代表不校验</div>
          </el-col>
        </el-row>
        <div class="form-unit">服务人员要求</div>
        <!-- 服务人员年龄: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="服务人员年龄:">
              <div class="julimi">
                <el-input-number v-model="form.num" controls-position="right" :min="1" :max="10"></el-input-number>
                <span>到</span>
                <el-input-number v-model="form.num" controls-position="right" :min="1" :max="10"></el-input-number>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>当服务人员签入时，会对其年龄进行校验。</div>
          </el-col>
        </el-row>
        <!-- 服务人员核验: -->
        <el-row :gutter="100" type="flex" justify="center">
          <el-col :span="10">
            <el-form-item label="签字校验:">
              <el-select v-model="form.region" placeholder="请选择签字校验:">
                <el-option label="开启" value="shanghai"></el-option>
                <el-option label="不开启" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <div>服务人员必须通过监管部门信息核验无误后，方可开展服务。</div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="but">
      <el-button type="success" @click="seve()">保存</el-button>
    </div>

    <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize">
      <Modifyingrecords></Modifyingrecords>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";
import Modifyingrecords from "./Modifyingrecords"
export default {
  data() {
    return {
      title: "窗口",
      form: {
        region: "shanghai",
        num: "",
        input: "",
      },
      drawerSize: "60%"
    };
  },
  components: { Drawer, Modifyingrecords },
  mounted() { },
  methods: {
    seve() { },
    modification() {
      this.$refs.drawerIshow.drawer = true
    }
  },
};
</script>
<style scoped lang="scss">
.HomeGovernment {
  font-size: 16px;

  .Governmentgm {
    position: relative;

    span {
      text-decoration: underline;
      color: #1fcfe2;
      padding-top: 4px;
      position: absolute;
      top: -5px;
      left: 100px;
    }

    .fwcl {
      font-size: 15px;
      color: #6379bb;
      border-bottom: 1px solid #ddd;
      margin: 8px 10px 25px 10px;
      padding-bottom: 5px;
    }
  }

  .form-unit {
    font-size: 15px !important;
    color: #6379bb !important;
    border-bottom: 1px solid #ddd !important;
    margin: 8px 10px 25px 10px !important;
    padding-bottom: 5px !important;
  }

  .el-select {
    width: 350px;
  }

  .julimi {
    display: flex;
    justify-content: flex-end;
    width: 350px;
  }

  .form-serve {
    div {
      color: #aaa;
      font-size: 13px;
      line-height: 30px;
    }
  }

  .but {
    display: flex;
    justify-content: center;
  }
}
</style>
