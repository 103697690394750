<template>
  <div>
    <Drawer :title="title" ref="drawerIshow">
      <div>
        <div class="infoTitle">
          <div class="gdTitle">
            <div class="colors"></div>
            <div class="gdWord">基本信息</div>
          </div>
        </div>
        <el-descriptions class="margin-top" :column="2" border>
          <el-descriptions-item v-for="item in descriptionsList" :key="item.name">
            <template slot="label">
              {{ item.label }}
            </template>
            {{ item.name }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="infoTitle">
          <div class="gdTitle">
            <div class="colors"></div>
            <div class="gdWord">配送信息</div>
          </div>
        </div>
        <el-descriptions class="margin-top" :column="2" border>
          <el-descriptions-item v-for="item in descriptionsList2" :key="item.name">
            <template slot="label">
              {{ item.label }}
            </template>
            {{ item.name }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { Drawer } from "@/components";

export default {
  name: "OrderFormInfo",
  components: { Drawer },
  props: {
    title: {
      type: String,
      default: "订单详情",
    },
  },

  data() {
    return {
      descriptionsList: [
        {
          label: "助餐站点",
          name: "王德非",
        },
        {
          label: "配餐厨房",
          name: "-",
        },
        {
          label: "姓名",
          name: "110101191903075356",
        },
        {
          label: "身份证",
          name: "13699858955",
        },
        {
          label: "对象分类",
          name: "安徽,六安,金安,中市",
        },
        {
          label: "享受补贴分类",
          name: "安徽,六安,金安,中市",
        },
        {
          label: "就餐日期",
          name: "安徽,六安,金安,中市",
        },
        {
          label: "就餐时段",
          name: "安徽,六安,金安,中市",
        },
        {
          label: "是否支付",
          name: "安徽,六安,金安,中市",
        },
        {
          label: "市场价",
          name: "安徽,六安,金安,中市",
        },
        {
          label: "实际支付价格",
          name: "安徽,六安,金安,中市",
        },
        {
          label: "补贴金额",
          name: "安徽,六安,金安,中市",
        },
        {
          label: "订单创建时间",
          name: "安徽,六安,金安,中市",
        },
      ],
      descriptionsList2: [
        {
          label: "配送方式",
          name: "王德非",
        },
        {
          label: "配送地址",
          name: "-",
        },
        {
          label: "配送费",
          name: "110101191903075356",
        },
        {
          label: "配送费补贴",
          name: "13699858955",
        },
      ],
    };
  },

  mounted() { },

  methods: {
    resetForm() {

    }
  },
};
</script>

<style lang="scss" scoped>
.infoTitle {
  margin: 0.7rem;

  .gdTitle {
    display: flex;
    align-items: center;

    .colors {
      width: 5px;
      height: 14px;
      background-color: #2362fb;
      margin-right: 0.7rem;
    }

    .gdWord {
      font-weight: 900;
    }
  }
}
</style>
