<template>
  <!-- 网站服务检索一级大类 -->
  <div class="Firstclass">
    <p>
      网站检索类别为两级，其中此类别为门户网站服务检索一级大类，二级为具体的服务项。如下图：
    </p>
    <img src="@/assets/somType.jpg" alt="" /><span><el-button type="primary" size="small" icon="el-icon-plus"
        @click="add()">新增</el-button>
    </span>
    <div class="box-line"></div>
    <div class="list">
      <el-form ref="form" :model="form" label-width="120px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="服务大类名称：">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="getlist()">查询</el-button> </el-col><el-col
            :span="1"><el-button type="primary" size="small" icon="el-icon-plus" @click="reset()">重置</el-button></el-col>
        </el-row>
      </el-form>
      <div class="jtcw1">
        <hltableZ :columnEdit="table1.columnEdit" :tableColumn="table1.column" :apiurl="table1.apiurl"
          :refreshFlag="table1.refreshFlag" :selectedRows="table1.selectedRows" :isChecked="table1.isChecked"
          :isNumber="table1.isNumber" :formSearch="query1">
          <div slot-scope="selected" slot="EditColumn">
            <el-button icon="el-icon-delete" type="primary" size="small" @click="editing()">编辑</el-button>
            <el-button icon="el-icon-delete" type="danger" size="small" @click="delete selected.singlerow">删除</el-button>
          </div>
        </hltableZ>
      </div>
    </div>
    <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize">
      <div class="schadd">
        <p class="schaddp">基本信息</p>
        <el-form ref="formadd" :model="formadd" label-width="120px">
          <el-form-item label="服务类型名称:">
            <el-input v-model="formadd.typename"></el-input>
          </el-form-item>
          <el-form-item label="服务类型描述:">
            <el-input type="textarea" v-model="formadd.typedescription"></el-input>
          </el-form-item>
          <el-form-item label="排序码:">
            <el-input type="textarea" v-model="formadd.sorting"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="schbut">
        <el-button type="primary" @abort="save()">保存</el-button>
        <el-button type="info" @click="back()">返回</el-button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
import { Drawer } from "@/components";
export default {
  components: { hltableZ, Drawer },
  data() {
    return {
      title: "市场化服务类型新增",
      drawerSize: "60%",
      form: {
        name: "",
      },
      table1: {
        columnEdit: true,
        column: [
          {
            label: "类别名称",
            prop: "oldName",
            checked: true,
          },
          {
            label: "服务项目数量",
            prop: "organName",
            checked: true,
          },
          {
            label: "描述",
            prop: "oldName",
            checked: true,
          },
          {
            label: "排序吗",
            prop: "oldName",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      query1: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
      //新增
      formadd: {
        typename: "",
        typedescription: "",
        sorting: "",
      },
    };
  },
  mounted() { },
  methods: {
    // 查询
    getlist() { },
    //重置
    reset() { },
    //新增
    add() {
      this.$refs.drawerIshow.drawer = true;
    },
    //保存
    save() { },
    //返回
    back() { },
    //编辑
    editing() {
      this.$refs.drawerIshow.drawer = true;
    }
  },
};
</script>
<style scoped lang="scss">
.Firstclass {
  margin-top: 10px;

  p {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  img {
    width: 90%;
  }

  span {
    position: absolute;
  }

  .box-line {
    width: 100%;
    height: 1px;
    background: #ccd2d9;
    margin-bottom: 20px;
  }

  .list {
    .jtcw1 {
      width: 100%;
      height: 40rem;
      margin-top: 1rem;
    }
  }
}

.schadd {
  width: 100%;
  height: 90%;

  .schaddp {
    font-size: 15px;
    color: #6379bb;
    border-bottom: 1px solid #ddd;
    margin: 8px 10px 25px 10px;
    padding-bottom: 5px;
  }
}

.schbut {
  width: 30%;
  display: flex;
  margin-left: 40%;
  justify-content: center;
}
</style>
