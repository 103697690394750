<template>
  <div class="hl-full-container flexVertical" style="width: calc(100% - 2rem)">
    <div class="search">
      <el-form :model="query" ref="query" label-width="80px" inline>
        <el-form-item label="负责人姓名">
          <el-input
            v-model="query.oldName"
            placeholder="请输入老人姓名"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="所属机构">
                    <el-input v-model="query.organ" placeholder="请输入所属机构"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-select v-model="query.sex" placeholder="请选择">
                        <el-option key="1" label="男" value="1"> </el-option>
                        <el-option key="2" label="女" value="2"></el-option>
                    </el-select>
                </el-form-item> -->
        <!-- <template v-if="searchIsShow">
                    <el-form-item label="身份证号">
                        <el-input v-model="query.idCard" placeholder="请输入身份证号"></el-input>
                    </el-form-item>
                    <el-form-item label="电话">
                        <el-input v-model="query.phone" placeholder="请输入电话"></el-input>
                    </el-form-item>
                    <el-form-item label="能力等级">
                        <el-select v-model="query.level" placeholder="请选择">
                            <el-option key="能力完好" label="能力完好" value="能力完好">
                            </el-option>
                            <el-option key="轻度失能" label="轻度失能" value="轻度失能"></el-option>
                            <el-option key="中度失能" label="中度失能" value="中度失能"></el-option>
                            <el-option key="重度失能" label="重度失能" value="重度失能"></el-option>
                            <el-option key="完全失能" label="完全失能" value="完全失能"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="区域">
                        <el-cascader v-model="query.area" :options="options" @change="handleBaseValue"
                            :props="optionProps"></el-cascader>
                    </el-form-item>
                </template> -->
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <!-- <el-button size="small" icon="el-icon-refresh-left" @click="reset('Form')">重置</el-button>
                    <el-button size="small" type="info" :icon="searchIsShow == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
                        " @click="more">更多</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <!-- 中间 -->
    <!-- <div class="toolbar">

            <el-button type="primary" plain size="mini" icon="el-icon-download" @click="derive">导出</el-button>

        </div> -->
    <hltableZ
      style="margin-top: 10px"
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :apiurl="table.apiurl"
      :isPage="false"
      :staticData="table.staticData"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
      :isChecked="table.isChecked"
      :isNumber="table.isNumber"
      :formSearch="query"
      @getSelectedRows="getSelectedRows"
    >
    </hltableZ>
  </div>
</template>

<script>
import { hltableZ, Drawer } from "@/components";
export default {
  name: "YanglaoGatf",
  components: { hltableZ, Drawer },
  data() {
    return {
      searchIsShow: false,
      //   表格
      table: {
        staticData: [],
        columnEdit: false,
        column: [
          {
            label: "负责人姓名",
            prop: "fullName",
            checked: true,
          },
          {
            label: "开始时间",
            prop: "startHour",
            checked: true,
          },
          {
            label: "结束时间",
            prop: "endHour",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: true,
        isNumber: true,
      },
      query: {
        oldName: "",
        idLei: "",
        idCard: "",
        oldType: "",
        sex: "",
        age: "",
        phone: "",
        area: "",
        address: "",
        status: "",
        isResident: "",
        isLocal: "",
        householdAddress: "",
        householdType: "",
        culture: "",
        // interest: [],
      },
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    //   查询
    dataRefresh() {
      this.table.staticData = [
        {
          fullName: "董梅香",
          startHour: "2024/2/18 14:40:00",
          endHour: "2024/2/18 16:10:00",
        },
        {
          fullName: "丁秀茂",
          startHour: "2022/3/8 13:50:00",
          endHour: 44628.65972222222,
        },
        {
          fullName: "王志玲",
          startHour: "2022/3/28 10:10:00",
          endHour: "2022/3/28 11:20:00",
        },
        {
          fullName: "于会香",
          startHour: "2022/4/5 8:20:00",
          endHour: "2022/4/5 10:20:00",
        },
        {
          fullName: "吴依蔓",
          startHour: "2022/4/8 9:20:00",
          endHour: "2022/4/8 11:20:00",
        },
        {
          fullName: "孙丽萍",
          startHour: "2022/4/13 9:20:00",
          endHour: "2022/4/13 10:20:00",
        },
        {
          fullName: "刘永梅",
          startHour: "2022/4/20:2:00",
          endHour: "2022/4/20:3:00",
        },
        {
          fullName: "周晓敏",
          startHour: "2022/4/21 9:20:00",
          endHour: "2022/4/21 10:20:00",
        },
        {
          fullName: "刘永梅",
          startHour: "2022/5/7 9:20:00",
          endHour: "2022/5/7 10:20:00",
        },
        {
          fullName: "周晓敏",
          startHour: "2022/5/10 2:20:00",
          endHour: "2022/5/10 3:20:00",
        },
        {
          fullName: "于会香",
          startHour: "2022/5/13 9:20:00",
          endHour: "2022/5/13 10:30:00",
        },
      ];
      this.table.refreshFlag = new Date().getTime().toString();
    },
    more() {
      this.searchIsShow = !this.searchIsShow;
    },
    async derive() {
      const token = await this.$store.dispatch("user/getToken");
      this.$axios({
        method: "post",
        url: "/api/orderService/visitdoDownLoad",
        data: this.query,
        headers: {
          token: token,
          platform: "web",
        },
        responseType: "blob",
      })
        .then((response) => {
          if (response.headers["content-disposition"]) {
            const content = response.data;
            const blob = new Blob([content]); //构造一个blob对象来处理数据

            // fileName必用这种方式进行解析，否则乱码
            // Server.UrlEncode(filename);

            let contentDisposition = response.headers["content-disposition"];
            // fileName必用这种方式进行解析，否则乱码
            let fileName = window.decodeURI(
              contentDisposition.substring(contentDisposition.indexOf("=") + 1)
            );
            //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
            if ("download" in document.createElement("a")) {
              const link = document.createElement("a"); //创建a标签
              link.download = fileName; //a标签添加属性
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              document.body.appendChild(link);
              link.click(); //执行下载
              URL.revokeObjectURL(link.href); //释放url
              document.body.removeChild(link); //释放标签
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSelectedRows(e) {},
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
</style>
