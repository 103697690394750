<template>
  <div class="hl-full-container flexVertical">
    <!-- 表单s -->
    <el-drawer :title="dailogTitle" :visible.sync="dialogFormVisible" direction="rtl" size="100%" :modal="false"
      :destroy-on-close="true" class="el-drawer-hl" @close="handleClose">
      <el-form ref="elForm" :model="formData" :disabled="submitType == 'view' ? true : false" :rules="rules" size="medium"
        class="padding15" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="角色名" prop="roleName">
              <el-input v-model="formData.roleName" placeholder="请输入角色名" clearable :style="{ width: '100%' }">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序序号" prop="sortNo">
              <el-input v-model="formData.sortNo" placeholder="请输入排序序号" :maxlength="2" clearable
                :style="{ width: '100%' }"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin:0px 0px 20px 30px">
          <el-collapse v-model="activeCollapse">
            <el-collapse-item title="功能权限" name="1">
              <el-tree :data="funData" show-checkbox default-expand-all node-key="id" ref="tree"
                @check-change="treeChecked" :check-strictly="checkStrictly" highlight-current :props="defaultProps">
              </el-tree>
            </el-collapse-item>
          </el-collapse>
        </el-row>
        <el-row>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="formData.remark" type="textarea" placeholder="请输入备注" :autosize="{ minRows: 4, maxRows: 4 }"
              :style="{ width: '100%' }"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item size="large" style="text-align: center;">
            <el-button v-show="submitType != 'view' ? true : false" class="qconfirm" type="primary" size="medium"
              @click="submitForm()">提交</el-button>
            <el-button v-show="submitType != 'view' ? true : false" class="qcancle" size="medium" @click="resetForm()">
              重置
            </el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </el-drawer>
    <!-- 表单e -->
    <div class="search">
      <el-form :model="form" ref="form" label-width="80px" inline>
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="form.roleName" placeholder="请输入角色名称" :maxlength="100" clearable
            :style="{ width: '100%' }"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" icon="el-icon-search" @click="dataRefresh">查询</el-button>
          <el-button size="small" icon="el-icon-refresh-left" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <div class="crud">
        <el-button @click="Add" size="small">
          <i class="el-icon-plus"></i> 新增
        </el-button>
        <el-button @click="Del" size="small">
          <i class="el-icon-delete"></i> 删除
        </el-button>
      </div>
      <el-button-group class="right-btn">
        <el-button icon="el-icon-refresh" @click="dataRefresh"></el-button>
        <el-dropdown trigger="click" :hide-on-click="false">
          <el-button icon="el-icon-s-grid">
            <i class="el-icon-caret-bottom"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in TableOptions.column" :key="index">
              <el-checkbox :label="item.label" v-model="item.checked">
              </el-checkbox>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-button-group>
    </div>
    <hltableZ :isNumber="false" :columnEdit="TableOptions.columnEdit" :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag" :tableColumn="TableOptions.column" :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl" @getSelectedRows="getSelectedRows">
      <div slot-scope='selected' slot="EditColumn">
        <el-button type="primary" icon="el-icon-edit" size="mini" circle @click="Edit(selected.singlerow)"></el-button>
        <el-button type="warning" size="mini" icon="el-icon-search" circle @click="View(selected.singlerow)"></el-button>
      </div>
    </hltableZ>
  </div>
</template>

<script>
import { hltableZ } from '@/components'
export default {
  name: 'roleManage',
  components: { hltableZ },
  data() {
    return {
      checkStrictly: true,
      activeCollapse: ["1"],
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: '',
      //表单信息-s
      formData: {
        roleName: '',
        sortNo: '',
        remark: '',
      },
      rules: {
        roleName: [{
          required: true,
          message: '请输入角色名',
          trigger: 'blur'
        }],
        sortNo: [{
          pattern: /^\d+(\.\d+)?$/,
          message: '请输入数字',
          trigger: 'blur'
        }],
        remark: [],
      },
      //表单信息-e
      //功能树-s
      funData: [],
      defaultProps: {
        children: 'children',
        label: 'moduleName'
      },
      //功能树-e
      form: {
        roleName: ""
      },
      TableOptions: {
        selectedRows: [],
        apiurl: "sysRole/getDataList",
        refreshFlag: '',
        columnEdit: true,
        column: [{
          label: "角色名称",
          prop: "roleName",
          checked: true,
        }, {
          label: "排序序号",
          prop: "sortNo",
          checked: true,
        }, {
          label: "备注",
          prop: "remark",
          checked: true,
        }]
      },
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.getFunctions()
    this.dataRefresh()
  },
  destroyed() {
  },
  methods: {
    treeChecked() {
      this.checkStrictly = false
    },
    getCheckedNodes() {
      const nodes = this.$refs.tree.getCheckedNodes(false, true);
      const moduleIdList = []
      nodes.forEach(v => {
        moduleIdList.push(v.id)
      });
      return moduleIdList
    },
    async setCheckedNodes(id) {
      const funtree = await this.$store.dispatch('sysRole/getDataInfo', { id: id })
      const moduleIdList = []
      funtree.result.moduleIdList.forEach(v => {
        moduleIdList.push({ id: v })
      });
      this.$nextTick(() => {
        this.$refs.tree.setCheckedNodes(moduleIdList);
      })
    },
    getFunctions() {
      this.$store.dispatch("sysModule/moduleTree")
        .then((response) => {
          if (response.success) {
            this.funData = response.result
          }
          else {
            this.$message.error(response.message);
          }
        }).catch((error) => {
          console.log(error)
        })
    },
    dataRefresh() {
      this.TableOptions.refreshFlag = (new Date()).getTime().toString()
    },
    handleClose() {
      this.resetForm()
    },
    resetForm() {
      this.checkStrictly = true
      const th = this
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = ""
      })
    },
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (valid) {
          this.loading = true
          const copyForm = Object.assign({}, this.formData);
          copyForm.moduleIdList = this.getCheckedNodes()
          if (this.submitType == "add") {
            copyForm.id = ''
          }
          this.$store.dispatch('sysRole/saveData', copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh()
              }
              else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false
            })
            .catch((error) => {
              this.dialogFormVisible = false
              console.log(error)
            })
        }
        else {
          console.log('error submit!!')
          return false
        }
      })
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row)
      this.formData = rowinfo;
      this.setCheckedNodes(rowinfo.id)
    },
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title
      this.submitType = type
    },
    Add() {
      this.setformType("add", "新增")
    },
    Edit(row) {
      this.setformType("edit", "编辑")
      this.loadform(row)
    },
    Del: function () {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: 'error',
          message: '请勾选下方表格中的数据'
        })
        return false;
      }
      var ids = []
      this.TableOptions.selectedRows.forEach(key => {
        ids.push(key.id)
      })
      this.$confirm('确定要进行删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('sysRole/deleteData', { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh()
            }
            else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    View(row) {
      this.setformType("view", "查看")
      this.loadform(row)
    },
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows
    },
    reset() {
      // this.$refs['form'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this
      Object.keys(this.form).forEach(function (key) {
        th.form[key] = ""
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
</style>
