<template>
  <Drawer
    :title="title"
    ref="drawerIshow"
    :buttonIsShow="true"
    @resetForm="resetForm"
    submitFormName="保存"
  >
    <subTitle information-name="基本信息"></subTitle>
    <el-row :gutter="24">
      <el-form class="form" ref="form" :model="dataForm" label-width="140px">
        <el-col :span="12">
          <el-form-item label="服务组织" prop="serviceOrganization">
            <el-select
              v-model="dataForm.serviceOrganization"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in serviceOrganizationOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账号" prop="account">
            <el-input v-model="dataForm.account"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            class="bindEmployees"
            label="绑定员工"
            prop="bindEmployees"
          >
            <el-input v-model="dataForm.bindEmployees"></el-input>
            <el-button
              type="primary"
              plain
              size="mini"
              icon="el-icon-plus"
              @click="handleSlect"
              >选择</el-button
            >
            <el-button
              type="primary"
              plain
              size="mini"
              icon="el-icon-close"
              @click="handleClear"
              >清空</el-button
            >
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item class="userName" label="用户姓名" prop="userName">
            <el-input v-model="dataForm.userName"></el-input>
            <span>请输入员工真实姓名</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="定期修改密码(90天)" prop="regularPassword">
            <el-select
              v-model="dataForm.regularPassword"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option label="开启" value="1"></el-option>
              <el-option label="关闭" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注" prop="remarks">
            <el-input v-model="dataForm.remarks"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <Drawer title="员工档案" ref="employeeDrawerIshow">
      <el-form ref="employeeForm" :model="employeeDataForm" inline>
        <el-form-item label="用户姓名" prop="employeesName">
          <el-input v-model="employeeDataForm.employeesName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="employeesDataRefresh"
            >查询</el-button
          >
          <el-button
            size="small"
            icon="el-icon-refresh-left"
            @click="employeesReset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <hltableZ
        :columnEdit="table.columnEdit"
        :tableColumn="table.column"
        :apiurl="table.apiurl"
        :refreshFlag="table.refreshFlag"
        :selectedRows="table.selectedRows"
        :isChecked="table.isChecked"
        :isNumber="table.isNumber"
        :widthClick="table.widthClick"
        :isClickSelect="table.isClickSelect"
        :staticData="tableDataSource"
        :formSearch="employeeDataForm"
      >
        <div slot-scope="selected" slot="EditColumn">
          <el-button
            icon="el-icon-check"
            type="text"
            size="small"
            @click="handleCheck(selected.singlerow)"
            >选择</el-button
          >
        </div>
      </hltableZ>
    </Drawer>
  </Drawer>
</template>

<script>
import { Drawer, hltableZ } from "@/components";
import subTitle from "@/views/ZC/components/common/subTitle.vue";
export default {
  name: "AccountInfoDrawer",
  components: { Drawer, subTitle, hltableZ },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      serviceOrganizationOptions: [
        { label: "辛店街道寨子村农村幸福院", value: "1" },
        { label: "辛店街道渠村农村幸福院", value: "2" },
        { label: "辛店街道安里村农村幸福院", value: "3" },
      ],
      // 员工form
      dataForm: {
        serviceOrganization: "",
        account: "",
        bindEmployees: "",
        userName: "",
        regularPassword: "",
        remarks: "",
      },
      // 员工档案搜索条件
      employeeDataForm: {
        employeesName: "",
      },
      // 表格属性值
      table: {
        columnEdit: true,
        column: [
          {
            label: "姓名",
            prop: "1",
            checked: true,
          },
          {
            label: "身份证号",
            prop: "2",
            checked: true,
          },
          {
            label: "性别",
            prop: "3",
            checked: true,
          },
        ],
        apiurl: "",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
        isClickSelect: false,
      },
      tableDataSource: [
        {
          1: "l1978",
          2: "370305**********22",
          3: "女",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    resetForm() {
      this.$refs.form.resetFields();
    },
    // 选择
    handleSlect() {
      this.$refs.employeeDrawerIshow.drawer = true;
    },
    // 清空
    handleClear() {},
    // 员工档案搜索
    employeesDataRefresh() {},
    // 员工档案重置
    employeesReset() {
      this.$refs.employeeForm.resetFields();
    },
    // 选择员工
    handleCheck() {},
  },
};
</script>

<style lang="scss" scoped>
.form {
  .bindEmployees ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
    .el-input {
      margin-right: 0.625rem;
    }
  }
  .userName ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
    .el-input {
      flex: 1;
      margin-right: 0.625rem;
    }
  }
}
</style>
