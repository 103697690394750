<template>
  <!-- 官方定义统计大类 -->
  <div class="Statistica">
    <p>用于大数据分析中的助老服务统计。</p>
    <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">新增</el-button>
    <div class="jtcw1">
      <hltableZ :columnEdit="table1.columnEdit" :tableColumn="table1.column" :apiurl="table1.apiurl"
        :refreshFlag="table1.refreshFlag" :selectedRows="table1.selectedRows" :isChecked="table1.isChecked"
        :isNumber="table1.isNumber" :formSearch="query1">
        <div slot-scope="selected" slot="EditColumn">
          <el-button icon="el-icon-delete" type="primary" size="small" @click="disable(selected.singlerow)"
            v-if='selected.singlerow.status == 1'>下架</el-button>
          <el-button icon="el-icon-delete" type="primary" size="small" @click="disable(selected.singlerow)"
            v-else>上架</el-button>
        </div>
      </hltableZ>
    </div>
    <!-- 新增弹窗 -->
    <Drawer ref="drawerIshow" :button-is-show="true" @submitForm="submitForm" :submit-form-name="submitFormName">
      <div class="schadd">
        <p class="schaddp">基本信息</p>
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="服务类型名称:">
            <el-input v-model="form.projectName"></el-input>
          </el-form-item>
          <!-- <el-form-item label="末级节点:">
            <el-select v-model="form.region" placeholder="">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="市场化类型:">
            <el-select v-model="form.region" placeholder="请选择">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="官方定义类型:">
            <el-select v-model="form.pid" placeholder="请选择" @change="selectc">
              <el-option v-for="(item, index) in serviceOrganizationList" :label="item.projectName"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="服务状态:">
            <el-select v-model="form.status" placeholder="请选择">
              <el-option label="上架" value='1'></el-option>
              <el-option label="下架" value='2'></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="价格:">
            <el-input type="textarea" v-model="form.money"></el-input>
          </el-form-item>
          <el-form-item label="排序码:">
            <el-input type="textarea" v-model="form.sort"></el-input>
          </el-form-item> -->
          <el-form-item label="服务项目介绍:">
            <el-input type="textarea" v-model="form.introduce"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { hltableZ, Drawer } from "@/components";
export default {
  components: { hltableZ, Drawer },
  data() {
    return {
      submitFormName: '保存',
      table1: {
        columnEdit: true,
        column: [
          {
            label: "类别名称",
            prop: "projectName",
            checked: true,
          },
          {
            label: "启用状态",
            prop: "status",
            checked: true,
            formatter(value) {
              const status = value.status;
              if (status == '1') {
                return '上架'
              } else if (status == '2') {
                return '下架'
              }
            },
          },
          // {
          //   label: "服务项目数量",
          //   prop: "oldName",
          //   checked: true,
          // },
          {
            label: "描述",
            prop: "introduce",
            checked: true,
          },
        ],
        apiurl: "jbsz/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      query1: {
        isEnd: 2,
        status: '',
        id: ''
      },
      //新增
      form: {
        projectName: "",
        type: "",
        sort: "",
        pid: '',
        isEnd: 2,
        money: '',
        status: "",
        introduce: '',
        id: ''
      },
      message: '',
    };
  },
  mounted() { },
  methods: {
    disable(e) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = e[key] || this.form[key]
      })
      this.$confirm("确定修改?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {

          if (e.status == 1) {
            this.form.status = 2
          } else if (e.status == 2) {
            this.form.status = 1
          }
          this.$store.dispatch("jbsz/livingProjectDictsaveData", this.form).then((res) => {
            this.$message({
              type: 'success',
              message: '修改成功!'
            });
            this.dataRefresh();
          })

        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取修改",
          });
        });
    },
    submitForm() {
      this.$store.dispatch("jbsz/livingProjectDictsaveData", this.form).then((res) => {
        this.$message({
          message: this.message,
          type: 'success'
        });
        this.dataRefresh()
        this.$refs.drawerIshow.drawer = false
      })
    },
    dataRefresh() {
      this.table1.refreshFlag = (new Date()).getTime().toString()
    },
    add() {
      this.message = '新增成功'
      const th = this
      Object.keys(th.form).forEach(function (key) {
        if (key !== 'isEnd') {
          th.form[key] = ''
        }


      })
      this.$refs.drawerIshow.drawer = true;
    },
  },
};
</script>
<style scoped lang="scss">
.Statistica {
  p {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .jtcw1 {
    width: 100%;
    height: 40rem;
    margin-top: 1rem;
  }
}
</style>
