<template>
    <div class="hl-full-container flexVertical" style="width: calc(100% - 2rem)">
        <div class="search">
            <el-form :model="query" ref="query" label-width="80px" inline>
                <el-form-item label="老人姓名">
                    <el-input v-model="query.oldName" placeholder="请输入老人姓名"></el-input>
                </el-form-item>
                <el-form-item label="所属机构">
                    <el-input v-model="query.organ" placeholder="请输入所属机构"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-select v-model="query.sex" placeholder="请选择">
                        <el-option key="1" label="男" value="1"> </el-option>
                        <el-option key="2" label="女" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <template v-if="searchIsShow">
                    <el-form-item label="身份证号">
                        <el-input v-model="query.idCard" placeholder="请输入身份证号"></el-input>
                    </el-form-item>
                    <el-form-item label="电话">
                        <el-input v-model="query.phone" placeholder="请输入电话"></el-input>
                    </el-form-item>
                    <el-form-item label="能力等级">
                        <el-select v-model="query.level" placeholder="请选择">
                            <el-option key="能力完好" label="能力完好" value="能力完好">
                            </el-option>
                            <el-option key="轻度失能" label="轻度失能" value="轻度失能"></el-option>
                            <el-option key="中度失能" label="中度失能" value="中度失能"></el-option>
                            <el-option key="重度失能" label="重度失能" value="重度失能"></el-option>
                            <el-option key="完全失能" label="完全失能" value="完全失能"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="区域">
                        <el-cascader v-model="query.area" :options="options" @change="handleBaseValue"
                            :props="optionProps"></el-cascader>
                    </el-form-item>
                </template>
                <el-form-item>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
                    <el-button size="small" icon="el-icon-refresh-left" @click="reset('Form')">重置</el-button>
                    <el-button size="small" type="info" :icon="searchIsShow == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
                        " @click="more">更多</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 中间 -->
        <div class="toolbar">
            <el-button type="text" plain size="mini" icon="el-icon-upload2">
                <a href="/static/jjlr.xlsx" download="老人信息管理模板.xlsx">模板下载</a></el-button>
            <el-upload :headers="token" :file-list="xlsAreafileList" :action="xlsAreaAction"
                :on-success="onXlsUploadSuccess" accept=".xlsx, .xls" style="margin: 0 1rem">
                <el-button type="success" plain size="mini" icon="el-icon-upload">导入</el-button>
            </el-upload>

            <el-button type="primary" plain size="mini" icon="el-icon-download" @click="derive">导出</el-button>

            <el-button type="primary" plain size="mini" icon="el-icon-plus" @click="add">新增</el-button>
            <el-button type="primary" plain size="mini" icon="el-icon-plus" @click="contract">创建合约</el-button>
        </div>
        <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
            :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
            :isNumber="table.isNumber" :formSearch="query" @getSelectedRows="getSelectedRows">
            <div slot-scope="selected" slot="EditColumn">
                <el-button icon="el-icon-view" type="text" size="small" @click="search(selected.singlerow)">查看</el-button>
                <el-dropdown>
                    <span class="el-dropdown-link">
                        更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1"> 生存异常</el-dropdown-item>
                        <!-- <el-dropdown-item command="2">迁出</el-dropdown-item> -->
                        <el-dropdown-item command="3"
                            @click.native="clickdropdown(selected.singlerow)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </hltableZ>
        <!-- 弹窗 -->
        <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize" :buttonIsShow="true"
            :submitFormName="submitFormName" @submitForm="submitForm">
            <el-tabs type="border-card" v-model="activeItem.name" @tab-click="tabClick" v-if='lrxxIshow'>
                <el-tab-pane v-for="item in systemConfig" :key="item.key" :label="item.name" :name="item.name">
                    <div class="component">
                        <component :is="activeItem.component" :dataProps="selectData" ref="componentRef"></component>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </Drawer>
        <!-- 新增 -->
        <Drawer :title="title2" ref="drawerIshow2" :drawerSize="drawerSize" :buttonIsShow="true" class="adds"
            :submitFormName="submitFormName" @submitForm="submitForm2">
            <div class="information">
                <div class="title">
                    <span style="">基本信息</span>
                    <div>
                        <i class="el-icon-printer" style="font-size.5rem;color"></i>
                        打印
                    </div>
                </div>
                <div class="content">
                    <el-form :label-position="labelPosition" :rules='rules' label-width="170px" :model="formLabelAlign"
                        ref="formLabelAlign" style="display: flex; flex-wrap: wrap">
                        <el-form-item label="老人头像:" style="width: 39%">
                            <el-upload class="avatar-uploader" :show-file-list="false" :headers="token"
                                :file-list="xlsAreafileList2" :action="xlsAreaAction2" :before-upload="xlsBeforeUpload"
                                :on-success="handleAvatarSuccess" :on-progress="onprogress" accept=".jpg, .png"
                                style="width:10rem">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item style="width: 49%">
                            <el-form-item label="老人姓名:" prop="oldName" style="width: 100%; margin-top: 1rem">
                                <el-input v-model="formLabelAlign.oldName" placeholder="请填写老人姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="证件类型:" prop="idType" style="width: 100%; margin-top: 1rem">
                                <el-select v-model="formLabelAlign.idType" placeholder="请选择证件类型">
                                    <el-option key="内地居民身份证" label="内地居民身份证" value="内地居民身份证"></el-option>
                                    <el-option key="香港居民身份证" label="香港居民身份证" value="香港居民身份证"></el-option>
                                    <el-option key="奥门居民身份证" label="奥门居民身份证" value="奥门居民身份证"></el-option>
                                    <el-option key="台湾居民身份证" label="台湾居民身份证" value="台湾居民身份证"></el-option>
                                    <el-option key="护照" label="护照" value="护照"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="身份证号:" prop="idCard" style="width: 100%; margin-top: 1rem">
                                <el-input v-model="formLabelAlign.idCard" placeholder="请输入身份证号"></el-input>
                            </el-form-item>
                        </el-form-item>
                        <el-form-item label="老人分类:" prop="oldType" style="width: 49%">
                            <el-select v-model="formLabelAlign.oldType" placeholder="请选择老人分类">
                                <el-option key="分散自理" label="分散自理" value="分散自理"> </el-option>
                                <el-option key="分散半自理" label="分散半自理" value="分散半自理"></el-option>
                                <el-option key="低保农村300" label="低保农村300" value="低保农村300"></el-option>
                                <el-option key="低保农村450" label="低保农村450" value="低保农村450"></el-option>
                                <el-option key="低保农村600" label="低保农村600" value="低保农村600"></el-option>
                                <el-option key="低保城市450" label="低保城市450" value="低保城市450"></el-option>
                                <el-option key="低保城市675" label="低保城市675" value="低保城市675"></el-option>
                                <el-option key="低保城市900" label="低保城市900" value="低保城市900"></el-option>
                                <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option> -->
                            </el-select>
                        </el-form-item>
                        <el-form-item label="出生日期:" style="width: 49%" prop="date1">
                            <el-date-picker type="date" placeholder="选择出生日期"
                                v-model="formLabelAlign.date1"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="性别:" style="width: 49%" prop="sex">
                            <el-select v-model="formLabelAlign.sex" placeholder="请选择性别">
                                <el-option key="男" label="男" value="男"> </el-option>
                                <el-option key="女" label="女" value="女"></el-option>
                                <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option> -->
                            </el-select>
                        </el-form-item>
                        <el-form-item label="年龄:" prop="age" style="width: 49%">
                            <el-input v-model="formLabelAlign.age" placeholder="请输入年龄"></el-input>
                        </el-form-item>
                        <el-form-item label="民族:" style="width: 49%" prop="idLei">
                            <el-select v-model="formLabelAlign.idLei" placeholder="请选择民族">
                                <el-option key="汉" label="汉" value="汉"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="联系电话:" prop="phone" style="width: 49%">
                            <el-input v-model="formLabelAlign.phone" placeholder="请输入联系电话"></el-input>
                        </el-form-item>
                        <el-form-item label="行政区划:" prop="area" style="width: 49%">
                            <el-cascader v-model="formLabelAlign.area" :options="options" @change="handleBaseValue"
                                :props="optionProps"></el-cascader>
                        </el-form-item>
                        <!-- <el-form-item label="通过社区（村）快速选择:" style="width: 49%">
                            <el-select v-model="formLabelAlign.idLei" placeholder="请选择">

                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="居住地址:" prop="address" style="width: 49%">
                            <el-input v-model="formLabelAlign.address" placeholder="请输入居住地址"></el-input>
                            <div style="color:#DCDFE6"> 格式：xx市区xx区xx街道xx小区xx楼xx单元xx室</div>
                        </el-form-item>
                        <el-form-item label="生存状态:" prop="status" style="width: 49%">
                            <el-select v-model="formLabelAlign.status" placeholder="请选择生存状态">
                                <el-option key="正常" label="正常" value="正常"> </el-option>
                                <el-option key="异常" label="异常" value="异常"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="是否常住人口:" style="width: 49%" prop="isResident">
                            <el-select v-model="formLabelAlign.isResident" placeholder="请选择是否常住人口">
                                <el-option key="是" label="是" value="是"> </el-option>
                                <el-option key="否" label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="本市户籍:" style="width: 49%" prop="isLocal">
                            <el-select v-model="formLabelAlign.isLocal" placeholder="请选择本市户籍">
                                <el-option key="是" label="是" value="是"> </el-option>
                                <el-option key="否" label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="户籍地址:" style="width: 49%" prop="householdAddress">
                            <el-input v-model="formLabelAlign.householdAddress" placeholder="请输入户籍地址"></el-input>
                        </el-form-item>
                        <el-form-item label="户口性质:" style="width: 49%" prop="householdType">
                            <el-select v-model="formLabelAlign.householdType" placeholder="请选择户口性质">
                                <el-option key="农业户口" label="农业户口" value="农业户口"> </el-option>
                                <el-option key="非农业户口" label="非农业户口" value="非农业户口"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="文化程度:" style="width: 49%" prop="culture">
                            <el-select v-model="formLabelAlign.culture" placeholder="请选择文化程度">
                                <el-option key="文盲半文盲" label="文盲半文盲" value="文盲半文盲"> </el-option>
                                <el-option key="小学" label="小学" value="小学"></el-option>
                                <el-option key="初中/中专" label="初中/中专" value="初中/中专"></el-option>
                                <el-option key="高中" label="高中" value="高中"></el-option>
                                <el-option key="大学专科" label="大学专科" value="大学专科"></el-option>
                                <el-option key="大学本科" label="大学本科" value="大学本科"></el-option>
                                <el-option key="研究生" label="研究生" value="研究生"></el-option>
                                <el-option key="博士生及以上" label="博士生及以上" value="博士生及以上"></el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="兴趣爱好:" prop="name" style="width: 100%">
                            <div class="hobby">
                                <el-checkbox-group v-model="formLabelAlign.interest">
                                    <el-checkbox label="京剧"></el-checkbox>
                                    <el-checkbox label="书法"></el-checkbox>
                                    <el-checkbox label="唱歌"></el-checkbox>
                                    <el-checkbox label="茶道"></el-checkbox>
                                    <el-checkbox label="园艺"></el-checkbox>
                                    <el-checkbox label="太极"></el-checkbox>
                                    <el-checkbox label="广场舞"></el-checkbox>
                                    <el-checkbox label="游泳"></el-checkbox>
                                    <el-checkbox label="象棋"></el-checkbox>
                                    <el-checkbox label="二胡"></el-checkbox>
                                    <el-checkbox label="扑克"></el-checkbox>
                                    <el-checkbox label="民族舞"></el-checkbox>
                                    <el-checkbox label="钓鱼"></el-checkbox>
                                    <el-checkbox label="登山"></el-checkbox>
                                    <el-checkbox label="旅游"></el-checkbox>
                                    <el-checkbox label="猫狗"></el-checkbox>
                                    <el-checkbox label="麻将"></el-checkbox>
                                </el-checkbox-group>
                            </div>
                            <div>其他：</div>
                            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="formLabelAlign.textarea">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="助老服务需求:" prop="name" style="width: 100%">
                            <div class="hobby">
                                <el-checkbox-group v-model="formLabelAlign.interest">
                                    <el-checkbox label="助餐"></el-checkbox>
                                    <el-checkbox label="助医"></el-checkbox>
                                    <el-checkbox label="助洁"></el-checkbox>
                                    <el-checkbox label="助浴"></el-checkbox>
                                    <el-checkbox label="助乐"></el-checkbox>
                                    <el-checkbox label="助急"></el-checkbox>
                                    <el-checkbox label="助购"></el-checkbox>
                                    <el-checkbox label="助聊"></el-checkbox>
                                    <el-checkbox label="助行"></el-checkbox>
                                    <el-checkbox label="助学"></el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-form-item> -->
                        <el-form-item label="备注:" prop="textarea" style="width: 100%">
                            <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="formLabelAlign.textarea"
                                style="width: 80rem">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </Drawer>
        <Drawer :title="title3" ref="drawerIshow3" :drawerSize="drawerSize2" :buttonIsShow="true"
            :submitFormName="submitFormName" @submitForm="submitForm3">
            <el-form :model="ruleFormobje" ref="ruleForm3" label-width="180px" class="demo-ruleForm" :rules="chRules">
                <el-form-item label="已选择服务对象：" prop="name" v-for="(item, index) in oldName" :key="index">
                    <span>{{ item }}</span>
                </el-form-item>
                <el-form-item label="选择政府服务批次：" prop="batchId">
                    <el-select v-model="ruleFormobje.batchId" placeholder="请选择选择政府服务批次" @change="batchIdClick">
                        <el-option v-for="(item, index) in livingBatchgetLists" :label="item.describe"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择服务包：" prop="packageId">
                    <el-select v-model="ruleFormobje.packageId" placeholder="请选择选择服务包" @change='packageIdClick'>
                        <el-option v-for="(item, index) in optionss" :label="item.packageName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分配服务商：" prop="organId">
                    <el-select v-model="ruleFormobje.organId" placeholder="请选择服务商">
                        <el-option v-for="(item, index) in optionss2" :label="item.organName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="坐标同步：" prop="flag">
                    <el-select v-model="ruleFormobje.flag" placeholder="请选择">
                        <el-option label="同步服务对象的经纬度信息到合约" value="1"></el-option>
                        <el-option label="不同步服务对象的经纬度信息到合约" value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </Drawer>
    </div>
</template>
    
<script>
import { hltableZ, Drawer } from "@/components";
export default {
    name: "lrxx",
    components: { hltableZ, Drawer },
    data() {
        return {
            // 上传
            xlsAreafileList2: [],
            drawerSize: "90%",
            drawerSize2: '40%',
            formLabelAlign: {
                oldName: '',
                idLei: '',
                idCard: '',
                oldType: '',
                sex: '',
                age: '',
                phone: '',
                area: '',
                address: '',
                status: '',
                isResident: '',
                isLocal: '',
                householdAddress: '',
                householdType: '',
                culture: '',
                // interest: [],
                type: "1",
                fileStoreEntity: '',
                idType: '',
            },
            fileList: [],
            submitFormName: '保存',
            //   表格
            table: {
                columnEdit: true,
                column: [
                    {
                        label: "老人姓名",
                        prop: "oldName",
                        checked: true,
                        width: "150",
                    },
                    {
                        label: "照片",
                        prop: "filePath",
                        checked: true,
                        columnType: "img",
                        width: "150",
                        formatter(value) {
                            return value[0].filePath;

                        },
                    },
                    {
                        label: "所属机构",
                        prop: "organName",
                        checked: true,
                        width: "150",
                    },
                    {
                        label: "性别",
                        prop: "sex",
                        checked: true,
                        width: "150",
                        formatter(value) {
                            const sex = value.sex;
                            return sex == "1" ? "男" : "女";
                        },
                    },
                    {
                        label: "年龄",
                        prop: "age",
                        checked: true,
                        width: "150",
                    },
                    {
                        label: "身份证号",
                        prop: "idCard",
                        checked: true,
                        width: "150",
                    },
                    {
                        label: "联系电话",
                        prop: "phone",
                        checked: true,
                        width: "150",
                    },
                    {
                        label: "老人类别",
                        prop: "oldType",
                        checked: true,
                        width: "150",
                    },
                    // {
                    //     label: "服务类型",
                    //     prop: "serveType",
                    //     checked: true,
                    //     width: "150",
                    // },
                    // {
                    //     label: "能力等级",
                    //     prop: "level",
                    //     checked: true,
                    //     width: "150",
                    // },
                    {
                        label: "所属区别",
                        prop: "areaName",
                        checked: true,
                        width: "150",
                    },
                    {
                        label: "生存状态",
                        prop: "status",
                        checked: true,
                        width: "150",
                    },
                    {
                        label: "家庭地址",
                        prop: "address",
                        checked: true,
                        width: "150",
                    },
                    {
                        label: "登记时间",
                        prop: "createTime",
                        checked: true,
                        formatter: this.timeFormatter,
                        width: "150",
                    },
                ],
                apiurl: "lrxx/getDataList",
                refreshFlag: "",
                selectedRows: [],
                isChecked: true,
                isNumber: true,
            },
            query: {
                oldName: '',
                idLei: '',
                idCard: '',
                oldType: '',
                sex: '',
                age: '',
                phone: '',
                area: '',
                address: '',
                status: '',
                isResident: '',
                isLocal: '',
                householdAddress: '',
                householdType: '',
                culture: '',
                type: "1",
                // interest: [],
            },
            rules: {
                oldName: [
                    { required: true, message: '请填写老人姓名', trigger: 'blur' },
                ],
                idLei: [
                    { required: true, message: '请选择证件类型', trigger: 'change' },
                ],
                oldType: [
                    { required: true, message: '请选择老人分类', trigger: 'change' },
                ],
                date1: [
                    { required: true, message: '请选择日期', trigger: 'change' },
                ],
                idCard: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                ],
                oldType: [
                    { required: true, message: '请选择老人分类', trigger: 'change' },
                ],
                date: [
                    { required: true, message: '请选择出生日期', trigger: 'change' },
                ],
                sex: [
                    { required: true, message: '请选择出生日期', trigger: 'change' },
                ],
                age: [
                    { required: true, message: '请输入年龄', trigger: 'blur' },
                ],
                area: [
                    { required: true, message: '请选择行政区划', trigger: 'change' },
                ],
                idLei: [
                    { required: true, message: '请选择民族', trigger: 'change' },
                ],
                phone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                ],
                address: [
                    { required: true, message: '请输入居住地址', trigger: 'blur' },
                ],
                status: [
                    { required: true, message: '请选择生存状态', trigger: 'change' },
                ],
                isResident: [
                    { required: true, message: '请是否常住人口', trigger: 'change' },
                ],
                isLocal: [
                    { required: true, message: '请选择本市户籍', trigger: 'change' },
                ],
                householdAddress: [
                    { required: true, message: '请输入户籍地址', trigger: 'blur' },
                ],
                householdType: [
                    { required: true, message: '请选择户口性质', trigger: 'change' },
                ],
                culture: [
                    { required: true, message: '请选择文化程度', trigger: 'change' },
                ],
                idType: [
                    { required: true, message: '请选择证件类型', trigger: 'change' },
                ]
            },
            chRules: {
                batchId: [
                    { required: true, message: '请选择选务包', trigger: 'change' },
                ],
                packageId: [
                    { required: true, message: '请选择服务包', trigger: 'change' },
                ],
                organId: [
                    { required: true, message: '请选择服务商', trigger: 'change' },
                ],
                flag: [
                    { required: true, message: '请选择', trigger: 'change' },
                ],
            },
            options: [],
            optionProps: {
                value: 'pcode',
                label: 'name',
                children: 'children'
            },
            // 新增
            drawerIshow2: false, // 弹框的出现与否
            buttonIshow2: true,
            title2: "新增",
            rulesAdd: [],
            token: { token: localStorage.getItem("systoken") },
            xlsAreafileList: [],
            xlsAreaAction2: "/api/fileStore/upload.json",
            xlsAreaAction: "/api/oldUser/importUser.json",
            title: "老人档案",
            activeItem: {},
            systemConfig: this.$store.getters.systemSettings.config,
            searchIsShow: false,
            //   新增数据
            labelPosition: "right",
            imageUrl: "",
            // rules: {
            //     name: [
            //         { required: true, message: "请输入活动名称", trigger: "blur" },
            //     ],
            //     region: [
            //         { required: true, message: "请选择活动区域", trigger: "change" },
            //     ],
            //     type: [
            //         {
            //             type: "array",
            //             required: true,
            //             message: "请至少选择一个活动性质",
            //             trigger: "change",
            //         },
            //     ],
            //     resource: [
            //         { required: true, message: "请选择活动资源", trigger: "change" },
            //     ],
            //     idCard: [
            //         { required: true, message: "请填写活动形式", trigger: "blur" },
            //     ],
            // },
            checkList: [],
            selectData: {},
            tablNum: '',
            lrxxIshow: true,
            ids: [],
            title3: '创建合约',
            ruleFormobje: {
                flag: "1",
                organId: '',
                packageId: '',
                batchId: ""
            },
            oldName: [],
            rulesobje: {},
            livingBatchgetLists: [],
            optionss: [],
            optionss2: []
        };
    },

    mounted() {
        this.getDataTree()
        this.livingBatchgetList()
    },

    methods: {
        batchIdClick(e) {
            this.livingPackagegetList(e)
        },
        packageIdClick(e) {
            this.organizationGetList2(e)
        },
        livingPackagegetList(e) {
            this.$store.dispatch("jbsz/livingPackagegetList", { batchId: e }).then((res) => {
                console.log(77777, res)
                this.optionss = res.result
            });
        },
        organizationGetList2(e) {
            this.$store.dispatch("jbsz/organizationGetList2", e).then((res) => {
                this.optionss2 = res.result
            });
        },
        clickdropdown(e) {
            console.log(e)
            this.$confirm('确定将选择数据删除?', '', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store
                    .dispatch("homePage/deleteData", { ids: [e.id] })
                    .then((response) => {
                        if (response.success) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.dataRefresh();
                        }
                    })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        onprogress(event, file, fileList) {
            setTimeout(() => {
                this.formLabelAlign.fileStoreEntity = file.response
            }, 500)



        },
        xlsBeforeUpload(e) {
            console.log(88888, e)

        },
        submitForm2() {
            this.$refs.formLabelAlign.validate(valid => {
                if (!valid) {
                } else {
                    let formDatas = new FormData();
                    formDatas.append('oldName', this.formLabelAlign.oldName)
                    formDatas.append('idLei', this.formLabelAlign.idLei)
                    formDatas.append('idCard', this.formLabelAlign.idCard)
                    formDatas.append('oldType', this.formLabelAlign.oldType)
                    formDatas.append('sex', this.formLabelAlign.sex)
                    formDatas.append('age', this.formLabelAlign.age)
                    formDatas.append('area', this.formLabelAlign.area)
                    formDatas.append('phone', this.formLabelAlign.phone)
                    formDatas.append('address', this.formLabelAlign.address)
                    formDatas.append('status', this.formLabelAlign.status)
                    formDatas.append('isResident', this.formLabelAlign.isResident)
                    formDatas.append('isLocal', this.formLabelAlign.isLocal)
                    formDatas.append('householdAddress', this.formLabelAlign.householdAddress)
                    formDatas.append('householdType', this.formLabelAlign.householdType)
                    formDatas.append('culture', this.formLabelAlign.culture)
                    formDatas.append('type', '1')
                    formDatas.append(`photo`, this.formLabelAlign.fileStoreEntity)
                    this.$store.dispatch("homePage/oldUser", formDatas).then((res) => {
                        console.log(res)
                        if (res.success == true) {
                            this.$refs.drawerIshow2.drawer = false;
                            this.dataRefresh()
                        } else {
                            this.$message.error(res.message)
                        }

                    })
                }
            })

        },
        interestClick(e) {
            this.formLabelAlign.interest = e
            console.log(this.interest)
        },
        close() {
            this.$refs.drawerIshow2.drawer = false;
        },
        handleAvatarSuccess(res, file) {
            console.log(999, file)
            this.imageUrl = URL.createObjectURL(file.row);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },
        timeFormatter(row, column, cellValue) {
            return cellValue ? this.$timestampTostr(cellValue) : "";
        },
        //   查询
        dataRefresh() {
            this.table.refreshFlag = new Date().getTime().toString();
        },
        reset() {
            const th = this;
            Object.keys(th.query).forEach(function (key) {
                if (key == "value") {
                    th.query.value = "1";
                } else {
                    th.query[key] = "";
                }
            });
            this.dataRefresh();
        },

        // 修改是否让页面显示与隐藏的事件
        updateVisible5(val) {
            this.dialogVisible5 = val;
        },
        // 点击取消的事件
        resetPopupData5() {
            //  这里可重置数据
            this.dialogVisible5 = false;
        },
        // 点击确定的按钮
        async submitPopupData5() {
            this.dialogVisible5 = false;
        },
        // 关闭弹框（头部的X）
        handleClose5() {
            this.dialogVisible5 = false;
        },
        // 导入
        onXlsUploadSuccess(response) {
            if (response.success) {
                this.$message({
                    type: "success",
                    message: "导入成功",
                });
                this.dataRefresh();
            } else {
                this.$message({
                    type: "error",
                    message: "导入失败",
                });
            }
        },
        async derive() {
            const token = await this.$store.dispatch("user/getToken")
            this.$axios({
                method: "post",
                url: "/api/oldUser/doDownLoad",
                data: this.query,
                headers: {
                    token: token,
                    platform: "web",
                },
                responseType: "blob",
            })
                .then((response) => {
                    if (response.headers["content-disposition"]) {
                        const content = response.data;
                        const blob = new Blob([content]); //构造一个blob对象来处理数据

                        // fileName必用这种方式进行解析，否则乱码
                        // Server.UrlEncode(filename);

                        let contentDisposition = response.headers["content-disposition"];
                        // fileName必用这种方式进行解析，否则乱码
                        let fileName = window.decodeURI(
                            contentDisposition.substring(contentDisposition.indexOf("=") + 1)
                        );
                        //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
                        if ("download" in document.createElement("a")) {
                            const link = document.createElement("a"); //创建a标签
                            link.download = fileName; //a标签添加属性
                            link.style.display = "none";
                            link.href = URL.createObjectURL(blob);
                            document.body.appendChild(link);
                            link.click(); //执行下载
                            URL.revokeObjectURL(link.href); //释放url
                            document.body.removeChild(link); //释放标签
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        search(e) {
            this.$store.dispatch("homePage/getDataInfo", { id: e.id }).then((res) => {
                this.$refs.drawerIshow.drawer = true;
                this.lrxxIshow = false
                setTimeout(() => { this.lrxxIshow = true, this.setActiveItem(this.systemConfig[0]); }, 500)

                this.selectData = res
            })
        },
        setActiveItem(item) {
            this.activeItem = {
                name: item.name,
                key: item.key,
                component: item.component,
            };
        },
        tabClick(item) {
            this.tablNum = item.index
            this.setActiveItem(
                this.systemConfig.filter((v) => v.name === item.name)[0]
            );
        },
        more() {
            this.searchIsShow = !this.searchIsShow;
        },
        // 新增
        add() {
            const th = this;
            Object.keys(th.formLabelAlign).forEach(function (key) {

                th.formLabelAlign[key] = "";

            });

            this.$refs.drawerIshow2.drawer = true;
        },
        submitForm() {
            if (this.tablNum == 1) {
                this.$store.dispatch("homePage/saveData", this.$refs.componentRef[1].formLabelAlign).then((res) => {
                    this.$refs.drawerIshow.drawer = false;
                })
            } else if (this.tablNum == 0) {
                let formDatas = new FormData();
                formDatas.append('id', this.$refs.componentRef[0].formLabelAlign.id)
                formDatas.append('oldName', this.$refs.componentRef[0].formLabelAlign.oldName)
                formDatas.append('idLei', this.$refs.componentRef[0].formLabelAlign.idLei)
                formDatas.append('idCard', this.$refs.componentRef[0].formLabelAlign.idCard)
                formDatas.append('oldType', this.$refs.componentRef[0].formLabelAlign.oldType)
                formDatas.append('sex', this.$refs.componentRef[0].formLabelAlign.sex)
                formDatas.append('age', this.$refs.componentRef[0].formLabelAlign.age)
                formDatas.append('areaName', this.$refs.componentRef[0].formLabelAlign.areaName)
                formDatas.append('phone', this.$refs.componentRef[0].formLabelAlign.phone)
                formDatas.append('address', this.$refs.componentRef[0].formLabelAlign.address)
                formDatas.append('status', this.$refs.componentRef[0].formLabelAlign.status)
                formDatas.append('isResident', this.$refs.componentRef[0].formLabelAlign.isResident)
                formDatas.append('isLocal', this.$refs.componentRef[0].formLabelAlign.isLocal)
                formDatas.append('householdAddress', this.$refs.componentRef[0].formLabelAlign.householdAddress)
                formDatas.append('householdType', this.$refs.componentRef[0].formLabelAlign.householdType)
                formDatas.append('culture', this.$refs.componentRef[0].formLabelAlign.culture)

                this.$store.dispatch("homePage/oldUser", formDatas).then((res) => {
                    this.$refs.drawerIshow.drawer = false;
                    this.dataRefresh()
                })
            }

        },
        getDataTree() {
            this.$store.dispatch("area/getDataTree",{
          areaCode: this.$store.getters.user.areaCode,
        }).then((res) => {
                this.options = res.result
            })
        },
        handleBaseValue(val) {
            this.formLabelAlign.area = val[val.length - 1];
            console.log(999, val[val.length - 1])
        },
        contract() {
            if (this.oldName.length == 0) {
                this.$message({
                    message: '请至少选择一个老人',
                    type: 'warning'
                });
            } else {
                this.$refs.drawerIshow3.drawer = true
            }

        },

        submitForm3() {
            this.$refs.ruleForm3.validate(valid => {
                if (!valid) {
                } else {
                    this.$store.dispatch("jbsz/livingContractSaveData", this.ruleFormobje).then((res) => {

                    })
                }
            })

        },
        livingBatchgetList() {
            this.$store.dispatch("jbsz/livingBatchgetList").then((res) => {
                this.livingBatchgetLists = res.result
            })
        },
        getSelectedRows(e) {
            console.log(e)
            this.ruleFormobje.oldIds = []
            this.oldName = []
            e.forEach((res) => {
                this.ruleFormobje.oldIds.push(res.id)
                this.oldName.push(res.oldName)
            })
            console.log(88888, this.oldName, e)
        }
    },
};
</script>
    
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";

::v-deep .el-table th,
::v-deep .el-table td {
    text-align: center !important;
}

.data {
    display: flex;
}

.component {
    height: 100%;
    width: 100%;
    // overflow-y: scroll;
}

.el-dropdown-link {
    cursor: pointer;
    color: #409eff;
    margin-left: 9px;
    font-size: 12px;
    font-weight: 500;
}

.el-icon-arrow-down {
    font-size: 12px;
    // transform: scale(2);
}

// 新增样式
.avatar-uploader {
    border: 1px solid #ccced1;
}

.avatar-uploader .el-upload {
    border: 1px dashed white;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 1rem;
    color: #8c939d;
    width: 10rem;
    height: 10rem;
    line-height: 10rem;
    text-align: center;
}

.avatar {
    width: 10rem;
    height: 10rem;
    display: block;
}

.content {
    .el-input {
        width: 30rem;
    }

    .el-select {
        width: 30rem;
    }

    .el-cascader {
        width: 30rem;
    }
}


.el-checkbox {
    margin-right: 2px;
}

.hobby {
    width: 100%;
    border: 1px solid #d5d5d5;
    padding: 0 5px;
}

.title {
    width: 98%;
    border-bottom: 1px solid #999;
    padding-bottom: 0.5rem;
    margin: 1rem auto;
    font-size: 1rem;
    color: rgb(50, 50, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
    
  