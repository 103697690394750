<template>
  <div class="countyDistrictTabs">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane v-for="item in countyDistrictTabList" :key="item.label" :label="item.label" :name="item.name">
        <!-- 表格 -->
        <hltableZ v-if="activeName === item.name" style="height: 25rem" :columnEdit="table.columnEdit"
          :tableColumn="table.column" :apiurl="table.apiurl" :refreshFlag="table.refreshFlag"
          :selectedRows="table.selectedRows" :isChecked="table.isChecked" :isNumber="table.isNumber"
          :staticData="tableDataSource">
        </hltableZ>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
const countyDistrictTableData = {
  columnEdit: false,
  column: [
    {
      label: "区域",
      prop: "areaName",
      checked: true,
    },
    {
      label: "慢性病",
      prop: "chronicDisease",
      checked: true,
    },
  ],
  apiurl: "",
  refreshFlag: "",
  selectedRows: [],
  isChecked: false,
  isNumber: false,
};

const tablist = [
  { label: "市辖区", name: "first" },
  { label: "淄川区", name: "second" },
  { label: "张店区", name: "third" },
  { label: "博山区", name: "fourth" },
  { label: "临淄区", name: "fifth" },
  { label: "周村区", name: "sixth" },
  { label: "桓台县", name: "seventh" },
  { label: "高青县", name: "eighth" },
  { label: "沂源县", name: "ninth" },
];

export default {
  name: "CountyDistrictTabs",
  components: { hltableZ },
  data() {
    return {
      activeName: "first",
      countyDistrictTabList: [...tablist],
      table: { ...countyDistrictTableData },
      tableDataSource: [
        {
          id: 1,
          areaName: "王小虎",
          chronicDisease: "上海市普陀区金沙江路 1519 弄",
          children: [
            {
              id: 31,
              areaName: "王小虎",
              chronicDisease: "上海市普陀区金沙江路 1519 弄",
            },
            {
              id: 32,
              areaName: "王小虎",
              chronicDisease: "上海市普陀区金沙江路 1519 弄",
            },
          ],
        },
      ],
    };
  },

  mounted() { },

  methods: {
    // 选项卡点击
    handleClick() {
      if (this.activeName === "fifth") {
        let dataList = JSON.parse(JSON.stringify(countyDistrictTableData));
        let list = [
          {
            label: "60-79岁补贴对象",
            prop: "oldName",
            checked: true,
          },
          {
            label: "	80岁以上补贴对象",
            prop: "2",
            checked: true,
          },
        ];
        dataList.column.splice(1, 0, ...list);
        this.table = dataList;
      } else {
        this.table = countyDistrictTableData;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
